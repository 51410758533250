import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ButtonBranded, ProgressBar, Text } from "@components/common";
import { ProfileResponse } from "@types";

import { useStyles } from "./ProfileTrackerCard.styles";

export interface ProfileTrackerCardProps {
  profile: ProfileResponse;
}

export const ProfileTrackerCard: React.FC<ProfileTrackerCardProps> = ({ profile }: ProfileTrackerCardProps) => {
  const { t } = useTranslation("profile_tracker");
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text
          fontSize="xxlarge"
          fontWeight="semiBold"
          lineHeight="medium"
          className={classes.profileName}
          data-cy="profile-tracker-name"
        >
          {t("header", { name: profile.firstName })}
        </Text>
        <Text
          fontSize="medium"
          fontWeight="semiBold"
          lineHeight="medium"
          color="green"
          data-cy="profile-tracker-percentage-complete"
        >
          {t("complete", { percentage: profile.profilePercentageComplete })}
        </Text>
      </div>
      <ProgressBar value={profile.profilePercentageComplete} />
      <ButtonBranded
        data-cy="profile-tracker-button"
        variant="secondary"
        className={`${classes.setButtonWidth} ${classes.setButtonFont}`}
        onClick={() => navigate("/profile")}
      >
        {t("buttonLabel")}
      </ButtonBranded>
    </div>
  );
};
