/// <reference types="vite-plugin-svgr/client" />

import { IconButton, SvgIcon } from "@mui/material";
import { Link } from "react-router-dom";

import adviceIcon from "@assets/icons/advice.svg?react";
import homeIcon from "@assets/icons/home.svg?react";
import plansIcon from "@assets/icons/plans.svg?react";
import profileIcon from "@assets/icons/profile.svg?react";
import savingsIcon from "@assets/icons/savings.svg?react";
import settingsIcon from "@assets/icons/settings.svg?react";
import toolsIcon from "@assets/icons/tools.svg?react";
import { useStore } from "@stores";
import { GTMNavigationClick } from "@utils";

import { useStyles } from "./NavItem.styles";
import { Text } from "../Text";

export type NavItemIcon = "advice" | "home" | "plans" | "profile" | "savings" | "settings" | "tools";

export type NavItemOrientation = "horizontal" | "vertical";

export interface NavItemPropsBase {
  label?: string;
  icon: NavItemIcon;
  accentColor?: string;
  active?: boolean;
  orientation?: NavItemOrientation;
  dataCy?: string;
}

interface NavItemWithOnClick extends NavItemPropsBase {
  onClick: () => void;
  route?: never;
}

interface NavItemWithRoute extends NavItemPropsBase {
  onClick?: never;
  route: string;
}

type NavItemProps = NavItemWithOnClick | NavItemWithRoute;

export const NavItem: React.FC<NavItemProps> = ({
  label,
  icon,
  route,
  onClick,
  accentColor,
  active,
  orientation = "vertical",
  dataCy,
}) => {
  const classes = useStyles({ active, accentColor, orientation });
  const { modalStore } = useStore();

  const iconComponents: {
    [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  } = {
    advice: adviceIcon,
    home: homeIcon,
    plans: plansIcon,
    profile: profileIcon,
    savings: savingsIcon,
    settings: settingsIcon,
    tools: toolsIcon,
  };

  const getButtonProps = () => {
    if (route) {
      return {
        component: Link,
        to: route,
      };
    } else if (onClick) {
      return {
        onClick: onClick,
      };
    }
  };

  const baseOnClick = () => {
    (onClick && onClick()) || modalStore.closeModal();
    GTMNavigationClick(label ?? route ?? "navItem");
  };

  return (
    <div className={classes.container}>
      <IconButton
        {...getButtonProps()}
        className={classes.button}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        onClick={() => {
          baseOnClick();
        }}
      >
        <div className={classes.iconContainer}>
          <div className={classes.hoverCircle}></div>
          <SvgIcon component={iconComponents[icon]} className={classes.icon} inheritViewBox />
        </div>
        {label && (
          <div className={classes.label}>
            <Text
              data-cy={dataCy}
              className={orientation === "vertical" ? classes.navMobile : classes.navDesktop}
              color={active ? "black" : "gray"}
            >
              {label}
            </Text>
          </div>
        )}
      </IconButton>
    </div>
  );
};
