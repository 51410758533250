/// <reference types="vite-plugin-svgr/client" />

import EastRoundedIcon from "@mui/icons-material/EastRounded";

import CarPaymentImage from "@assets/tools/CarPayment_optimized.jpg";
import CreditCardPayoffImage from "@assets/tools/CreditCardPayoff_optimized.jpg";
import DebtToIncomeImage from "@assets/tools/Debt-to-income-ratio_optimized.jpg";
import IncomeTaxImage from "@assets/tools/IncomeTax_optimized.jpg";
import MortgagePaymentImage from "@assets/tools/MortgagePayments_optimized.jpg";
import SavingsImage from "@assets/tools/Savings_optimized.jpg";
import { GTMLinkOutClicked } from "@utils";

import { useStyles } from "./ToolCard.styles";
import { Text } from "../Text";

const images: Record<string, string> = {
  CreditCardPayoff: CreditCardPayoffImage,
  DebtToIncomeRatio: DebtToIncomeImage,
  MortgagePayments: MortgagePaymentImage,
  CarPayment: CarPaymentImage,
  Savings: SavingsImage,
  IncomeTax: IncomeTaxImage,
};

export interface Tool {
  title: string;
  description: string;
  id: string;
  link: string;
}

export interface ToolCardProps {
  toolData: Tool;
}

export const ToolCard: React.FC<ToolCardProps> = ({ toolData }) => {
  const classes = useStyles();

  const getImagePosition = () => {
    if (toolData.id === "CreditCardPayoff") {
      return classes.creditCardImage;
    }

    if (toolData.id === "DebtToIncomeRatio") {
      return classes.debtToIncomeImage;
    }

    if (toolData.id === "MortgagePayments") {
      return classes.mortgageImage;
    }
    if (toolData.id === "CarPayment") {
      return classes.carImage;
    }

    if (toolData.id === "Savings") {
      return classes.savingsImage;
    }

    if (toolData.id === "IncomeTax") {
      return classes.incomeTaxImage;
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={images[toolData.id]} alt={toolData.title} className={getImagePosition()} />
      </div>
      <div
        className={classes.content}
        onClick={() => {
          GTMLinkOutClicked(toolData.link);
          window.open(toolData.link, "_blank");
        }}
      >
        <Text fontSize="xlarge" fontWeight="semiBold" lineHeight="large" color="accent" className={classes.titleText}>
          {toolData.title}
        </Text>
        <Text fontSize="small" fontWeight="regular" lineHeight="xxsmall" color="brandBlueOther" paddingBottom={5}>
          {toolData.description}
        </Text>
        <div className={classes.buttonContainer}>
          <EastRoundedIcon className={classes.arrowIcon} />
        </div>
      </div>
    </div>
  );
};
