import { useMediaQuery } from "@mui/material";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DebtInfo, DebtInfoPropsBase, Text } from "@components/common";
import { IneligibleDebtsAccordion } from "@components/layout/IneligibleDebtsAccordion";
import { useStore } from "@stores";
import { theme } from "@styles";
import { TradeLines } from "@types";
import { CTAModulesEnum } from "@utils";

import { useDebtReviewStyles } from "./DebtReview.styles.ts";
import { TotalDebt } from "./TotalDebt.tsx";

export const DebtReviewModule: FC = observer(() => {
  const { callToActionsStore, profileStore, modalStore } = useStore();
  const [eligibleTradeLines, setEligibleTradelines] = useState<DebtInfoPropsBase[]>([]);
  const [inEligibleTradeLines, setInEligibleTradelines] = useState<DebtInfoPropsBase[]>([]);

  useEffect(() => {
    autorun(() => {
      if (!callToActionsStore.creditResponse) {
        if (profileStore.profile?.id) {
          modalStore.setModalLoading(true);
          callToActionsStore.getCreditReport(profileStore.profile.id).catch(() => {
            modalStore.openModal(CTAModulesEnum["call-for-help"]);
          });
        }
      }
    });
  }, [profileStore.profile, callToActionsStore, modalStore]);

  useEffect(() => {
    autorun(() => {
      if (callToActionsStore.creditResponse) {
        modalStore.setModalLoading(false);
      }
    });
  }, [callToActionsStore.creditResponse, modalStore]);

  useEffect(() => {
    autorun(() => {
      if (callToActionsStore.creditResponse) {
        const getTradeLines = (tradeLines: TradeLines[], flag: boolean) => {
          return tradeLines.map((item) => ({
            name: item.currentCreditor.name,
            type: item.debtType,
            amount: Number(item.currentBalance),
            ineligible: flag,
            accountIdentifier: item.accountIdentifier,
          }));
        };

        const inEligibleTradeLines = getTradeLines(
          callToActionsStore.creditResponse.analysis["debt-settlement"].ineligibleTradeLines,
          true,
        ).filter((tradeline) => tradeline.amount > 0);

        const eligibleTradeLines = getTradeLines(
          callToActionsStore.creditResponse.analysis["debt-settlement"].eligibleTradeLines,
          false,
        );

        setEligibleTradelines(eligibleTradeLines);
        setInEligibleTradelines(inEligibleTradeLines);
      }
    });
  }, [callToActionsStore.creditResponse]);

  const { t } = useTranslation("debt_review");
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const classes = useDebtReviewStyles();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.sidebar}>
          <Text fontSize="medium" fontWeight="semiBold" lineHeight="medium" color="accent">
            {t("subheader")}
          </Text>
          <Text fontSize="xxxlarge" fontWeight="bold" lineHeight="xxxlarge" className={classes.fontDarkGray}>
            {t("header1")}
          </Text>
        </div>
        <div className={classes.contentBox}>
          <div className={classes.contentChild}>
            <Text fontSize="xlarge" fontWeight="semiBold" lineHeight="large" className={classes.fontDarkGray}>
              {t("header2")}
            </Text>
          </div>
          <div className={classes.tradeLinesContainer} data-cy="tradelines-container">
            <DebtInfo data-cy="debt-review-debt-list" data={eligibleTradeLines} />

            <div>
              <IneligibleDebtsAccordion data={inEligibleTradeLines} />
            </div>
            <div>{isDesktop && <TotalDebt />}</div>
          </div>
        </div>
      </div>
    </>
  );
});
