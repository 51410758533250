import HelpIcon from "@mui/icons-material/Help";
import { IconButton, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Text, Tooltip as MuiTooltip } from "@components/common";
import { theme } from "@styles";

import { useStyles } from "./Tooltip.styles";
import { ConfirmationModalView } from "../../Footer";

export const SelectPlanTooltip = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [showTooltip, setShowTooltip] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation("plan_option", {
    keyPrefix: "tooltip",
  });

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const tooltip = (
    <div className={classes.tooltipContainer}>
      <Text fontSize={"small"} fontWeight={"semiBold"} lineHeight={"xxxsmall"} color={"darkerGray"}>
        {t("header")}
      </Text>

      <Text fontSize={"small"} fontWeight={"light"} lineHeight={"xxsmall"}>
        {t("content")}
      </Text>
    </div>
  );

  return (
    <>
      {isDesktop ? (
        <MuiTooltip title={tooltip}>
          <HelpIcon className={classes.tooltip} sx={{ ml: 0.5, mb: "-2px" }} />
        </MuiTooltip>
      ) : (
        <IconButton onClick={toggleTooltip} sx={{ mt: "-2px" }}>
          <HelpIcon className={classes.tooltip} />
        </IconButton>
      )}

      {!isDesktop && (
        <ConfirmationModalView
          isOpen={showTooltip}
          handlePrimary={toggleTooltip}
          handleCancel={toggleTooltip}
          name={"select-plan-tooltip"}
        />
      )}
    </>
  );
};
