import { makeAutoObservable } from "mobx";

import { AxiosAgent } from "@services";
import { Engagement, EngagementStatus, JourneyName, Servicer } from "@types";

import LoadingStore from "./LoadingStore.ts";
import { rollbar } from "../../rollbarConfig.ts";

export default class EngagementStore {
  engagementLoading: boolean = false;
  loader: LoadingStore;
  private retrievedEngagement: Engagement | undefined = undefined;
  private retrievedServicer: Servicer | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
    this.loader = new LoadingStore();
  }

  get engagement() {
    if (this.retrievedEngagement) return this.retrievedEngagement;
    if (localStorage.getItem("engagementData")) return JSON.parse(localStorage.getItem("engagementData") as string);
  }

  get servicer() {
    if (this.retrievedServicer) return this.retrievedServicer;
    if (localStorage.getItem("servicer")) return JSON.parse(localStorage.getItem("servicer") as string);
  }

  setEngagementLoading = (isLoading: boolean): void => {
    this.engagementLoading = isLoading;
  };

  clearEngagementStore = (): void => {
    this.retrievedEngagement = undefined;
  };

  setEngagement = (newEngagement: Engagement): void => {
    this.retrievedEngagement = newEngagement;
  };

  private setServicer = (servicer: Servicer): void => {
    window.localStorage.setItem("servicer", JSON.stringify(servicer));
    this.retrievedServicer = servicer;
  };

  getEngagementAndServicerById = async (id: string) => {
    try {
      this.loader.updateState("LOADING");
      const engagement = await this.getEngagementById(id);
      await this.getServicer(engagement);
      this.loader.updateState("SUCCESS");
      return engagement;
    } catch (error) {
      rollbar.debug(error as Error);
      this.loader.updateState("ERROR");
    }
  };

  private getEngagementById = async (id: string) => {
    try {
      const engagement = await AxiosAgent.Engagement.getById(id);
      this.setEngagement(engagement);
      return engagement;
    } catch (error) {
      throw new Error("Error retrieving the engagement", error as Error);
    }
  };

  private getServicer = async (engagement: Engagement) => {
    try {
      let servicer;
      if (engagement.lead_type === JourneyName.DEBT_SETTLEMENT) {
        servicer = await AxiosAgent.Utils.getServicerByState(engagement.profile.address.state);
      }
      if (engagement.lead_type === JourneyName.DEBT_MANAGEMENT) {
        servicer = await AxiosAgent.Utils.getServicerById("ConsolidatedCredit");
      }
      if (servicer) {
        this.setServicer(servicer);
      } else {
        throw new Error("Servicer could not be found for the engagement.");
      }
    } catch (error) {
      throw new Error("Error retrieving the servicer", error as Error);
    }
  };

  updateEngagementStatus = async (id: string, status: EngagementStatus) => {
    const engagement = await AxiosAgent.Engagement.updateStatus(id, status.toString());
    this.setEngagement(engagement);
  };
}
