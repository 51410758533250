// import { SubStatus } from '@types';
import { useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Text } from "@components/common";
import { theme } from "@styles";

import { useStyles } from "./HelpView.styles";

export const HelpView = observer(() => {
  const { t } = useTranslation("call_for_help_page");
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <div className={classes.helpViewBackground}>
        <div className={classes.mainContainer}>
          <div className={classes.helpContent}>
            <div className={classes.header} data-cy="we'll-call-you-page">
              {!isDesktop ? (
                <>
                  <Text fontSize="xxxxlarge" fontWeight="semiBold" lineHeight="xxxxxlarge" color="white">
                    {t("header1a")}
                  </Text>
                  <Text fontSize="xxxxlarge" fontWeight="semiBold" lineHeight="xxxxxlarge" color="white">
                    {t("header1b")}
                  </Text>
                </>
              ) : (
                <>
                  <Text fontSize="xxxxlarge" fontWeight="semiBold" lineHeight="xxxxxlarge" color="white">
                    {t("header1")}
                  </Text>
                </>
              )}
            </div>
            <div className={classes.container}>
              <div className={classes.content}>
                <Text fontSize="xxlarge" fontWeight="semiBold" lineHeight="xxlarge" color="darkerGray">
                  {t("header2")}
                </Text>
                <Text fontSize="small" fontWeight="light" lineHeight="xxsmall" color="darkerGray">
                  {t("infobox1")}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
