import { ChatOutlined } from "@mui/icons-material";
import { Box, Button, Divider, useMediaQuery } from "@mui/material";
import { useRollbar } from "@rollbar/react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { patternFormatter } from "react-number-format";

import idaLogo from "@assets/logos/ida_icon.png";
import { ButtonBranded, Text, FooterLegalInfo, ScrollIndicator } from "@components/common";
import { Modal, Module, CallForHelpFooter, ConfirmationModalView } from "@components/layout";
import { useStore } from "@stores";
import { theme } from "@styles";
import { StoredProfile } from "@types";
import { getThankYouUrl } from "@utils";
import {
  CTAModulesEnum,
  getServicerLogo,
  getServicerPhoneNumber,
  getTrustedFormUrl,
  GTMOffRampClicked,
  useSimpleTimer,
} from "@utils";

export interface AuthProps {
  storedProfile?: StoredProfile;
  setPhone?: (phone: string) => void;
  sendSms: () => Promise<string | undefined>;
  verifyUser: () => Promise<void>;
  children?: React.ReactNode;
}

export const SignUpForm = observer(({ verifyUser, children }: AuthProps) => {
  const [open, setOpen] = useState(false);
  const [showHelpCard, setShowHelpCard] = useState(false);
  const [isAgreementRead, setIsAgreementRead] = useState(false);
  const [userCredentials, setUserCredentials] = useState({ email: "", name: "", phone: "" });

  const {
    modalStore: { module, openModal },
    engagementStore: { engagement, servicer, updateEngagementStatus },
    remoteConfigStore: { timers, featureFlags, redirectUrl },
    chatStore: { launchChat, enabled: chatEnabled, initializeChat, hasActiveChat },
    sessionStore: { sessionExpiredLogout },
    profileStore: { setTrustedFormUrl, profile },
  } = useStore();
  const { t } = useTranslation("sign_up");
  const leadType = profile?.engagementLeadType ?? "";
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const rollbar = useRollbar();

  const handleTimeout = async () => {
    const engagementId = engagement.id;

    try {
      await updateEngagementStatus(engagementId, "ready_for_submission");
    } catch (error) {
      rollbar.debug("Error handling timeout on OTP page", error as Error);
    }

    window.localStorage.setItem("engagementData", "");
    window.localStorage.setItem("servicer", "");
    sessionExpiredLogout();
  };

  const timer = timers.sessionTimeout || 15;
  const { startTimer, stopTimer } = useSimpleTimer(timer, handleTimeout);

  useEffect(() => {
    startTimer();

    return () => {
      stopTimer();
    };
  }, [startTimer, stopTimer]);

  useEffect(() => {
    if (engagement) {
      if (engagement.status === "ready_for_submission") {
        setShowHelpCard(true);
        openModal(CTAModulesEnum["call-for-help"]);
      } else {
        initializeChat({
          firstName: engagement.profile.first_name,
          lastName: engagement.profile.last_name,
          engagementId: engagement.id,
        });
        setUserCredentials({
          email: engagement.profile.email,
          name: `${engagement.profile.first_name} ${engagement.profile.last_name}`,
          phone: engagement.profile.phone_mobile,
        });
      }
    }
  }, [engagement, initializeChat, openModal]);

  if (showHelpCard) {
    return (
      <div style={{ marginRight: "20px" }}>
        <Modal style={{ padding: "20px" }}>
          <div style={{ maxHeight: "calc(100vh - 270px)" }}>{module.component}</div>
          <CallForHelpFooter showDoneToggle />
        </Modal>
      </div>
    );
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const trustedFormUrl = getTrustedFormUrl();
    setTrustedFormUrl(trustedFormUrl);
    verifyUser();
  };

  return (
    <form onSubmit={onSubmit}>
      <Module formatted>
        <Module.Header>
          <Box sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}>
            <img src={idaLogo} height="32px" alt="Instant Debt Advisor" />
          </Box>
        </Module.Header>
        <Module.Main sx={{ p: 0, gap: 0, position: "relative" }}>
          <Module.Sidebar
            sx={{
              padding: "32px 24px",
              pb: { xs: 6, md: 4 },
              height: 1,
              overflow: { xs: "visible", md: "auto" },
              backgroundImage: "linear-gradient(270deg, #44accd 0%, #1568a0 100%)",
            }}
          >
            <Module.Title color="white" fontWeight="light" fontSize="xxlarge" lineHeight="xxlarge">
              <Trans
                i18nKey="title"
                ns="sign_up"
                components={{
                  highlight: <Box sx={{ fontWeight: 800 }} component="span" />,
                }}
                values={{
                  planType: t(engagement?.lead_type === "Debt Management" ? "debtManagement" : "debtSettlement", {
                    ns: "common",
                  }),
                }}
              />
            </Module.Title>
            <Module.SubTitle color="white" fontWeight="regular" lineHeight="small">
              {t("description")}
            </Module.SubTitle>
          </Module.Sidebar>
          <Module.Content
            sx={{
              padding: "32px 24px",
              overflow: { xs: "visible", md: "auto" },
              borderRadius: { xs: 4, md: 0 },
              mt: { xs: -5, md: 0 },
              background: "white",
            }}
            id="content"
          >
            <Box>
              <Text fontSize="medium" fontWeight="bold" lineHeight={"small"}>
                {t("first")}
              </Text>
              <Text fontSize={"medium"} lineHeight={"small"}>
                {t("confirmPhone")}
              </Text>
              <Text fontSize="medium" fontWeight="semiBold" lineHeight="small" sx={{ my: 0.5 }}>
                {t("textYou")}
              </Text>

              {Object.keys(userCredentials).map((credential) => (
                <Box
                  key={credential}
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    justifyContent: {
                      lg: "space-between",
                    },
                    textAlign: "left",
                    alignItems: {
                      xs: "start",
                      lg: "center",
                    },
                    flexWrap: "wrap",
                    gap: 1,
                    backgroundColor: "#eef0fb",
                    p: 1,
                    mt: 1,
                    borderRadius: 1,
                  }}
                >
                  <Text sx={{ textTransform: "capitalize", color: "#74808b" }}>{t(credential)}</Text>

                  <Text fontSize={"medium"} fontWeight="semiBold" lineHeight={"small"}>
                    {(userCredentials as { [key: string]: string })[credential]}
                  </Text>
                </Box>
              ))}

              {chatEnabled && (
                <>
                  <Text pt={4} fontSize="medium" fontWeight="semiBold" lineHeight="medium">
                    {t("chat.header")}
                  </Text>
                  <Text fontSize="medium" fontWeight="regular" lineHeight="medium">
                    {t("chat.description")}
                  </Text>

                  <Button
                    onClick={launchChat}
                    sx={(theme) => ({
                      py: 1,
                      color: theme.palette.brandBlue.dark,
                      fontSize: theme.fontSizes.medium,
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "none",
                      },
                    })}
                  >
                    <ChatOutlined sx={{ mr: 1 }} />
                    {t(hasActiveChat ? "continueChat" : "chat", { ns: "common" })}
                  </Button>
                </>
              )}

              <Divider sx={{ my: 3 }} />
              {servicer?.id && (
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                  <Text fontSize="small" sx={{ color: (theme) => theme.palette.brandGray.main }}>
                    {t("planServicedBy")} -
                  </Text>
                  <img src={getServicerLogo(servicer.id)} alt={servicer?.displayName} width="150px" />
                </Box>
              )}
              <Text fontSize="small" lineHeight="small" fontWeight="light" sx={{ color: "#74808b" }} component="div">
                <Trans
                  i18nKey={engagement.lead_type === "Debt Management" ? "dmpTerms" : "dspTerms"}
                  ns="sign_up"
                  values={{
                    servicer: servicer?.displayName,
                    name: userCredentials.name,
                    servicerPhone: patternFormatter(getServicerPhoneNumber(leadType).replace(/\D/g, ""), {
                      format: "#-###-###-####",
                    }),
                  }}
                  components={{
                    bold: <Box sx={{ fontWeight: 500 }} component="span" />,
                    6: <a href="https://allclear.ai/legal-notices/#privacy-policy" />,
                    1: <a href="mailto:info@lighthousefinancesolutions.com" />,
                    2: <a href="https://www.consolidatedcredit.org/terms-conditions/" />,
                    3: <a href="https://www.consolidatedcredit.org/privacy/" />,
                    4: <a href="https://www.consolidatedcredit.org/e-sign-budget/" />,
                    5: <a href="mailto:privacy@consoldiatedcredit.org" />,
                    7: <a href="https://www.lighthousefinancesolutions.com/privacy-policy/" />,
                    8: <a href="https://www.lighthousefinancesolutions.com/terms-conditions/" />,
                    9: <a href="https://www.lighthousefinancesolutions.com/e-sign-disclosure/" />,
                    10: <a href="https://www.lighthousefinancesolutions.com/es/politica-de-privacidad/" />,
                    11: <a href="https://www.lighthousefinancesolutions.com/es/terminos-y-condiciones/" />,
                    12: <a href="https://www.lighthousefinancesolutions.com/es/divulgacion-de-firma-electronica/" />,
                    13: <a href="https://www.consolidatedcredit.org/es/politica-de-privacidad/" />,
                    14: <a href="https://www.consolidatedcredit.org/es/terminos-y-condiciones-online/" />,
                    15: <a href="https://www.consolidatedcredit.org/es/e-sign-budget/" />,
                    name: <Box sx={{ color: (theme) => theme.palette.brandGray.darker }} component="span" />,
                  }}
                />
              </Text>
            </Box>
            {children}
          </Module.Content>
          {isDesktop && (
            <ScrollIndicator scrollDivId="content" displayOnDesktop atBottomCallback={() => setIsAgreementRead(true)} />
          )}
        </Module.Main>

        <Module.Footer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              flexDirection: { xs: "column", md: "row-reverse" },
              gap: 2,
              mb: 2,
            }}
          >
            <ButtonBranded
              variant="primary"
              type="submit"
              disabled={!isAgreementRead}
              sx={{
                [theme.breakpoints.up("md")]: {
                  width: "unset",
                  height: "min-content",
                  fontSize: theme.fontSizes.medium,
                  fontWeight: theme.fontWeights.semiBold,
                  lineHeight: theme.fontLineHeights.xxxsmall,
                },
              }}
            >
              {t("sendCode")}
            </ButtonBranded>

            {featureFlags.enableVerifyPageOffRamp && (
              <ButtonBranded
                variant="textonly"
                fontSize={theme.fontSizes.medium}
                fontWeight={theme.fontWeights.medium}
                lineHeight={theme.fontLineHeights.xxxsmall}
                onClick={() => {
                  GTMOffRampClicked("Sign-up");
                  setOpen(!open);
                }}
                data-cy="callMeButton"
              >
                {t("callMe")}
              </ButtonBranded>
            )}
          </Box>
          <FooterLegalInfo />
        </Module.Footer>
      </Module>

      <ConfirmationModalView
        isOpen={open}
        handlePrimary={() => {
          setOpen(false);
          if (engagement && engagement.id) {
            updateEngagementStatus(engagement!.id, "ready_for_submission");
          }
          window.localStorage.setItem("engagementData", "");
          window.location.replace(getThankYouUrl(redirectUrl));
        }}
        handleSecondary={() => {
          setOpen(false);
        }}
        handleCancel={() => {
          setOpen(false);
        }}
        name={"sign-up"}
      />
      {!isDesktop && <ScrollIndicator scrollDivId="module" atBottomCallback={() => setIsAgreementRead(true)} />}
    </form>
  );
});
