import { yupResolver } from "@hookform/resolvers/yup";
import { Backdrop, Card, Divider, Grid, useMediaQuery } from "@mui/material";
import { AxiosError } from "axios";
import { observer } from "mobx-react-lite";
import { ChangeEvent, FC, forwardRef, useEffect, useState } from "react";
import { useForm, Controller, FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import { ButtonBranded, Slider, Text } from "@components/common";
import { InputBranded } from "@components/common/InputBranded";
import { SelectBranded } from "@components/common/SelectBranded";
import { AxiosAgent } from "@services";
import { useStore } from "@stores";
import { theme } from "@styles";
import {
  BudgetSubCategoryEnum,
  BudgetItemTypeEnum,
  BudgetTypeEnum,
  DEFAULT_ALLOWED_FREQUENCY,
  DEFAULT_FREQUENCY,
  budgetItemFormSchema,
  IBudgetItemDataDto,
  JourneyId,
  BudgetCategoryDSP,
} from "@types";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      inputMode="numeric"
      thousandSeparator
      valueIsNumericString
      prefix="$"
      decimalScale={2}
    />
  );
});
export const BudgetInputModal = observer(() => {
  const { t } = useTranslation("budget_tool");
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [isLoading, setIsLoading] = useState(false);
  const { budgetStore, profileStore } = useStore();
  const { isBudgetEdit: isOpen, itemData, itemBudgetType } = budgetStore;
  const { type } = itemData;
  const form = useForm({
    defaultValues: { ...itemData },
    mode: "onChange",
    resolver: yupResolver(budgetItemFormSchema(t)),
  });
  const {
    reset,
    setValue,
    formState: { errors, isValid, touchedFields, isDirty },
  } = form;

  useEffect(() => {
    reset({ ...itemData });
  }, [itemData, reset]);

  const isDSP = profileStore.journeyId === JourneyId.DEBT_SETTLEMENT;
  const isCustom = type === BudgetItemTypeEnum.CUSTOM;
  const isRequired = type === BudgetItemTypeEnum.REQUIRED;
  const categoryOpt = Object.values(BudgetCategoryDSP)
    .filter((c) => c !== BudgetSubCategoryEnum.PrimaryIncome)
    .map((bc) => ({
      value: bc,
      label: t(`categoryValues.${bc}`),
    }));
  const freqOpt = DEFAULT_ALLOWED_FREQUENCY.map((f) => ({
    value: f,
    label: t(`frequencyLabel.${f}`),
  }));
  const onAmountChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): boolean => {
    const rawValue = e.target.value.replace(/\D/g, "");
    const value = parseInt(rawValue);
    if (!isNaN(value) || rawValue === "") {
      setValue("amount", value);
      return true;
    }
    return false;
  };

  const onClose = async () => {
    if (!itemData.id) {
      budgetStore.removeItem(itemBudgetType, itemData);
    }
    setIsLoading(false);
    budgetStore.setIsBudgetEdit(false);
  };

  const onDelete = async () => {
    try {
      if (itemData.id && profileStore?.profile?.id) {
        if (isDSP) {
          AxiosAgent.Budget.delete({
            profileId: profileStore.profile.id,
            budgetType: itemBudgetType,
            itemId: itemData.id,
          });
        } else {
          AxiosAgent.BudgetDMP.delete({
            profileId: profileStore.profile.id,
            itemId: itemData.id,
          });
        }
      }
      budgetStore.removeItem(itemBudgetType, itemData);
    } catch (e) {
      if (e instanceof AxiosError) {
        const { response } = e;
        if (response) {
          form.setError("name", { message: t("nameExistError") }, { shouldFocus: true });
        }
      }
    } finally {
      onClose();
    }
  };

  const isEdit = () => {
    return itemData.id !== undefined;
  };

  const getHeaderText = (): string => {
    return t("inputHeader", {
      budgetType: t(type!),
      action: isEdit() ? t("edit") : t("add"),
    });
  };

  const onSubmit: SubmitHandler<IBudgetItemDataDto> = async (data: IBudgetItemDataDto) => {
    try {
      if (!data.subCategory && isCustom && itemBudgetType === BudgetTypeEnum.EXPENSE) {
        form.setError("subCategory", { message: t("requiredError") }, { shouldFocus: true });
        return;
      }

      if (profileStore?.profile?.id) {
        setIsLoading(true);
        let newProfile;
        if (itemData.id) {
          if (isDSP) {
            newProfile = await AxiosAgent.Budget.update({
              profileId: profileStore.profile.id,
              budgetType: itemBudgetType,
              itemId: itemData.id,
              budgetItem: { ...data, amount: Number(data.amount) },
            });
          } else {
            newProfile = await AxiosAgent.BudgetDMP.update({
              profileId: profileStore.profile.id,
              itemId: itemData.id,
              budgetItem: budgetStore.transformDSPtoDMP(data, itemBudgetType),
            });
          }
        } else {
          if (isDSP) {
            newProfile = await AxiosAgent.Budget.create({
              profileId: profileStore.profile.id,
              budgetType: itemBudgetType,
              budgetItem: { ...data, amount: Number(data.amount) },
            });
          } else {
            newProfile = await AxiosAgent.BudgetDMP.create({
              profileId: profileStore.profile.id,
              budgetItem: budgetStore.transformDSPtoDMP(data, itemBudgetType),
            });
          }
        }
        if (newProfile?.budget) {
          profileStore.setProfile(newProfile);
          budgetStore.setData(newProfile.budget);
        }
      }
      onClose();
    } catch (e) {
      if (e instanceof AxiosError) {
        const { response } = e;
        if (response) {
          form.setError("name", { message: response.data?.message }, { shouldFocus: true });
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <Backdrop open={isOpen} onClick={onClose} />
      <Slider direction={isDesktop ? "left" : "up"} show={isOpen} transitionTime={500}>
        <Card
          sx={{
            position: "absolute",
            width: "100%",
            bottom: 0,
            right: 0,
            height: "auto",
            [theme.breakpoints.up("md")]: {
              height: "100%",
              top: 0,
              right: 0,
              width: "23rem",
              minHeight: "400px",
            },
          }}
          elevation={4}
        >
          <form data-cy="budget-input-modal-form" onSubmit={form.handleSubmit(onSubmit)}>
            <Grid container direction="column" alignItems="center">
              <Grid item py={2}>
                <Text fontSize="medium" fontWeight="semiBold" textTransform="capitalize">
                  {getHeaderText()}
                </Text>
              </Grid>
              <Divider sx={{ color: "black", width: "100%" }} />

              <Grid item pt={2} px={2} xs={12} width="100%">
                <Controller
                  name="name"
                  render={({ field: { value, ...field } }) => (
                    <InputBranded
                      {...field}
                      autoComplete="off"
                      disabled={!isCustom}
                      inputProps={{
                        "data-cy": "budget-input-modal-name-input",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      value={isCustom ? value : t(value)}
                      placeholder={t("customPlaceholder")}
                      label={t("name")}
                    />
                  )}
                />
              </Grid>
              {isCustom && itemBudgetType !== BudgetTypeEnum.INCOME && (
                <Grid item pt={2} px={2} xs={12} width="100%">
                  <Controller
                    name="subCategory"
                    control={form.control}
                    disabled={!isCustom}
                    render={({ field }) => (
                      <SelectBranded
                        {...field}
                        label={t("category")}
                        options={categoryOpt}
                        inputProps={{
                          "data-cy": "budget-input-modal-sub-category-input",
                        }}
                        error={!!errors.subCategory}
                        helperText={errors.subCategory?.message}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item pt={2} px={2} xs={12} width="100%">
                <Controller
                  name="amount"
                  render={({ field }) => (
                    <InputBranded
                      {...field}
                      autoComplete="off"
                      error={!!errors.amount && !!(isDirty || touchedFields.amount)}
                      helperText={(isDirty || touchedFields.amount) && errors?.amount?.message}
                      placeholder="$0"
                      label={t("amount")}
                      onChange={(e) => {
                        if (onAmountChange(e)) {
                          field.onChange(e); // ensure the field value is updated
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputComponent: NumericFormatCustom as unknown as FC,
                        inputMode: "decimal",
                        disableUnderline: true,
                      }}
                      data-cy="budget-amount-input"
                    />
                  )}
                />
              </Grid>
              <Grid item p={2} xs={12} width="100%">
                <Controller
                  name="frequency"
                  control={form.control}
                  defaultValue={DEFAULT_FREQUENCY}
                  render={({ field }) => (
                    <SelectBranded {...field} label={t("frequency")} options={freqOpt} data-cy="budget-freq-select" />
                  )}
                />
              </Grid>
              <Grid item p={2} pt={0} xs={12} width="100%">
                <ButtonBranded
                  loading={isLoading}
                  variant="primary"
                  data-cy="add-income-btn"
                  type="submit"
                  disabled={!!Object.keys(errors).length || !isValid}
                >
                  {t("save")}
                </ButtonBranded>
              </Grid>
              {!isRequired && itemData.id && (
                <Grid item pb={2} px={2} xs={12} width="100%">
                  <ButtonBranded
                    variant="secondary"
                    sx={{
                      "&:hover": {
                        textDecoration: "none",
                        cursor: "pointer",
                        backgroundColor: theme.palette.common.white,
                      },
                    }}
                    onClick={onDelete}
                    data-cy="remove-income-btn"
                  >
                    <Text
                      fontSize="medium"
                      fontWeight="semiBold"
                      lineHeight="medium"
                      color="brandRed"
                      textTransform="capitalize"
                    >
                      {t("removeItem", { budgetType: t(itemBudgetType) })}
                    </Text>
                  </ButtonBranded>
                </Grid>
              )}
            </Grid>
          </form>
        </Card>
      </Slider>
    </FormProvider>
  );
});
