import { makeAutoObservable } from "mobx";

import { JourneyId } from "@types";
import { ProfileSubStatusEnum } from "@utils";
import { AxiosErrorData } from "src/types/common";

import { store } from "./store";

export default class CommonStore {
  language: string = "en";
  serverError: AxiosErrorData | null = null;
  userClosedCredAuthModal: boolean = false;
  userClosedBudgetModal: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get loadingEngagementOrProfile(): boolean {
    return (
      store.sessionStore.authUserLoaded === "VERIFYING" ||
      store.engagementStore.loader?.isLoading.get() ||
      store.profileStore.loader?.isLoading.get() ||
      this.isMissingCreditReport
    );
  }

  get isMissingCreditReport(): boolean {
    return (
      store.commonStore.getHelpPageView() === "helpB" &&
      !!store.profileStore.profile?.id &&
      !store.callToActionsStore.creditResponse &&
      store.profileStore.journeyId !== JourneyId.DEBT_MANAGEMENT
    );
  }

  get isEngagementOrProfileLoaded(): boolean {
    return store.engagementStore.loader?.isSuccess.get() || store.profileStore.loader?.isSuccess.get();
  }

  clearStores = (): void => {
    store.engagementStore.clearEngagementStore();
    store.profileStore.clearProfileStore();
    store.sessionStore.clearSessionStore();
    store.modalStore.setShowLogOffModal(false);
    store.modalStore.clearModalStore();
    this.setUserClosedCredAuthModal(false);
    this.setUserClosedBudgetModal(false);
  };

  setUserClosedCredAuthModal = (value: boolean): void => {
    this.userClosedCredAuthModal = value;
  };

  setUserClosedBudgetModal = (value: boolean): void => {
    this.userClosedBudgetModal = value;
  };

  setUserClosedModal = (value: boolean): void => {
    this.setUserClosedCredAuthModal(value);
    this.setUserClosedBudgetModal(value);
  };

  setServerError = (error: AxiosErrorData | null): void => {
    this.serverError = error;
  };

  getHelpPageView = (): string => {
    switch (store.profileStore.profile?.subStatus) {
      case ProfileSubStatusEnum.SYSTEM_TIMEOUT_BEFORE_CREDIT:
      case ProfileSubStatusEnum.SYSTEM_ERROR:
      case ProfileSubStatusEnum.IDA_DECLINED_CREDIT:
      case ProfileSubStatusEnum.USER_DECLINED_CREDIT:
      case ProfileSubStatusEnum.IDA_CREDIT_ERROR:
      case ProfileSubStatusEnum.IDA_ANALYSIS_ERROR:
      case ProfileSubStatusEnum.SYSTEM_TIMEOUT_CREDIT_AUTH:
      case ProfileSubStatusEnum.SYSTEM_TIMEOUT:
        return "helpA";
      case ProfileSubStatusEnum.USER_DECLINED_SELECTION:
      case ProfileSubStatusEnum.IDA_SYSTEM_COMPLETED:
      case ProfileSubStatusEnum.IDA_ENROLLMENT_COMPLETED:
      case ProfileSubStatusEnum.CALL_ACTION:
        return "helpB";
      case ProfileSubStatusEnum.IDA_DECLINED_SELECTION:
        return "helpC";
      case ProfileSubStatusEnum.IDA_TIMED_OUT:
        return store.profileStore.hasSelectedPlan ? "helpC" : "helpA";
      default:
        return "";
    }
  };

  getHelpPhoneNumber = (): string => {
    return "8445652669";
  };
}
