import { Drawer, useTheme, useMediaQuery, Box } from "@mui/material";
import { ReactElement } from "react";

import { useStyles } from "./DrawerModal.styles";
interface DrawerModalProps {
  show: boolean;
  setShow?: () => void;
  contentSX?: object;
  children: ReactElement;
}

export const DrawerModal = ({ show, setShow, children, contentSX = {} }: DrawerModalProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();

  return (
    <Drawer classes={{ paper: classes.modal }} anchor={isDesktop ? "right" : "bottom"} open={show} onClose={setShow}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "stretch",
          gap: "8px",
          padding: "0 24px",
          ...contentSX,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            fontSize: "14px",
            fontWeight: 300,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.29,
            letterSpacing: "normal",
            textAlign: "left",
            color: "#464646",
          }}
        >
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerModal;
