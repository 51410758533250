import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    supportedLngs: ["en", "es"],
    preload: ["en", "es"],
    ns: ["common"],
    returnObjects: true,
    debug: false,
    backend: {
      loadPath: "locales/{{lng}}/{{ns}}.json",
    },
    react: {
      useSuspense: true,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "h2", "h3", "ol", "ul", "li", "u"],
    },
    returnNull: false,
    // allow keys to be phrases having `:`
    nsSeparator: false,
  });

export default i18n;
