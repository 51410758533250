import { FirebaseError, Unsubscribe } from "@firebase/util";
import {
  linkWithCredential,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInAnonymously,
  signInWithCredential,
  User,
} from "firebase/auth";
import { makeAutoObservable } from "mobx";

import { Engagement, Profile, StoredProfile } from "@types";
import {
  decodeBase64String,
  errorCodes,
  getThankYouUrl,
  GTMOnboardingInitialization,
  GTMUserLoggedIn,
  GTMUserLoggedOut,
  GTMVerificationError,
  isValidToken,
} from "@utils";

import LoadingStore from "./LoadingStore.ts";
import { store } from "./store";
import { auth } from "../../firebase.config.ts";
import { rollbar } from "../../rollbarConfig.ts";
import { AuthError } from "../utils/errors/AuthError.ts";

type authUserStates = "INITIAL" | "LOADED" | "EMPTY" | "VERIFYING";
class SessionStore {
  authUser?: User | null;
  authUserLoaded: authUserStates = "INITIAL";
  loader: LoadingStore;
  profileLoader: LoadingStore;
  profile?: Profile;
  profileError?: AuthError;
  isLoggingOut: boolean = false;
  isLoadingEngagementData: boolean = false;
  sessionTimer?: NodeJS.Timeout;
  timeLeft: string = "";

  constructor() {
    makeAutoObservable(this);
    this.loader = new LoadingStore();
    this.profileLoader = new LoadingStore();
  }

  userIsLoggingOut = () => {
    this.isLoggingOut = true;
  };

  clearSessionStore = () => {
    this.authUser = undefined;
    this.authUserLoaded = "INITIAL";
    this.loader = new LoadingStore();
    this.profileLoader = new LoadingStore();
    this.profile = undefined;
    this.profileError = undefined;
  };

  setAuthUser = (authUser: User | null) => {
    this.authUser = authUser;
  };

  setProfileError(error: AuthError) {
    this.profileError = error;
  }

  clearProfileError() {
    this.profileError = undefined;
  }

  setAuthUserLoaded = (state: authUserStates) => {
    this.authUserLoaded = state;
  };

  setSessionTimer = (timer: NodeJS.Timeout) => {
    this.sessionTimer = timer;
  };

  clearSessionTimer = () => {
    if (this.sessionTimer) {
      clearInterval(this.sessionTimer);
    }
  };

  setTimeLeft = (timeLeft: string) => {
    this.timeLeft = timeLeft;
  };

  logout = () => {
    GTMUserLoggedOut();
    auth.signOut().then(() => {
      this.userIsLoggingOut();
      store.commonStore.clearStores();
    });
  };

  sessionExpiredLogout = () => {
    GTMUserLoggedOut();
    auth.signOut().then(() => {
      window.location.replace(getThankYouUrl(store.remoteConfigStore.redirectUrl));
      store.commonStore.clearStores();
    });
  };

  verifyPhoneNumber = async (phoneNumber: string, recaptchaVerifier: RecaptchaVerifier) => {
    this.loader.updateState("LOADING");
    const phoneProvider = new PhoneAuthProvider(auth);
    try {
      const verificationId = await phoneProvider.verifyPhoneNumber(
        `+1${phoneNumber.replace(/[^0-9]/g, "")}`,
        recaptchaVerifier,
      );
      this.loader.updateState("SUCCESS");
      return verificationId;
    } catch (error: unknown) {
      this.loader.updateState("ERROR");
      if (error instanceof FirebaseError) {
        throw new AuthError({
          name: (error.code as (typeof errorCodes)[number]) || "internalAuthError",
          message: "SMS auth failed",
        });
      } else if (error instanceof Error) {
        if (error.message.includes("reCAPTCHA")) {
          throw new AuthError({
            name: "auth/recaptcha-render-error",
            message: error.message,
          });
        }
      }
    }
  };

  /**
   * Validates the user provided one time passcode and returns Promise<User>
   * @param verificationId
   * @param otp
   */
  validateVerificationCode = async (verificationId: string, otp: string) => {
    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      if (auth.currentUser && auth.currentUser.isAnonymous) {
        await linkWithCredential(auth.currentUser, credential);
      } else {
        const aa = await signInWithCredential(auth, credential);
        this.setAuthUser(aa.user);
      }
      this.setAuthUserLoaded("LOADED");
    } catch (error: unknown) {
      this.loader.updateState("ERROR");
      if (error instanceof FirebaseError) {
        if (error.code === "auth/account-exists-with-different-credential") {
          auth.currentUser?.delete(); // Delete the anonymous user for returning users with engagement id in the url
          // Authenticate normally
          const credential = PhoneAuthProvider.credential(verificationId, otp);
          await signInWithCredential(auth, credential);
        } else if (error.code === "auth/invalid-verification-code") {
          GTMVerificationError();
          throw new AuthError({
            name: "auth/invalid-verification-code",
            message: "Invalid Verification code",
          });
        } else {
          if (error.code === "auth/code-expired") {
            rollbar.warning("Firebase auth error", error);
          }

          throw new AuthError({
            name: "internalAuthError",
            message: "Authentication Error",
          });
        }
      }
    }
  };

  getEngagementRecordFromLocalStorage = async () => {
    if (!store.profileStore.profile) {
      const storedEngagement = window.localStorage.getItem("engagementData");
      if (storedEngagement) {
        return await store.engagementStore.getEngagementAndServicerById(JSON.parse(storedEngagement).id);
      }
    }
  };

  userSignUpWithEngagementId = async (token: string) => {
    if (token) {
      window.localStorage.removeItem("engagementData");
      window.localStorage.removeItem("lastUsedProfile");
      await signInAnonymously(auth);
      const engagement = await store.engagementStore.getEngagementAndServicerById(decodeBase64String(token));
      if (engagement) {
        store.profileStore.setTempUser(engagement);
        window.localStorage.setItem("engagementData", JSON.stringify(engagement));
        if (engagement.ga_client_id && engagement.lead_type) {
          GTMOnboardingInitialization(engagement.ga_client_id, engagement.lead_type);
        }
      }
    }
  };

  userSignUpWithEngagementIdFromLocalStorage = async () => {
    if (!store.engagementStore.engagement) {
      const engagement = await this.getEngagementRecordFromLocalStorage();
      if (engagement) {
        store.profileStore.setTempUser(engagement as Engagement);
      }
    }
  };

  verifyUser = async (token: string) => {
    await this.getEngagementData(token);
  };

  async getEngagementData(token: string) {
    if (this.isLoadingEngagementData) {
      return;
    }

    this.isLoadingEngagementData = true;

    try {
      if (token && isValidToken(token)) {
        await this.userSignUpWithEngagementId(token as string);
      } else {
        await this.userSignUpWithEngagementIdFromLocalStorage();
      }
    } finally {
      this.isLoadingEngagementData = false;
    }
  }

  storeProfileInLocalStorage = () => {
    const { profile } = store.profileStore;
    const storedProfile: StoredProfile = {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      phone: profile?.phone,
      email: profile?.email,
      phoneSignInMethod: profile?.verification?.phone?.isVerified,
      preferredLanguage: profile?.preferredLanguage,
    };
    window.localStorage.setItem("lastUsedProfile", JSON.stringify(storedProfile));
    window.localStorage.removeItem("engagementData");
  };

  loadProfile = async () => {
    try {
      if (this.authUser) {
        if (this.profileLoader.isNotLoading.get()) {
          this.profileLoader.updateState("LOADING");
          if (store.profileStore.userPhoneInput) {
            await store.profileStore.getUserProfileFromAPI(this.authUser.uid);
          } else {
            if (!store.profileStore.profile) {
              const storedEngagement = window.localStorage.getItem("engagementData");
              if (storedEngagement) {
                await store.engagementStore.getEngagementAndServicerById(JSON.parse(storedEngagement).id);
              }
            }
            if (!store.profileStore.profile && this.authUser.providerData[0].providerId === "phone") {
              await store.profileStore.createOrGetProfile(this.authUser, "sms");
            }
            this.profileLoader.updateState("SUCCESS");
            if (!store.profileStore.profile) {
              this.setProfileError(
                new AuthError({
                  name: "api/no-profile-found",
                  message: "Profile doesn't exist",
                }),
              );
            }
          }
          this.storeProfileInLocalStorage();
        }
      }
    } catch (error) {
      if (error instanceof AuthError) {
        this.setProfileError(error);
      }
    } finally {
      this.profileLoader.updateState("EMPTY");
    }
  };

  /**
   * onAuthStateChanged gets triggered on load of the page to validate user session and after successful verification
   */
  onAuthStateChange = (token: string): Unsubscribe => {
    return auth.onAuthStateChanged(async (user: User | null) => {
      try {
        if (user) {
          GTMUserLoggedIn();
          this.setAuthUser(user);
          this.setAuthUserLoaded("LOADED");
          if (user.isAnonymous) {
            await this.getEngagementData(token);
          } else {
            this.loadProfile();
          }
        } else {
          if (this.profileError) {
            return;
          }
          this.setAuthUserLoaded("VERIFYING");
          try {
            await this.verifyUser(token);
            if (!this.authUser) {
              this.setAuthUserLoaded("EMPTY");
            } else {
              this.setAuthUserLoaded("LOADED");
            }
          } catch (error: unknown) {
            if (error instanceof AuthError) {
              this.setProfileError(error);
            }
          }
        }
      } catch (error) {
        rollbar.debug("Error from onAuthStateChange", error as Error);
      }
    });
  };
}

export default SessionStore;
