import { styled } from "@mui/material/styles";
import Switch, { switchClasses } from "@mui/material/Switch";

import { theme } from "@styles";

export const SwitchText = styled(Switch)({
  width: 80,
  height: 48,
  padding: 8,
  fontSize: `${theme.fontSizes.small} !important`,
  fontWeight: `${theme.fontWeights.bold} !important`,
  lineHeight: `${theme.fontLineHeights.xsmall} !important`,
  fontFamily: "Poppins !important",
  [`& .${switchClasses.switchBase}`]: {
    padding: 11,
    color: "#ff6a00 !important",
  },
  [`& .${switchClasses.thumb}`]: {
    width: 26,
    height: 26,
    backgroundColor: "#fff",
  },
  [`& .${switchClasses.track}`]: {
    backgroundColor: "#f6f7f8 !important",
    opacity: "1 !important",
    borderRadius: 20,
    position: "relative",
    "&:before, &:after": {
      display: "inline-block",
      position: "absolute",
      top: "50%",
      width: "50%",
      transform: "translateY(-50%)",
      color: "#74808b",
      textAlign: "center",
    },
    "&:before": {
      content: '"EN"',
      left: 4,
      opacity: 0,
    },
    "&:after": {
      content: '"ES"',
      right: 4,
    },
  },
  [`& .${switchClasses.checked}`]: {
    [`&.${switchClasses.switchBase}`]: {
      color: "#f6f7f8 !important",
      transform: "translateX(32px)",
    },
    [`& .${switchClasses.thumb}`]: {
      backgroundColor: "#fff !important",
    },
    [`& + .${switchClasses.track}`]: {
      background: "#f6f7f8 !important",
      "&:before": {
        opacity: 1,
      },
      "&:after": {
        opacity: 0,
      },
    },
  },
});
