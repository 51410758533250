import { TextFieldProps, InputAdornment, TextField } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { ReactNode } from "react";

interface IInputBranded extends Omit<TextFieldProps<"filled">, "variant"> {
  startAdorment?: ReactNode;
}

export const InputBranded = (props: IInputBranded) => {
  const theme = useTheme();
  return (
    <TextField
      sx={{
        width: "100%",
        ".Mui-focused": {
          color: `${theme.palette.brandGray.dark} !important`,
        },
        ".Mui-error": {
          color: `${theme.palette.error.main} !important`,
          borderColor: `${theme.palette.error.main} !important`,
          input: {
            background: alpha(theme.palette.error.main, 0.3),
          },
        },
        "& .MuiFilledInput-root": {
          borderRadius: 1,
          backgroundColor: theme.palette.common.white,
          border: "1px solid",
          borderColor: theme.palette.brandGray.main,
          color: theme.palette.brandGray.dark,
          transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        },

        "& .Mui-disabled": {
          borderColor: alpha(theme.palette.brandGray.main, 0.3),
          backgroundColor: theme.palette.brandGray.lighter,
        },
        '& [data-shrink="true"]': {
          color: theme.palette.brandGray.dark,
          fontWeight: "bold",
          textTransform: "uppercase",
        },
      }}
      data-testid="input-text-field"
      label="outlined"
      variant="filled"
      InputProps={{
        ...props.InputProps,
        disableUnderline: true,
      }}
      {...props}
    />
  );
};

export const InputBrandedAdornment = ({ startAdorment, ...props }: IInputBranded) => {
  const theme = useTheme();
  return (
    <TextField
      sx={{
        width: "100%",
        ".Mui-focused": {
          color: `${theme.palette.brandGray.dark} !important`,
        },
        ".Mui-error": {
          color: `${theme.palette.error.main} !important`,
          borderColor: `${theme.palette.error.main} !important`,
        },
        "& .MuiFilledInput-root": {
          border: "1px solid",
          borderColor: theme.palette.brandGray.main,
          borderRadius: 1,
          padding: 0,
          backgroundColor: theme.palette.common.white,
          transition: theme.transitions.create(["background-color", "box-shadow"]),
        },

        "& .Mui-disabled": {
          border: "none",
          borderColor: alpha(theme.palette.brandGray.main, 0.3),
          backgroundColor: theme.palette.common.white,
        },
        "& .MuiInputBase-input": {
          paddingLeft: "24px",
        },
        '[data-shrink="true"]': {
          fontWeight: "bold",
          textTransform: "uppercase",
        },
        '[data-shrink="true"] + div > .MuiInputAdornment-root': {
          display: "flex",
        },
      }}
      label="outlined"
      data-testid="input-text-field"
      variant="filled"
      InputProps={{
        ...props.InputProps,
        disableUnderline: true,
        endAdornment: startAdorment && (
          <InputAdornment
            date-testid="input-text-field-adornment"
            position="start"
            sx={{
              position: "absolute",
              display: "none",
              margin: "0px !important",
              padding: "18px 12px 0px 12px",
            }}
          >
            {startAdorment}
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
