import { useMediaQuery } from "@mui/material";

import { theme } from "@styles";

import { Text } from "../Text";

export const Copyright = () => {
  const currentYear = new Date().getFullYear();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Text
      sx={{
        fontWeight: 300,
        fontSize: "0.625rem",
      }}
      color="gray"
    >
      &copy; {currentYear} {"Instant Debt Advisor℠,"} {isDesktop && <br></br>} {" All Rights Reserved"}
    </Text>
  );
};
