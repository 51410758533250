import { InfoOutlined as InfoOutlinedIcon, ArrowDropDown } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Box, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ButtonBranded, Text } from "@components/common";
import { AddBudgetCategory } from "@components/layout/BudgetTool/AddBudgetCategory";
import { BudgetItem } from "@components/layout/BudgetTool/BudgetItem";
import { BudgetStatus } from "@components/layout/BudgetTool/BudgetStatus";
import { useStore } from "@stores";
import { theme } from "@styles";
import {
  BudgetSubCategoryMap,
  BudgetItemData,
  BudgetItemTypeEnum,
  BudgetTypeEnum,
  JourneyId,
  TSubCategories,
} from "@types";
import { formatNumberToCurrencyString } from "@utils";

interface BudgetToolAccordionProps extends Omit<AccordionProps, "children"> {
  budgetType: BudgetTypeEnum;
  optionalItems?: TSubCategories[];
  handleSubmit: (budgetType: BudgetTypeEnum) => void;
}

export const BudgetToolAccordion = observer(
  ({ budgetType, optionalItems, handleSubmit, expanded, ...props }: BudgetToolAccordionProps) => {
    const { t } = useTranslation("budget_tool");
    const { budgetStore, profileStore } = useStore();
    const [optionsList, setOptionsList] = useState<TSubCategories[]>(optionalItems || []);
    const { data } = budgetStore;
    const items = data[budgetType];
    const isDSP = profileStore.journeyId === JourneyId.DEBT_SETTLEMENT;
    const total = budgetStore.getTotal(budgetType);
    const formatedTotal = total === null ? "--" : formatNumberToCurrencyString(total);
    useEffect(() => {
      const itemsName = items.map(({ name }) => name);
      const filteredItems = optionalItems?.filter((i) => !itemsName.includes(i)) || [];
      setOptionsList(filteredItems);
      budgetStore.setTotal(budgetType, items);
    }, [items, optionalItems, budgetType, budgetStore]);

    const addOptional = (name: TSubCategories) => {
      const newItem = new BudgetItemData({
        name,
        amount: "",
        type: BudgetItemTypeEnum.OPTIONAL,
        subCategory: BudgetSubCategoryMap[name],
      });

      budgetStore.addItem(budgetType, newItem);
      budgetStore.setItemData(newItem);
      budgetStore.setItemBudgetType(budgetType);
      budgetStore.setIsBudgetEdit(true);
      setOptionsList((items) => items.filter((_name) => _name !== name));
    };

    const addCustomClick = () => {
      const newItem = new BudgetItemData({
        amount: "",
        type: BudgetItemTypeEnum.CUSTOM,
      });
      budgetStore.addItem(budgetType, newItem);
      budgetStore.setItemData(newItem);
      budgetStore.setItemBudgetType(budgetType);
      budgetStore.setIsBudgetEdit(true);
    };

    return (
      <Accordion
        elevation={0}
        expanded={expanded}
        disableGutters
        sx={{
          width: "100%",
        }}
        {...props}
      >
        <AccordionSummary
          sx={{
            paddingX: 1,
            flexDirection: "row-reverse",
            background: theme.palette.common.white,
          }}
          expandIcon={<ArrowDropDown />}
          data-cy={`BudgetToolAccordion-section-${budgetType}`}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{
              [theme.breakpoints.down("md")]: {
                paddingX: theme.spacing(2),
              },
            }}
          >
            <Grid item>
              <Text fontWeight="semiBold" textTransform="capitalize">
                {t(budgetType)}
              </Text>
            </Grid>

            <Grid item>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Text mr={4} data-cy={`BudgeToolAccordion-total-${budgetType}`}>
                  ${formatedTotal}
                </Text>
                <BudgetStatus data-cy={`${budgetType.toString()}-status`} type={budgetStore.statuses[budgetType]} />
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            background: theme.palette.brandGray.lighter,
            paddingY: 5,
            paddingX: 3,
          }}
        >
          <Box display="flex" flexDirection="column" gap={3}>
            {isDSP && budgetType === BudgetTypeEnum.DEBT && (
              <Box display="flex" gap={1}>
                <InfoOutlinedIcon />
                <Text fontSize="xsmall" lineHeight="xxsmall">
                  {t("debtMessage")}
                </Text>
              </Box>
            )}
            <Box display="flex" flexDirection="column" gap={1.5}>
              {items.map((bi) => (
                <BudgetItem data={bi} budgetType={budgetType} />
              ))}
            </Box>
            {budgetStore.isRequiredEmpty(budgetType) && (
              <Box display="flex" gap={1}>
                <InfoOutlinedIcon />
                <Text fontSize="xsmall" lineHeight="small">
                  {t("categoryMessage")}
                </Text>
              </Box>
            )}
            {!(isDSP && budgetType === BudgetTypeEnum.DEBT) && (
              <Box display="flex" justifyContent="center" gap={1}>
                {isDSP && budgetType !== BudgetTypeEnum.DEBT && (
                  <ButtonBranded
                    variant="secondary"
                    data-cy={`budget-tool-add-custom-${budgetType}`}
                    fontSize={theme.fontSizes.medium}
                    sx={{ height: 40, fontWeight: 600, paddingX: 0 }}
                    onClick={addCustomClick}
                  >
                    {t("addCustom")}
                  </ButtonBranded>
                )}
                <ButtonBranded
                  variant="primary"
                  data-cy={`budget-tool-finish-${budgetType}`}
                  fontSize={theme.fontSizes.medium}
                  disabled={budgetStore.isRequiredEmpty(budgetType)}
                  sx={{
                    height: 40,
                    fontWeight: 600,
                    paddingX: 0,
                    textTransform: "capitalize",
                  }}
                  onClick={() => handleSubmit(budgetType)}
                >
                  {status === "completed"
                    ? t("update", { budgetType: t(budgetType) })
                    : t("finish", { budgetType: t(budgetType) })}
                </ButtonBranded>
              </Box>
            )}
            {optionsList.length > 0 && (
              <>
                <Text fontSize="small" fontWeight="semiBold" textAlign="center" textTransform="uppercase">
                  {t("otherTypes", { budgetType: t(budgetType) })}
                </Text>
                <Box display="flex" flexDirection="column" gap={1.5}>
                  {optionsList.map((o) => (
                    <AddBudgetCategory category={o} key={o} onClick={() => addOptional(o)} />
                  ))}
                </Box>
              </>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  },
);
