import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import { ButtonBranded } from "@components/common";
import { useStore } from "@stores";

import { useStyles } from "./DebtJourney.styles.ts";
import { auth } from "../../../../firebase.config.ts";

export const DebtJourney = observer(() => {
  const { profileStore, commonStore, sessionStore } = useStore();
  const { profile } = profileStore;
  const classes = useStyles();

  return (
    <Box className={classes.contentViewContainer}>
      <Typography>
        Authenticated user: {profile?.firstName}
        {profile?.lastName}
      </Typography>
      <Box>
        <ButtonBranded
          data-cy="sign-out-button"
          variant="textonly"
          onClick={async () => {
            auth.signOut().then(() => {
              sessionStore.userIsLoggingOut();
              commonStore.clearStores();
            });
          }}
        >
          Sign Out
        </ButtonBranded>
      </Box>
    </Box>
  );
});
