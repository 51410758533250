/// <reference types="vite-plugin-svgr/client" />
import { useStyles } from "./UserPlanTag.styles.ts";
import { Text } from "../Text";

export type UserPlanTagVariants = "primary" | "secondary" | "tertiary";

export interface UserPlanTagProps {
  planType: string;
  variant: UserPlanTagVariants;
}

export const UserPlanTag: React.FC<UserPlanTagProps> = ({ planType, variant }) => {
  const classes = useStyles({ variant });
  return (
    <div className={`${classes.tag} ${classes[variant]}`}>
      <Text fontSize="xsmall" fontWeight="bold" color={variant == "primary" ? "brandBlue" : "white"}>
        {planType}
      </Text>
    </div>
  );
};
