import { CloseRounded } from "@mui/icons-material";
import { Grid, IconButton, Paper, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, ReactNode, CSSProperties } from "react";

import { useStore } from "@stores";
import { GTMExitClick } from "@utils";

import { useStyles } from "./Modal.styles";
import { ContinueChatButton } from "../ContinueChatButton";

type Props = {
  children?: ReactNode;
  style?: CSSProperties;
};
export const Modal: FC<Props> = observer(({ children, style }) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    modalStore: { module, closeModal, setShowOffRampModal, setClickSource },
    profileStore: { getUserProfileFromAPI },
    sessionStore: { authUser },
    chatStore: { hasActiveChat },
  } = useStore();

  const close = () => {
    GTMExitClick(module.name);
    switch (module.name) {
      case "PlanSelected":
        getUserProfileFromAPI(authUser!.uid).then(() => {
          closeModal();
        });
        return;
      default:
        setClickSource("header");
        setShowOffRampModal(true);
    }
  };

  return (
    <Paper className={classes.root} sx={style}>
      {module.name !== "CallForHelp" && (
        <Grid container className={classes.header}>
          {hasActiveChat && (
            <Grid item>
              <ContinueChatButton />
            </Grid>
          )}
          <Grid item ml={"auto"}>
            <IconButton onClick={() => close()} data-cy="credit-pull-exit" sx={{ color: theme.palette.brandGray.main }}>
              <CloseRounded />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <div className={classes.content}>{children}</div>
    </Paper>
  );
});
