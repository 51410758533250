import { useRollbar } from "@rollbar/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import {
  AuthenticationForm,
  DebtJourney,
  Home,
  Profile,
  UserPlans,
  InvalidSignInLink,
  ErrorView,
  TempThankYou,
} from "@components/views";
import { DashboardWrapper, VerificationWrapper } from "@components/wrappers";
import { ProtectedRouteOutlet } from "@routes";
import { useStore } from "@stores";
import { GTMPageView } from "@utils";

export const ApplicationRoutes: FC = observer(() => {
  const rollbar = useRollbar();

  const { remoteConfigStore, sessionStore, profileStore, chatStore } = useStore();

  const location = useLocation();
  const prevPathRef = useRef(location.pathname);

  // This makes sure we only send the GTM event if the path is different each time
  // ignoring reloading etc
  useEffect(() => {
    const currentPath = location.pathname;
    const previousPath = prevPathRef.current;

    if (currentPath !== previousPath) {
      GTMPageView(currentPath);
      prevPathRef.current = currentPath;
    }
  }, [location]);

  useEffect(() => {
    if (profileStore.profile && !chatStore.initialized) {
      chatStore.initializeChat(profileStore.profile);
    }
  }, [profileStore.profile, chatStore]);

  useEffect(() => {
    if (sessionStore.authUser) {
      rollbar.configure({
        payload: {
          person: {
            id: sessionStore.authUser.uid,
          },
        },
      });
    }

    if (sessionStore.authUser || remoteConfigStore.configsExistsInStorage) {
      remoteConfigStore.getConfigs();
    }
  }, [remoteConfigStore, sessionStore.authUser, rollbar]);

  return (
    <Routes>
      {/* Protected Routes that needs to be authenticated */}
      <Route element={<ProtectedRouteOutlet />}>
        <Route element={<DashboardWrapper />}>
          <Route key="dashboard" path="/dashboard" element={<Home />} />
          <Route key="profile" path="/profile" element={<Profile />} />
          <Route key="main" path="/" element={<DebtJourney />} />
          <Route key="user-plans" path={"/user-plans"} element={<UserPlans />} />
        </Route>
      </Route>

      {/* Public Routes */}
      <Route element={<VerificationWrapper />}>
        <Route key="auth" path="auth" element={<AuthenticationForm />} />
        <Route key="invalidEmailLink" path="invalidEmailLink" element={<InvalidSignInLink />} />
        <Route key="error" path="error" element={<ErrorView />} />
        <Route key="thankyou" path="thankyou" element={<TempThankYou />} />
      </Route>
    </Routes>
  );
});
