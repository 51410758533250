import { Buffer } from "buffer";

import {
  Engagement,
  EngagementDebtTypes,
  IProfileJourney,
  JourneyName,
  MyPlanWorkflowOptions,
  MyPlanWorkflowOptionsEnum,
  Profile,
  ProfileResponse,
  ProfileVerificationInfo,
  Servicer,
  ServicerId,
  ServicerIdEnum,
  VTPageTitles,
  VTPageTitlesEnum,
} from "@types";
import { JourneyCTAEnum } from "@utils";
import BudgetStore from "src/stores/budgetStore";
import ProfileStore from "src/stores/profileStore";

import { ProfileSubStatusEnum } from "./subStatus";
import i18n from "../../i18n.config";

/**
 *
 * @param {string} input base64 encoded string
 * @returns {string} returns a decoded string
 */
export const decodeBase64String = (input: string) => {
  return Buffer.from(input, "base64").toString("utf-8");
};

/**
 *
 * @param {string} input a string to encode
 * @returns {string} returns a base 64 encoded string
 */
export const encodeBase64String = (input: string) => {
  return Buffer.from(input, "utf-8").toString("base64");
};

/**
 *
 * @param {string} token base64 encoded string
 * @returns {boolean} returns true if token is valid, false otherwise
 */
export const isValidToken = (token: string) => {
  const b64RegExPattern: RegExp = /^[-A-Za-z0-9+/]*={0,3}$/;

  return token != null && b64RegExPattern.test(token) && decodeBase64String(token).length == 28;
};

export const checkVerificationMethod = (
  value: string,
  defaultValues: { email: string; phone: string },
): ProfileVerificationInfo => {
  const verificationInfo: ProfileVerificationInfo = {
    email: {
      value: defaultValues.email ?? "",
      isPreferred: false,
      isVerified: false,
    },
    phone: {
      value: defaultValues.phone ?? "",
      isPreferred: false,
      isVerified: false,
    },
  };
  verificationInfo.phone = {
    value,
    isPreferred: true,
    isVerified: true,
    verificationDate: new Date(),
  };
  return verificationInfo;
};

export const convertEngagementToProfileObject = (
  engagement: Engagement,
  verificationValue: string,
  id: string,
  servicer?: Servicer,
): Profile => {
  const { first_name, last_name, email, phone_mobile, address } = engagement.profile;
  const { employment_status, hhi_consistency, household_income, debt_amount, debt_types } = engagement.attributes;
  return {
    id,
    servicer,
    engagementId: engagement.id,
    firstName: first_name,
    lastName: last_name,
    email,
    phone: phone_mobile,
    address: {
      state: address.state,
    },
    ssn: "",
    dob: { year: "", month: "", day: "" },
    preferredLanguage: engagement.profile.language,
    employmentStatus: employment_status,
    hhiConsistency: hhi_consistency,
    householdIncome: household_income,
    verification: checkVerificationMethod(verificationValue, {
      email,
      phone: phone_mobile,
    }),
    subStatus: ProfileSubStatusEnum.IDA_ENROLLMENT_STARTED,
    engagementDebtTypes: debt_types as EngagementDebtTypes[],
    debtAmount: debt_amount,
    engagementLeadType: engagement.lead_type as JourneyName,
    documents: {},
  };
};

/**
 * Format's a number into proper currency, e.g.: `1000000` will return: '$1,000,000'
 *
 * @param {number} amount - a dollar amount unformatted
 * @returns {string} returns the dollar amount formatted
 */
export const formatNumberToCurrencyString = (amount?: number): string | undefined => {
  if (amount !== undefined) {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }
};

export const formatDollar = (amount: number) => {
  return formatNumberToCurrencyString(Math.round(amount));
};

export const getServicerLogo = (servicerId: ServicerId) => {
  switch (servicerId) {
    case ServicerIdEnum.LIGHT_HOUSE_FINANCIAL:
      return "lighthouse.png";
    case ServicerIdEnum.CONSOLIDATED_CREDIT:
      return "consolidatedCredit.png";
    default:
      return "";
  }
};

export const isPlanSelectionAbandoned = (profile: ProfileResponse) => {
  return (
    profile.subStatus === ProfileSubStatusEnum.IDA_DECLINED_SELECTION ||
    profile.subStatus === ProfileSubStatusEnum.USER_DECLINED_SELECTION ||
    profile.subStatus === ProfileSubStatusEnum.SYSTEM_TIMEOUT_CREDIT_AUTH ||
    profile.subStatus === ProfileSubStatusEnum.SYSTEM_TIMEOUT
  );
};

export const isPlanSelectedByUser = (journey?: IProfileJourney): boolean => {
  return Boolean(journey && journey.completedCallToActionIds.includes("select-plan"));
};

export const formatAmountWithCurrencyUnit = (amount: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export const formatToNearestDollar = (amount: number) => {
  const res = new Intl.NumberFormat("en-US", {
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
  return res === "NaN" ? "" : res;
};

export const formatDateMMDDYYY = (date: string) => {
  if (date) {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  }
};

export const formatDate = (date: string) => {
  if (!date) {
    return;
  }
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString(`${i18n.language}-US`, {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
};

function hasSubStatus(profile: ProfileResponse, statuses: ProfileSubStatusEnum[]): boolean {
  return statuses.includes(profile.subStatus);
}

export const shouldShowMyPlanNavItem = (profile: ProfileResponse): boolean => {
  return profile.subStatus != ProfileSubStatusEnum.IDA_ENROLLMENT_STARTED;
};

export const determineMyPlansPageVariant = (profile: ProfileResponse): MyPlanWorkflowOptions => {
  const authorizedCreditPull = profile.completedCallToActionIds.includes("authorize-credit-pull");
  const leadType = profile.engagementLeadType;

  // Positive - The user authorizes a credit pull and successfully completes the workflow (positive path)
  // Or the user is in debt management, and the workflow is completed
  if (
    (authorizedCreditPull || leadType === JourneyName.DEBT_MANAGEMENT) &&
    hasSubStatus(profile, [ProfileSubStatusEnum.IDA_ENROLLMENT_COMPLETED, ProfileSubStatusEnum.IDA_SYSTEM_COMPLETED])
  ) {
    return MyPlanWorkflowOptionsEnum.POSITIVE;
  }

  // Semi-positive - The user authorizes a credit pull. Credit pull executes successfully, but the user abandons
  if (
    authorizedCreditPull &&
    hasSubStatus(profile, [
      ProfileSubStatusEnum.IDA_DECLINED_SELECTION,
      ProfileSubStatusEnum.USER_DECLINED_SELECTION,
      ProfileSubStatusEnum.IDA_TIMED_OUT,
    ])
  ) {
    return MyPlanWorkflowOptionsEnum.SEMI_POSITIVE;
  }

  // Negative - The user reaches the credit auth screen, but does NOT authorize the credit pull
  if (
    !authorizedCreditPull &&
    hasSubStatus(profile, [
      ProfileSubStatusEnum.IDA_DECLINED_CREDIT,
      ProfileSubStatusEnum.USER_DECLINED_CREDIT,
      ProfileSubStatusEnum.IDA_TIMED_OUT,
      ProfileSubStatusEnum.SYSTEM_TIMEOUT_BEFORE_CREDIT,
    ])
  ) {
    return MyPlanWorkflowOptionsEnum.NEGATIVE;
  }

  // Default - The user authorizes a credit pull but the credit report is not available or there are no eligible trade lines
  if (
    authorizedCreditPull &&
    hasSubStatus(profile, [
      ProfileSubStatusEnum.IDA_ANALYSIS_ERROR,
      ProfileSubStatusEnum.IDA_CREDIT_ERROR,
      ProfileSubStatusEnum.SYSTEM_ERROR,
    ])
  ) {
    return MyPlanWorkflowOptionsEnum.DEFAULT;
  }

  // Fallback to default in cases not covered explicitly
  return MyPlanWorkflowOptionsEnum.DEFAULT;
};

export const maskPhoneNumber = (phone?: string) => {
  if (!phone) return "";

  const phoneDigits = phone.replace(/\D/g, "");
  return phoneDigits.replace(/^(\d{6})(\d{4})$/, "(***) ***-$2");
};

export const shouldOpenCreditAuthModule = (
  hasModalBeenClosedManually: boolean,
  hasCreditAuthBeenCompleted: boolean,
  profile: ProfileResponse,
) => {
  return (
    !hasModalBeenClosedManually &&
    !hasCreditAuthBeenCompleted &&
    profile.subStatus === ProfileSubStatusEnum.IDA_ENROLLMENT_STARTED &&
    !profile.journeys?.["debt-management"]
  );
};

export const shouldOpenBudgetModule = (
  profileStore: ProfileStore,
  budgetStore: BudgetStore,
  hasModalBeenClosedManually: boolean,
) => {
  const isBudgetComplete = profileStore.journey?.completedCallToActionIds?.includes(
    JourneyCTAEnum["complete-your-budget"],
  );
  return !budgetStore.isBudgetVisited && !isBudgetComplete && !hasModalBeenClosedManually;
};
export const setLanguage = (lng?: string) => {
  if (lng && i18n.language !== lng) {
    i18n.changeLanguage(lng);
  }
};

export const handlePercentageAndPageCompleted = async (
  enableBudgetTool: boolean = false,
  currentPageTitle: VTPageTitles,
  profileStore: ProfileStore,
) => {
  const pages = [
    VTPageTitlesEnum.SERVICER_CONSENT,
    VTPageTitlesEnum.CREDIT_AUTHORIZATION,
    VTPageTitlesEnum.DEBT_REVIEW,
    ...(enableBudgetTool ? [VTPageTitlesEnum.BUDGET_TOOL] : []),
    VTPageTitlesEnum.SELECT_PLAN,
    VTPageTitlesEnum.DONE,
  ];

  const totalSteps = pages.length + 1;
  const currentStepIndex = pages.indexOf(currentPageTitle) + 2;

  const percentageComplete = (currentStepIndex / totalSteps) * 100;

  await profileStore.updatePercentageCompleteAndLastPageCompleted(percentageComplete, currentPageTitle);
};

export const getThankYouUrl = (redirectUrl: string) => {
  return i18n.language === "es" ? `${redirectUrl}es/gracias` : `${redirectUrl}thank-you`;
};

export const getServicerPhoneNumber = (leadType: string) => {
  if (leadType === "Debt Management") {
    return i18n.language === "en" ? "+18442112231" : "+18442435215";
  } else {
    return i18n.language === "en" ? "+18445682101" : "+18447446895";
  }
};

export function capitalize(word: string) {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function getFormattedTodayDateUS() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const year = today.getFullYear();

  return `${month.toString().padStart(2, "0")}/${day.toString().padStart(2, "0")}/${year}`;
}

export function isDateExpired(date: string | Date) {
  const now = new Date().getTime();
  const targetDate = new Date(date).getTime();

  return now > targetDate;
}

/**
 * Retrieves the TrustedForm URL from the hidden field in the DOM.
 *
 * @returns {string} The TrustedForm URL or an empty string if not found.
 */
export const getTrustedFormUrl = () => {
  const hiddenField = document.getElementsByName("xxTrustedFormCertUrl")[0] as HTMLInputElement;
  const trustedFormUrl = hiddenField?.value;
  return trustedFormUrl ?? "";
};
