import { Typography, TypographyProps, useTheme } from "@mui/material";

import {
  fontSizes,
  fontWeights,
  fontLineHeights,
  ColorOptions,
  FontLineHeightOptions,
  FontSizeOptions,
  FontWeightOptions,
} from "@styles";
import { colorMap } from "@utils";

export interface TextProps extends TypographyProps {
  color?: ColorOptions;

  /**
   * Font Sizes being used by the design.
   * Update with any values as needed.
   *
   * xxsmall = 10px //
   * xsmall = 12px //
   * small = 14px //
   * medium = 16px //
   * large = 18px //
   * xlarge = 20px //
   * xxlarge = 24px //
   * xxxlarge = 30px //
   * xxxxlarge = 32px //
   * xxxxxlarge = 48px //
   */
  fontSize?: FontSizeOptions;

  /**
   * Font Weights being used by the design.
   * Update with any values as needed.
   *
   * light = 300 //
   * regular = normal (400) //
   * medium = 500 //
   * semiBold = 600 //
   * bold = bold (700)
   */
  fontWeight?: FontWeightOptions;

  /**
   * Font Line-Heigths being used by the design.
   * Update with any values as needed.
   *
   * xxxsmall = 16px //
   * xxsmall = 18px //
   * xsmall = 20px //
   * small = 22px //
   * medium = 24px //
   * large = 28px //
   * xlarge = 30px //
   * xxlarge = 32px //
   * xxxlarge = 34px //
   * xxxxlarge = 36px //
   * xxxxxlarge = 38px
   */
  lineHeight?: FontLineHeightOptions;
  children: React.ReactNode;
}

export const Text: React.FC<TextProps> = ({
  color = "darkerGray",
  fontSize = "medium",
  fontWeight = "regular",
  lineHeight,
  children,
  ...props
}) => {
  const theme = useTheme();

  /**
   * If lineHeight is provided - Use it, otherwise use the fontSize to find the lineHeight
   */
  const getLineHeight = () => {
    return lineHeight ? fontLineHeights[lineHeight] : fontSizes[fontSize];
  };

  return (
    <Typography
      fontSize={fontSizes[fontSize]}
      fontWeight={fontWeights[fontWeight]}
      lineHeight={getLineHeight()}
      color={colorMap(theme)[color]}
      {...props}
      className={props.className}
    >
      {children}
    </Typography>
  );
};
