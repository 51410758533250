import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DownArrow from "@assets/icons/doubleDownArrow.svg";

import { Text } from "../Text";

export const ScrollIndicator: FC<{
  spaceFromBottom?: number;
  scrollDivId: string;
  atBottomCallback?: () => void;
  displayOnDesktop?: boolean;
}> = ({ spaceFromBottom = 40, scrollDivId, atBottomCallback, displayOnDesktop = false }) => {
  const { t, i18n } = useTranslation("common");
  const [isAtBottom, setIsAtBottom] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      let isBottom = false;
      const element = document.getElementById(scrollDivId);
      if (element) {
        const scrolled = element.scrollTop;
        const maxHeight = element.scrollHeight - element.clientHeight;
        const scrollPercent = Math.ceil((scrolled / maxHeight) * 100);
        isBottom = scrollPercent >= 99 || element.scrollHeight <= element.clientHeight;
        setIsAtBottom(isBottom);
      }
      if (isBottom) {
        atBottomCallback?.();
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollDivId, atBottomCallback]);

  return (
    <Box
      sx={{
        visibility: { xs: "visible", md: displayOnDesktop ? "visible" : "hidden" },
        position: "absolute",
        bottom: spaceFromBottom,
        right: 24,
        zIndex: 9999,
        backgroundColor: (theme) => theme.palette.brandDarkBlue.dark,
        width: "56px",
        height: "56px",
        padding: i18n.language === "es" ? "10px" : "5px",
        borderRadius: "50%",
        display: isAtBottom ? "none" : "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "2px",
        alignItems: "center",
        transition: "transform 0.3s ease",
        textAlign: "center",
      }}
    >
      <Text color="white" fontSize="xsmall">
        {t("scroll")}
      </Text>
      <img src={DownArrow} alt="Scroll Indicator" />
    </Box>
  );
};
