import { Text } from "@components/common";

import { useStyles } from "./TempThankYou.styles";

// TODO: This needs to be removed once we have a thank you page designed.
export const TempThankYou = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Text fontSize="xxxlarge">You've been timed out.</Text>
      <Text fontSize="xxxxxlarge">Thank You.</Text>
    </div>
  );
};

export default TempThankYou;
