import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";

import { Tools } from "@components/layout";
import { ProfileTrackerCard } from "@components/layout/ProfileTrackerCard";
import { useStore } from "@stores";

interface MiniDashboardProps {
  endState?: string;
}

export const MiniDashboard: React.FC<MiniDashboardProps> = observer(() => {
  const { profileStore, commonStore } = useStore();

  return (
    <Grid container spacing={8}>
      {profileStore.profile &&
        commonStore.getHelpPageView() !== "helpA" &&
        profileStore.profile.profilePercentageComplete !== 100 && (
          <Grid item xs={12}>
            <ProfileTrackerCard profile={profileStore.profile} />
          </Grid>
        )}
      <Grid item id="tools">
        <Tools />
      </Grid>
    </Grid>
  );
});
