import { Card, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ButtonBranded, ButtonVariants, Slider, Text } from "@components/common";
import { useStore } from "@stores";
import { useSessionTimer } from "@utils";

import { useStyles } from "./ConfirmationModal.styles";

interface ConfirmationModalProps {
  primaryOnClick: () => void;
  secondaryOnClick?: () => void;
  header?: string;
  infoText?: string;
  primaryButtonLabel: string;
  primaryVariant?: ButtonVariants;
  secondaryButtonLabel?: string;
  summary?: string[];
  disclaimer?: string;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = observer(
  ({ header, infoText, summary, disclaimer, ...props }: ConfirmationModalProps) => {
    const classes = useStyles();
    const { chatStore } = useStore();

    return (
      <div className={classes.sliderContainer} data-cy="slider">
        <Slider direction={"up"} show transitionTime={500}>
          <Card className={classes.modalCard}>
            <Grid container className={classes.modalContainer}>
              <Header header={header} />
              <InfoText infoText={infoText} />
              <Summary summary={summary} />
              <Disclaimer disclaimer={disclaimer} />
              {chatStore.enabled ? (
                chatStore.hasActiveChat ? (
                  <ActiveChatButtons {...props} />
                ) : (
                  <ChatEnabledButtons {...props} />
                )
              ) : (
                <Buttons {...props} />
              )}
            </Grid>
          </Card>
        </Slider>
      </div>
    );
  },
);

export default ConfirmationModal;

function Disclaimer({ disclaimer }: Partial<ConfirmationModalProps>) {
  if (!disclaimer) return null;

  return (
    <Grid item xs={12} my={2} padding="16px" borderRadius="4px" bgcolor={(theme) => theme.palette.brandGray.lighter}>
      <Text fontSize="xsmall" fontWeight="regular" lineHeight="xsmall">
        {disclaimer}
      </Text>
    </Grid>
  );
}

function Header({ header }: Partial<ConfirmationModalProps>) {
  if (!header) return null;

  return (
    <Grid item xs={12}>
      <Text fontSize="xlarge" fontWeight="semiBold" lineHeight="xlarge" color="darkerGray">
        {header}
      </Text>
    </Grid>
  );
}

function InfoText({ infoText }: Partial<ConfirmationModalProps>) {
  if (!infoText) return null;

  return (
    <Grid item xs={12}>
      <Text fontSize="medium" fontWeight="regular" lineHeight="medium" color="darkerGray" sx={{ paddingTop: "8px" }}>
        <Trans>{infoText}</Trans>
      </Text>
    </Grid>
  );
}

function Summary({ summary }: Partial<ConfirmationModalProps>) {
  if (!summary) return null;

  if (summary && summary.length > 1) {
    return (
      <ul data-cy="cta-summary">
        {summary.map((item, idx) => {
          return (
            <Trans key={idx}>
              <li key={idx}>{item}</li>
            </Trans>
          );
        })}
      </ul>
    );
  } else {
    return (
      <Grid item xs={12} pt={1}>
        <Text data-cy="cta-summary" fontSize="medium" fontWeight="regular" lineHeight="medium" color="darkerGray">
          <Trans>{summary && summary[0]}</Trans>
        </Text>
      </Grid>
    );
  }
}

function ChatEnabledButtons({
  primaryVariant,
  primaryOnClick,
  primaryButtonLabel,
  secondaryButtonLabel,
  secondaryOnClick,
}: Partial<ConfirmationModalProps>) {
  const { forceExtendSession } = useSessionTimer();
  const {
    chatStore: { launchChat },
  } = useStore();
  const { t } = useTranslation("common");
  const classes = useStyles();

  return (
    <Grid container rowGap={1.5} item display="flex" xs={12} alignItems="center" flexDirection={{ md: "row-reverse" }}>
      <Grid item xs={12} lg={secondaryButtonLabel ? 4 : 6} className={classes.buttonPrimary}>
        <ButtonBranded
          data-cy="launch-live-chat-button"
          variant={primaryVariant}
          className={classes.buttonFormatting}
          onClick={() => {
            launchChat();
            forceExtendSession();
          }}
        >
          {t("chat")}
        </ButtonBranded>
      </Grid>

      <Grid item xs={12} lg={secondaryButtonLabel ? 4 : 6} className={classes.buttonPrimary}>
        <ButtonBranded
          data-cy="confirmation-modal-button-primary"
          variant="secondary"
          className={classes.buttonFormatting}
          onClick={primaryOnClick}
        >
          {primaryButtonLabel}
        </ButtonBranded>
      </Grid>

      {secondaryButtonLabel && (
        <Grid item xs={12} lg={4} textAlign={{ xs: "center", lg: "left" }}>
          <ButtonBranded
            data-cy="confirmation-modal-button-secondary"
            variant="textonly"
            className={classes.buttonFormatting}
            onClick={secondaryOnClick}
          >
            {secondaryButtonLabel}
          </ButtonBranded>
        </Grid>
      )}
    </Grid>
  );
}

function ActiveChatButtons({
  primaryVariant,
  primaryOnClick,
  primaryButtonLabel,
  secondaryButtonLabel,
  secondaryOnClick,
}: Partial<ConfirmationModalProps>) {
  const { forceExtendSession } = useSessionTimer();
  const {
    chatStore: { launchChat },
  } = useStore();
  const { t } = useTranslation("common");
  const classes = useStyles();

  return (
    <Grid container rowGap={1.5} item display="flex" xs={12} alignItems="center" flexDirection={{ md: "row-reverse" }}>
      <Grid item xs={12} lg={secondaryButtonLabel ? 4 : 6} className={classes.buttonPrimary}>
        <ButtonBranded
          data-cy="confirmation-modal-button-primary"
          variant={primaryVariant}
          className={classes.buttonFormatting}
          onClick={primaryOnClick}
        >
          {primaryButtonLabel}
        </ButtonBranded>
      </Grid>

      <Grid item xs={12} lg={secondaryButtonLabel ? 4 : 6} className={classes.buttonPrimary}>
        <ButtonBranded
          data-cy="launch-live-chat-button"
          variant="secondary"
          className={classes.buttonFormatting}
          onClick={() => {
            launchChat();
            forceExtendSession();
          }}
        >
          {t("continueChat")}
        </ButtonBranded>
      </Grid>

      {secondaryButtonLabel && (
        <Grid item xs={12} lg={4} textAlign={{ xs: "center", lg: "left" }}>
          <ButtonBranded
            data-cy="confirmation-modal-button-secondary"
            variant="textonly"
            className={classes.buttonFormatting}
            onClick={secondaryOnClick}
          >
            {secondaryButtonLabel}
          </ButtonBranded>
        </Grid>
      )}
    </Grid>
  );
}

function Buttons({
  primaryVariant,
  primaryOnClick,
  primaryButtonLabel,
  secondaryButtonLabel,
  secondaryOnClick,
}: Partial<ConfirmationModalProps>) {
  const classes = useStyles();

  return (
    <Grid
      container
      rowGap={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt={2}
      flexDirection={{ md: "row-reverse" }}
    >
      <Grid
        container
        rowGap={1.5}
        item
        display="flex"
        xs={12}
        alignItems="center"
        flexDirection={{ md: "row-reverse" }}
      >
        <Grid item xs={12} lg={secondaryButtonLabel ? 6 : 12} className={classes.buttonPrimary}>
          <ButtonBranded
            data-cy="confirmation-modal-button-primary"
            variant={primaryVariant}
            className={classes.buttonFormatting}
            onClick={primaryOnClick}
          >
            {primaryButtonLabel}
          </ButtonBranded>
        </Grid>
        {secondaryButtonLabel && (
          <Grid item xs={12} lg={6}>
            <ButtonBranded
              data-cy="confirmation-modal-button-secondary"
              variant="secondary"
              className={classes.buttonFormatting}
              onClick={secondaryOnClick}
            >
              {secondaryButtonLabel}
            </ButtonBranded>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
