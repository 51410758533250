import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ButtonBranded, Text } from "@components/common";
import { CloseButton } from "@components/common/CloseButton/index.tsx";
import InnerTopLabelTextField from "@components/common/InnerTopLabelTextField";
import { useStore } from "@stores";
import { theme } from "@styles";
import { StoredProfile } from "@types";
import { maskPhoneNumber } from "@utils";

import { useStyles } from "./PassCodeEntry.styles.ts";

export interface PassCodeEntryProps {
  verificationId: string;
  resend: () => Promise<string | undefined>;
  closeSlider: () => void;
  storedProfile?: StoredProfile;
}
const PassCodeEntry = ({ verificationId, storedProfile, resend, closeSlider }: PassCodeEntryProps) => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const { profileStore, remoteConfigStore } = useStore();

  const classes = useStyles();
  const { sessionStore } = useStore();
  const { t } = useTranslation("verification_page");

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setIsResendDisabled(false);
      },
      remoteConfigStore.timers.resendCodeTimeout * 1000 || 5000,
    );

    return () => clearTimeout(timer);
  }, [remoteConfigStore.timers.resendCodeTimeout]);

  /**
   * Validates the entered otp
   * validateVerificationCode automatically triggers onAuthStateChanged callback that is defined in the useEffect hook
   * @param event
   */
  const verifyOtp = async () => {
    const code = otp.split(" ").join("");
    if (code.length == 6) {
      if (verificationId) {
        try {
          setIsLoading(true);
          await sessionStore.validateVerificationCode(verificationId, code);
        } catch (error: unknown) {
          if (error instanceof Error) {
            setError(t(error.message));
          }
          setIsLoading(false);
        }
        sessionStore.loadProfile();
      }
    }
  };

  const handleOtpOnEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && otp) {
      event.preventDefault();
      verifyOtp();
    }
  };

  const phoneNumber = storedProfile?.phone || profileStore.tempUser.phone || profileStore.verificationMethodValue || "";

  return (
    <div className={classes.root} onKeyDown={handleOtpOnEnter}>
      <CloseButton onClick={closeSlider} />
      <Text fontSize="xlarge" fontWeight="semiBold" lineHeight="xlarge">
        {t("verificationCodeHeader")}
      </Text>
      <Text fontSize="small" fontWeight="light" lineHeight="xxsmall" color="darkGray">
        {t("verificationCodeSubHeader", {
          phone: maskPhoneNumber(phoneNumber),
        })}
      </Text>
      <InnerTopLabelTextField
        data-cy="otp-input"
        length={6}
        spaceAfter={3}
        label={t("verificationCodeLabel")}
        onCodeChange={(val) => {
          setOtp(val);
        }}
      />
      <div className={classes.resendButton}>
        <ButtonBranded
          fontSize={theme.fontSizes.medium}
          fontWeight={theme.fontWeights.medium}
          lineHeight={theme.fontLineHeights.xxxsmall}
          padding="0px 5px"
          variant="textonlyaccent"
          onClick={resend}
          disabled={isResendDisabled || isLoading}
        >
          {t("resendCode")}
        </ButtonBranded>
      </div>
      <div className={classes.setButtonWidth}>
        <ButtonBranded
          onClick={verifyOtp}
          disabled={!otp}
          data-cy="otp-submit-button"
          fontWeight={theme.fontWeights.medium}
          loading={isLoading}
        >
          {t("verify")}
        </ButtonBranded>
      </div>
      {error && (
        <div className={classes.alertBox}>
          <Alert severity="error">{error}</Alert>
        </div>
      )}
    </div>
  );
};
export default PassCodeEntry;
