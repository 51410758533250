import { useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReactNode, Suspense } from "react";
import { FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";

import { AnimatedColorBar, Loader } from "@components/common";
import { CallForHelpFooter } from "@components/layout/Footer/CallForHelpFooter.tsx";
import { CreditAuthorizationFooter } from "@components/layout/Footer/CreditAuthorizationFooter.tsx";
import { DebtReviewFooter } from "@components/layout/Footer/DebtReviewFooter.tsx";
import { SelectedPlanFooter } from "@components/layout/Footer/SelectedPlanFooter.tsx";
import { SelectPlanFooter } from "@components/layout/Footer/SelectPlanFooter.tsx";
import { useStyles } from "@components/layout/Form/form.styles.ts";
import { useStore } from "@stores";
import { theme } from "@styles";
import { FORM_TYPES } from "@types";

import { BudgetToolFooter } from "../Footer/BudgetToolFooter";

interface ModalProps {
  name: string;
  children: ReactNode;
  modalLoading?: boolean;
  methods: UseFormReturn<FORM_TYPES>;
  onSubmit: SubmitHandler<FORM_TYPES>;
  nextUserCtaAfterCreditPull: () => void;
}

export const ModalForm = observer(
  ({ children, name, modalLoading, methods, onSubmit, nextUserCtaAfterCreditPull }: ModalProps) => {
    const classes = useStyles({ name });
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const { remoteConfigStore } = useStore();

    let footer: JSX.Element = <></>;

    switch (name) {
      case "CreditModule":
        footer = <CreditAuthorizationFooter name={name} />;
        break;
      case "BudgetTool":
        if (isDesktop) {
          footer = <BudgetToolFooter />;
        }
        break;
      case "DebtReview":
        footer = <DebtReviewFooter name={name} />;
        break;
      case "SelectPlan":
        footer = <SelectPlanFooter methods={methods} onSubmit={onSubmit} name={name} />;
        break;
      case "PlanSelected":
        footer = <SelectedPlanFooter name={name} />;
        break;
      case "CallForHelp":
        footer = <CallForHelpFooter name={name} showDoneToggle />;
        break;
    }

    return (
      methods && (
        <FormProvider {...methods}>
          <div className={classes.container} id="form-container" data-cy="Form.container">
            {name === "CreditModule" ? (
              <AnimatedColorBar
                nextUserCtaAfterCreditPull={nextUserCtaAfterCreditPull}
                initialAnimationRunTime={remoteConfigStore.creditPullAnimationRunTime || "15"}
              >
                <>
                  <div className={classes.content}>{children}</div>
                  {footer}
                </>
              </AnimatedColorBar>
            ) : (
              <Suspense fallback={<Loader data-cy="modal-loader-spinner" />}>
                {modalLoading ? (
                  <Loader data-cy="modal-loader-spinner" />
                ) : (
                  <>
                    <div className={classes.content} data-cy="Form.content">
                      {children}
                    </div>
                    {footer}
                  </>
                )}
              </Suspense>
            )}
          </div>
        </FormProvider>
      )
    );
  },
);
