import { createContext, useReducer } from "react";

type ModeType = {
  overlay: boolean;
  showModal: boolean;
};

const initialState = {
  overlay: false,
  showModal: false,
};
export const ThemeContext = createContext<{
  state: ModeType;
  dispatch: React.Dispatch<{ type: string }>;
}>({
  state: initialState,
  dispatch: () => null,
});

const themeReducer = (state: ModeType, action: { type: string }) => {
  switch (action.type) {
    case "REMOVE_OVERLAY":
      return { overlay: false, showModal: false };
    case "SET_OVERLAY":
      return { overlay: true, showModal: true };
    default:
      return state;
  }
};

type Props = {
  children: string | JSX.Element | JSX.Element[];
};
export default function ThemeProvider({ children }: Props) {
  const [state, dispatch] = useReducer(themeReducer, initialState);

  return <ThemeContext.Provider value={{ state, dispatch }}>{children}</ThemeContext.Provider>;
}
