import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.brandGray.other,
    paddingTop: "24px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      padding: 0,
      flexDirection: "row-reverse",
    },
  },
  absoluteTimerModalWrapper: {
    position: "relative",
    zIndex: "9999",
  },

  header: {
    position: "relative",
    zIndex: "2",
    width: "100%",
  },

  footer: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "0",
      width: "100%",
    },
  },

  content: {
    padding: "24px",
    flex: 1,
    [theme.breakpoints.up("md")]: {
      padding: 0,
      margin: 0,
      display: "flex",
      flexDirection: "column",
    },
  },
  contenthelpA: {
    padding: "114px 24px 24px 24px",
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.up("md")]: {
      marginTop: "unset",
      padding: "24px",
    },
  },
  innerContent: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      // padding: '1rem 1rem 2rem 4rem',
      overflowX: "hidden",
    },
  },
  logOutButtonWrapper: {
    marginLeft: "auto",
    padding: "20px 2rem 20px 1rem",
  },
  logOutButton: {
    color: "#5d6974",
    "&:hover": {
      textDecoration: "unset !important",
    },
  },
  arrowRotate: {
    rotate: "180deg",
    border: "none",
    margin: "0",
    overflow: "hidden",
    "&.notActive": {
      transition: `rotate 0.1s ease-in-out`,
      rotate: "0deg",
    },
    "&.active": {
      transition: `rotate 0.1s ease-in-out`,
      rotate: "180deg",
    },
  },
}));
