import { Card } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Loader, Slider } from "@components/common";
import PassCodeEntry from "@components/views/Authentication/PassCodeEntry";
import { useStyles } from "@components/views/Modals/AuthenticationModal/AuthenticationModal.styles";
import { useStore } from "@stores";
import { theme } from "@styles";
import { StoredProfile } from "@types";

interface AuthenticationModalProps {
  resendSMS: () => Promise<string | undefined>;
  smsVerificationId?: string;
  closeSlider: () => void;
  storedProfile?: StoredProfile;
}

export const AuthenticationModal: FC<AuthenticationModalProps> = observer(
  ({ resendSMS, smsVerificationId, closeSlider, storedProfile }: AuthenticationModalProps) => {
    const classes = useStyles();
    const slideUp = useMediaQuery(theme.breakpoints.up("md"));
    const { sessionStore } = useStore();

    const isLoading = sessionStore.loader.isLoading.get();

    return (
      <div>
        <Slider direction={slideUp ? "left" : "up"} show={true} transitionTime={500}>
          <Card className={classes.modalCard} elevation={4}>
            {isLoading && <Loader size={"4rem"} />}
            {!isLoading && smsVerificationId && (
              <PassCodeEntry
                verificationId={smsVerificationId}
                resend={resendSMS}
                closeSlider={closeSlider}
                storedProfile={storedProfile}
              />
            )}
          </Card>
        </Slider>
      </div>
    );
  },
);

export default AuthenticationModal;
