import { Box } from "@mui/material";

import { TermsAndPolicy } from "@components/common/TermsAndPolicy";

import { useStyles } from "./FooterLegalInfo.styles";
import { Copyright } from "../Copyright";

export const FooterLegalInfo: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Copyright />
      <Box className={classes.linkContainer}>
        <TermsAndPolicy />
      </Box>
    </Box>
  );
};
