import { FirebaseError } from "@firebase/util";
import axios, { AxiosError, AxiosResponse } from "axios";

import { store } from "@stores";
import {
  AxiosErrorData,
  Engagement as EngagementInterface,
  Profile as ProfileInterface,
  ProfileExists,
  ProfileResponse,
  Servicer,
  UpdateProfile,
  BudgetTypeEnum,
  IBudgetItemDataDto,
  InvokeSweeperResponse,
  DebtSettlementPlanDetails,
  DebtManagementCalculatorResponse,
  IBudgetDMPItemDataDto,
  ServicerOpenStatus,
  DocumentPayload,
} from "@types";
import { JourneyCTAEnum } from "@utils";

import i18n from "../../i18n.config.ts";
import { rollbar } from "../../rollbarConfig.ts";
import { CreditResponse } from "../types";
import { AddressResponse } from "../types/addressLookup.ts";

axios.interceptors.request.use(async function (config) {
  if (
    config.url &&
    (config.url.includes("util") ||
      config.url.includes("remote-config") ||
      config.url.includes("servicer") ||
      config.url.includes("chat") ||
      config.url.includes("address-lookup"))
  ) {
    config.baseURL = `${import.meta.env.VITE_SAAS_API_URL}`;
  } else {
    config.baseURL = `${import.meta.env.VITE_SAAS_API_URL}/${i18n.language}`;
  }

  const idToken = await store.sessionStore.authUser?.getIdToken();

  config.headers.Authorization = `Bearer ${idToken}`;
  config.headers.client = "default";
  config.headers["Content-Type"] = "application/json";
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error: AxiosError<AxiosErrorData>) => {
    const data = error?.response?.data;
    const errorCode = error?.response?.status ?? data?.statusCode;
    const payload = {
      url: `${error?.response?.config.baseURL}${error?.response?.config.url}`,
      code: error?.code,
      data,
    };

    const log = (level: "error" | "warning") => {
      if (error instanceof FirebaseError) {
        rollbar[level](`(From Axios Interceptor): ${error.message}`, error);
      } else {
        rollbar[level](`(From Axios Interceptor): ${errorCode}`, error, {
          payload,
        });
      }
    };

    if (errorCode) {
      switch (errorCode) {
        case 500:
          if (data) {
            store.commonStore.setServerError(data);
            if (data.message.includes("NoFileReturnedBadSsn")) {
              log("warning");
            } else {
              log("error");
            }
          }
          break;
        case 401:
        case 404:
          if (data && /^Profile with id .* not found\.$/.test(data.message)) {
            log("warning");
          } else {
            log("error");
          }
          break;
        default:
          log("warning");
      }
    } else {
      log("warning");
    }
    return Promise.reject(error);
  },
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body?: NonNullable<unknown>) => axios.post<T>(url, body).then(responseBody),
  patch: <T>(url: string, body: NonNullable<unknown>) => axios.patch<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
  put: <T>(url: string, body: NonNullable<unknown>) => axios.put<T>(url, body).then(responseBody),
};

/**
 *
 * TODO: these are just pseudo-coded,
 * sync up with luke to determine functionality and intent
 *
 */
const Engagement = {
  getById: (id: string) => requests.get<EngagementInterface>(`/engagement/${id}`),
  updateStatus: (id: string, status: string) => requests.post<EngagementInterface>(`/engagement/${id}`, { status }),
};

const Profile = {
  doesExist: (id: string) => requests.get<ProfileExists>(`/profile/${id}/exists`),
  getById: (id: string) => requests.get<ProfileResponse>(`/profile/${id}`),
  create: (profile: ProfileInterface) => requests.post<ProfileResponse>(`/profile`, profile),
  findOrCreate: (profile: ProfileInterface) => requests.put<ProfileResponse>(`/profile`, profile),
  update: (id: string, profile: UpdateProfile) => requests.patch<ProfileResponse>(`/profile/${id}`, profile),
  invokeSweeper: (id: string) => requests.post<InvokeSweeperResponse>(`/profile/${id}/sweeper`),
};

const Budget = {
  create: ({
    profileId,
    budgetType,
    budgetItem,
  }: {
    profileId: string;
    budgetType: BudgetTypeEnum;
    budgetItem: IBudgetItemDataDto;
  }) => requests.post<ProfileResponse>(`/profile/${profileId}/budget/${budgetType}`, budgetItem),
  update: ({
    profileId,
    budgetType,
    itemId,
    budgetItem,
  }: {
    profileId: string;
    budgetType: BudgetTypeEnum;
    itemId: string;
    budgetItem: IBudgetItemDataDto;
  }) => requests.put<ProfileResponse>(`/profile/${profileId}/budget/${budgetType}/${itemId}`, budgetItem),
  delete: ({ profileId, budgetType, itemId }: { profileId: string; budgetType: BudgetTypeEnum; itemId: string }) =>
    requests.del(`/profile/${profileId}/budget/${budgetType}/${itemId}`),
};

const BudgetDMP = {
  create: ({ profileId, budgetItem }: { profileId: string; budgetItem: IBudgetDMPItemDataDto }) =>
    requests.post<ProfileResponse>(`/profile/${profileId}/budget`, budgetItem),
  update: ({
    profileId,
    itemId,
    budgetItem,
  }: {
    profileId: string;
    itemId: string;
    budgetItem: IBudgetDMPItemDataDto;
  }) => requests.put<ProfileResponse>(`/profile/${profileId}/budget/${itemId}`, budgetItem),
  delete: ({ profileId, itemId }: { profileId: string; itemId: string }) =>
    requests.del(`/profile/${profileId}/budget/${itemId}`),
};
const Configs = {
  get: () => requests.get<unknown[]>(`/remote-config`),
  getById: (id: string) => requests.get<unknown>(`/remote-config/${id}`),
};

const Utils = {
  getServicerByState: (state: string) => requests.get<Servicer>(`/servicer/state/${state}`),
  getServicerById: (id: string) => requests.get<Servicer>(`/servicer/${id}`),
  getIsServicerOpen: (id: string) => requests.get<ServicerOpenStatus>(`/servicer/${id}/isOpen`),
};

const Chat = {
  getStatus: () => requests.get<{ enabled: boolean }>(`/chat/status`),
};

const CallToActions = {
  authorizeCreditPull: (id: string) => requests.post<CreditResponse>(`/profile/${id}/credit-pull`, {}),

  saveSelectedPlan: (
    profileId: string,
    journeyId: JourneyCTAEnum,
    { selectedPlan, submittedBy }: { selectedPlan: DebtSettlementPlanDetails; submittedBy: string },
  ) =>
    requests.patch<CreditResponse>(`/profile/${profileId}/program/${journeyId}/selected-plan`, {
      selectedPlan: selectedPlan,
      submittedBy: submittedBy,
    }),
  getCreditReport: (profileId: string) => {
    return requests.get<CreditResponse>(`/profile/${profileId}/credit-report`);
  },
  updateJourneyCta: (profileId: string, journeyId: string, ctaId: string) => {
    return requests.patch<ProfileResponse>(
      `/profile/${profileId}/program/${journeyId}/call-to-action/${ctaId}/complete`,
      {},
    );
  },

  getDebtManagementPlan: (profileId: string) => {
    return requests.post<DebtManagementCalculatorResponse>(`/profile/${profileId}/dmp-calculator`);
  },
};

const AdressLookup = {
  getAddress: (search: string, selected?: string) =>
    requests.get<AddressResponse>(
      `/address-lookup?search=${encodeURIComponent(search)}&selected=${encodeURIComponent(selected || "")}`,
    ),
};

const DigitalSignature = {
  createESignDocument: (profileId: string, body: DocumentPayload) => {
    return requests.post(`/profile/${profileId}/document`, body);
  },
  generateESignLink: (profileId: string, type: string) => {
    return requests.post(`/profile/${profileId}/document/${type}/invite`, {});
  },
  updateESignDocument: (profileId: string, type: string, body: { [key: string]: string }) => {
    return requests.put<{ url: string }>(`/profile/${profileId}/document/${type}`, body);
  },
};

export const AxiosAgent = {
  Engagement,
  Profile,
  CallToActions,
  Configs,
  Utils,
  Budget,
  BudgetDMP,
  Chat,
  AdressLookup,
  DigitalSignature,
};
