import { SvgIcon } from "@mui/material";
import { Link } from "react-router-dom";

import adviceIcon from "@assets/icons/advice.svg?react";
import checkBoxIcon from "@assets/icons/checkmarkBlue.svg?react";
import homeIcon from "@assets/icons/home.svg?react";
import phoneIcon from "@assets/icons/phone.svg?react";
import plansIcon from "@assets/icons/plans.svg?react";
import profileIcon from "@assets/icons/profile.svg?react";
import savingsIcon from "@assets/icons/savings.svg?react";
import settingsIcon from "@assets/icons/settings.svg?react";
import toolsIcon from "@assets/icons/tools.svg?react";
import { theme } from "@styles";

import { useStyles } from "./MiniDashItem.styles";
import { ButtonBranded } from "../ButtonBranded";
import { Text } from "../Text";

export type itemIcon = "advice" | "home" | "plans" | "profile" | "savings" | "settings" | "phone" | "tools";

export interface MiniDashItemPropsBase {
  variant: string;
  taskCompleted?: boolean;
  disabled?: boolean;
  needHelp?: boolean;
  header?: string;
  icon?: itemIcon;
  title: string;
  summary: string;
}
interface MiniDashItemWithOnClick extends MiniDashItemPropsBase {
  onClick: () => void;
  route?: never;
}

interface MiniDashItemWithRoute extends MiniDashItemPropsBase {
  onClick?: never;
  route?: string;
}

type MiniDashItemProps = MiniDashItemWithOnClick | MiniDashItemWithRoute;

export const MiniDashItem: React.FC<MiniDashItemProps> = ({
  variant,
  header,
  icon,
  title,
  summary,
  onClick,
  route,
  taskCompleted = false,
  disabled = false,
  needHelp = false,
}) => {
  const classes = useStyles({ variant });

  const iconComponents: {
    [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  } = {
    advice: adviceIcon,
    home: homeIcon,
    plans: plansIcon,
    profile: profileIcon,
    savings: savingsIcon,
    settings: settingsIcon,
    tools: toolsIcon,
  };

  const getButtonActivityProps = () => {
    if (route) {
      return {
        component: Link,
        to: route,
      };
    } else if (onClick) {
      return {
        onClick: onClick,
      };
    }
  };

  const getButtonThemeProps = () => {
    if (variant && variant == "profile") {
      return {
        fontSize: theme.fontSizes.large,
        fontWeight: theme.fontWeights.semiBold,
        lineHeight: theme.fontLineHeights.xxxxlarge,
        style: { color: theme.palette.brandGray.darker },
      };
    } else {
      return {
        fontSize: theme.fontSizes.large,
        fontWeight: theme.fontWeights.semiBold,
        lineHeight: theme.fontLineHeights.medium,
      };
    }
  };

  const getArrowSvgProps = () => {
    if (variant && variant == "profile") {
      return {
        stroke: theme.palette.brandBlue.dark,
        strokeWidth: "2",
      };
    } else {
      return {
        stroke: theme.palette.brandAccent.main,
        strokeWidth: "2",
      };
    }
  };

  return (
    <div className={classes.container}>
      <ButtonBranded
        variant="textonly"
        {...getButtonThemeProps()}
        {...getButtonActivityProps()}
        padding="0"
        disabled={disabled}
      >
        {header && (
          <div className={classes.header}>
            <Text fontSize="xlarge" fontWeight="semiBold" lineHeight="xsmall">
              {header}
            </Text>
          </div>
        )}
        <div className={classes.content}>
          {icon && <SvgIcon component={iconComponents[icon]} className={classes.icon} inheritViewBox />}
          <div className={classes.contentText}>
            {title}
            <Text fontSize="small" fontWeight="regular" lineHeight="xxsmall" color="gray">
              {summary}
            </Text>
          </div>
          <div className={classes.arrow}>
            {taskCompleted ? (
              <SvgIcon component={checkBoxIcon} inheritViewBox />
            ) : needHelp ? (
              <SvgIcon component={phoneIcon} sx={{ color: "transparent" }} inheritViewBox />
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M14.1667 6.66669L17.5 10M17.5 10L14.1667 13.3334M17.5 10H2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  {...getArrowSvgProps()}
                />
              </svg>
            )}
          </div>
        </div>
      </ButtonBranded>
    </div>
  );
};
