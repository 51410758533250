/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

export const useSimpleTimer = (minutes: number, onEnd: () => void) => {
  const [timeLeft, setTimeLeft] = useState(minutes * 60);
  const [isActive, setIsActive] = useState(false);

  const decrementTimeLeft = () => {
    setTimeLeft((prevTime) => prevTime - 1);
  };

  useEffect(() => {
    if (!isActive && timeLeft < 0) return;

    const interval = setInterval(decrementTimeLeft, 1000);
    if (timeLeft <= 0 && isActive) {
      setIsActive(false);
      onEnd();
      clearInterval(interval);
      return;
    }

    return () => {
      clearInterval(interval);
    };
  }, [timeLeft, isActive, decrementTimeLeft, onEnd]);

  const startTimer = () => setIsActive(true);
  const stopTimer = () => setIsActive(false);
  const resetTimer = (newMinutes: number) => {
    setTimeLeft(newMinutes * 60);
    setIsActive(true);
  };

  return { timeLeft, startTimer, stopTimer, resetTimer };
};
