// Import the functions you need from the SDKs you need
import { Buffer } from "buffer";

import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getAuth } from "firebase/auth";

declare global {
  interface Window {
    Cypress?: unknown;
  }
}

type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

const encodedConfig = import.meta.env.VITE_ENCODED_FIREBASE_CONFIG;
if (!encodedConfig) {
  throw new Error("VITE_ENCODED_FIREBASE_CONFIG is not defined.");
}

const decodedJson: string = Buffer.from(encodedConfig, "base64").toString("utf-8");

const firebaseConfig: FirebaseConfig = JSON.parse(decodedJson);

// Initialize Firebase
const app = initializeApp(firebaseConfig);

initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(import.meta.env.VITE_ENTERPRISE_CAPTCHA_KEY),
  isTokenAutoRefreshEnabled: true,
});

export const auth = getAuth(app);
// only set to true if cypress is running e2e tests
if (window.Cypress) {
  auth.settings.appVerificationDisabledForTesting = true;
}
