import { Slide } from "@mui/material";
import { ReactElement } from "react";

interface SliderProps {
  direction: "up" | "left";
  show: boolean;
  transitionTime: number;
  children: ReactElement;
}
export const Slider = ({ direction, show, transitionTime, children }: SliderProps) => {
  return (
    <Slide
      direction={direction}
      in={show}
      timeout={transitionTime}
      easing={{
        enter: "ease",
        exit: "ease",
      }}
    >
      {children}
    </Slide>
  );
};
