import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { memo, useEffect, useRef } from "react";

import { useStyles } from "../TermsAndConditions.styles";

export const TermsEsVersion = memo(
  ({ hideModal, scrollToPrivacyPolicyRef }: { hideModal: () => void; scrollToPrivacyPolicyRef?: boolean }) => {
    const privatePolicyTargetRef = useRef<HTMLDivElement>(null);

    const classes = useStyles();

    useEffect(() => {
      if (scrollToPrivacyPolicyRef) {
        setTimeout(() => {
          privatePolicyTargetRef.current?.scrollIntoView({
            behavior: "instant",
            block: "start",
          });
        }, 100);
      }
    }, [scrollToPrivacyPolicyRef]);

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <IconButton aria-label="terms-of-use" onClick={() => hideModal()}>
            <Close />
          </IconButton>
        </div>
        <div className={classes.content}>
          <h2 style={{ margin: 0 }}>Términos de uso</h2>
          <p>
            <strong>Fecha de la última actualización: 19 de Abril de 2024.</strong>
          </p>
          <h2>ACUERDO ENTRE EL USUARIO Y DEBT.COM</h2>
          <p>
            Estos Términos y Condiciones de uso (“Acuerdo”) son un acuerdo legal entre usted y Debt.com, LLC (en
            adelante, el “Propietario del Sitio Web”), el propietario y desarrollador de Debt.com (el “Sitio”). Al usar,
            registrarse o interactuar de algún otro modo con el Sitio para cualquier servicio proporcionado a través de
            Debt.com, usted acepta quedar sujeto a todos los términos y condiciones (los “Términos”) establecidos en
            este Acuerdo. Usted comprende que no puede modificar estos términos y que debe aceptar todos los términos
            “TAL COMO ESTÁN”. Si decide no aceptar todos los términos, simplemente no se registre, utilice ni interactúe
            con este Sitio.
          </p>
          <p>
            Nuestra <strong>Política de Privacidad</strong>, incorporada por la presente como referencia en este
            Acuerdo, explica cómo recopilamos, protegemos, compartimos y utilizamos su información como parte de nuestra
            plataforma, y todos nuestros productos y servicios.
          </p>
          <p>
            USTED ACEPTA QUE HA LEÍDO, ENTENDIDO Y ACEPTA ESTAR OBLIGADO POR ESTOS TÉRMINOS. Este Acuerdo contiene un
            acuerdo de arbitraje vinculante, que establece que usted y nosotros aceptamos resolver todas las disputas
            mediante arbitraje individual vinculante, y que usted y nosotros renunciamos a todo derecho a que un juez o
            un jurado decida esas disputas. Tiene derecho a optar por no participar en nuestro acuerdo de arbitraje.
            Consulte la sección Disputas Legales de este Acuerdo.
          </p>
          <p>
            Debt.com es un sitio web de educación financiera y una agencia de referencias que proporciona materiales
            educativos sobre servicios financieros de alivio de deuda y conecta a los consumidores con proveedores de
            servicios financieros para ayudarlos a vivir una vida financiera más saludable. Cuando llama a nuestro
            número, es posible que se lo ponga en contacto directamente con uno de nuestros socios, o con un tercero
            para que le ayude. Los proveedores de servicios independientes podrían cobrar por sus servicios y tener sus
            propios términos de servicio. Debt.com no se hace responsable por esos proveedores ni garantiza ningún
            resultado de dichos proveedores. Es posible que los servicios no estén disponibles en todos los estados, así
            que llame o consulte nuestro sitio web para obtener más detalles.
          </p>
          <h3>MODIFICACIÓN DE ESTOS TÉRMINOS DE USO</h3>
          <p>
            DEBT.COM SE RESERVA EL DERECHO DE REALIZAR CAMBIOS EN LOS TÉRMINOS A DISCRECIÓN DEL PROPIETARIO DEL SITIO
            WEB CON O SIN PREVIO AVISO. EL QUE USTED CONTINÚE USANDO CUALQUIER PARTE DE ESTE SITIO WEB O CUALQUIER
            SERVICIO CONSTITUYE SU ACEPTACIÓN DE TALES CAMBIOS A ESTE ACUERDO. USTED DEBE REVISAR ESTE ACUERDO
            PERIÓDICAMENTE PARA DETERMINAR SI SE HA REALIZADO ALGÚN CAMBIO. LA VERSIÓN MÁS ACTUAL DE ESTOS TÉRMINOS, QUE
            SE INDICARÁ POR LA “FECHA DE LA ÚLTIMA ACTUALIZACIÓN” DEL PRESENTE, REEMPLAZA A TODAS LAS VERSIONES
            ANTERIORES.
          </p>
          <h3>ENLACES A SITIOS DE TERCEROS</h3>
          <p>
            Este Sitio puede contener enlaces a sitios web externos (“Sitios Vinculados”). Los Sitios Vinculados no
            están bajo el control ni la administración de Debt.com, y Debt.com no es responsable del contenido de
            ninguno de los Sitios Vinculados, incluidos, entre otros, los vínculos contenidos en un Sitio Vinculado o
            cualquier cambio o actualización de un Sitio Vinculado. Debt.com no es responsable de las transmisiones web
            ni de cualquier otra forma de transmisión recibida desde cualquier Sitio Vinculado. Debt.com le proporciona
            estos vínculos solo para su comodidad, y la inclusión de cualquier vínculo no implica respaldo del sitio por
            parte de Debt.com, ni asociación con sus operadores.
          </p>
          <h3>EXCLUSIÓN DEL USO ILEGAL O PROHIBIDO</h3>
          <p>
            Como condición para su uso del Sitio, usted garantiza a Debt.com que no utilizará el Sitio para ningún
            propósito que sea ilegal o esté prohibido por la ley, por algún reglamento o por los presentes términos. No
            puede utilizar el Sitio de ninguna manera que pueda dañar, deshabilitar, sobrecargar o perjudicar el Sitio o
            interferir con el uso y goce del Sitio por parte de terceros. No puede obtener ni intentar obtener ningún
            material o información del Sitio a través de ningún medio que no esté disponible se proporcione
            intencionalmente mediante Debt.com en este Sitio.
          </p>
          <h3>USTED ES EL RESPONSABLE DE SUS DECISIONES FINANCIERAS</h3>
          <p>
            Debt.com, a través de nuestro Sitio y sitios relacionados, puede proporcionarle un lugar mediante el cual
            pueda obtener información financiera educativa, así como conocer diversos proveedores de servicios externos,
            como instituciones financieras, proveedores de tarjetas de crédito, compañías de liquidación de deudas,
            prestamistas y otros profesionales de las finanzas (“Proveedores de Servicios”). Usted comprende y reconoce
            que Debt.com puede ser compensado por los Proveedores de Servicios por las referencias realizadas por medio
            de este Sitio. Debt.com y el Propietario del Sitio Web no garantizan la exactitud ni la integridad de la
            información proporcionada en el Sitio o con respecto a los Proveedores de Servicios, y no son responsables
            de ninguna pérdida que resulte de su confianza en dicha información. Usted comprende que debe examinar la
            información proporcionada y realizar su propia investigación para determinar si los servicios
            comercializados en este Sitio se ajustan a su situación financiera. No respaldamos ni recomendamos los
            productos o servicios de ningún Proveedor de Servicios, y no somos un agente ni asesor para usted ni para
            ningún Proveedor de Servicios. No validamos ni investigamos las licencias, certificaciones u otros
            requisitos y calificaciones de los Proveedores de Servicios. Es su responsabilidad investigar a los
            Proveedores de Servicios. Usted reconoce y acepta que los Proveedores de Servicios son los únicos
            responsables de cualquier servicio que puedan brindarle. Debt.com no es responsable de ninguna pérdida,
            costo, daño o reclamo que se relacione con su uso de los productos o servicios de cualquier Proveedor de
            Servicios, o que surja de ellos. Antes de tomar cualquier decisión financiera, le instamos a obtener el
            asesoramiento de profesionales calificados (como asesores fiscales, contadores, abogados, etc.) que conozcan
            bien todas sus circunstancias individuales. Usted reconoce y acepta que confía en su propio criterio y el de
            dichos asesores al seleccionar cualquier producto o servicio ofrecido por los Proveedores de Servicios.
          </p>
          <h3>SIN GARANTÍA DE COTIZACIONES, COMISIONES, TÉRMINOS, TARIFAS, COBERTURA O SERVICIOS</h3>
          <p>
            No ofrecemos ninguna garantía ni declaración con respecto a las cotizaciones, comisiones, términos, tarifas,
            cobertura o servicios ofrecidos o puestos a disposición por los Proveedores de Servicios a través de nuestro
            Sitio. No garantizamos que las cotizaciones, comisiones, términos, tarifas, cobertura o servicios ofrecidos
            por los Proveedores de Servicios sean los mejores disponibles en el mercado. Es posible que pueda encontrar
            ofertas más adecuadas en otros lugares.
          </p>
          <h3>USO DE SERVICIOS DE COMUNICACIÓN</h3>
          <p>
            El Sitio puede contener servicios de tablón de anuncios, áreas de chat, grupos de noticias, foros,
            comunidades, páginas web personales, calendarios u otros servicios de mensajería o comunicación diseñados
            para permitirle comunicarse con el público en general o con un grupo (colectivamente, “Servicios de
            Comunicación”), y usted acepta utilizar los Servicios de Comunicación solo para publicar, enviar y recibir
            mensajes y material que sean apropiados y estén relacionados con el Servicio de Comunicación en particular.
            A modo de ejemplo, y no como limitación, usted acepta que, al utilizar un Servicio de Comunicación, no hará
            nada de lo siguiente:
          </p>
          <ul>
            <li>
              Difamar, abusar, acosar, acechar o amenazar a otras personas, o violar de algún otro modo los derechos
              legales (como los derechos de privacidad y publicidad) de otras personas.
            </li>
            <li>
              Publicar, cargar, distribuir o difundir cualquier tema, nombre, material o información inapropiados,
              soeces, difamatorios, ilícitos, obscenos, indecentes o ilegales.
            </li>
            <li>
              Cargar archivos que contengan software u otro material protegido por leyes de propiedad intelectual (o por
              derechos de privacidad o publicidad), a menos que sea propietario o controle los derechos de dichos
              materiales o haya recibido todos los consentimientos necesarios.
            </li>
            <li>
              Cargar archivos que contengan virus o estén corruptos, o cualquier otro software o programa similares que
              puedan perjudicar el funcionamiento de la computadora de otra persona.
            </li>
            <li>
              Publicitar u ofrecer vender o comprar bienes o servicios para cualquier fin comercial, a menos que dicho
              Servicio de Comunicación permita específicamente dichos mensajes.
            </li>
            <li>Realizar o reenviar encuestas, concursos, esquemas piramidales o cartas en cadena.</li>
            <li>
              Descargar cualquier archivo publicado por otro usuario de un Servicio de Comunicación que usted sepa, o
              debería saber dentro de lo razonable, que no puede distribuirse legalmente de esa manera.
            </li>
            <li>
              Falsificar o eliminar cualquier atribución de autor, avisos legales u otros avisos adecuados o
              designaciones o etiquetas de propiedad exclusiva sobre el origen o la fuente del software u otro material
              contenido en un archivo que se cargue.
            </li>
            <li>Restringir o inhibir a cualquier otro usuario el uso y goce de los Servicios de Comunicación.</li>
            <li>
              Violar cualquier código de conducta u otras pautas que puedan ser aplicables a cualquier Servicio de
              Comunicación en particular.
            </li>
            <li>
              Recolectar o recopilar de algún otro modo información sobre otras personas, incluidas direcciones de
              correo electrónico, sin su consentimiento.
            </li>
            <li>Violar cualquier ley o reglamento aplicable.</li>
          </ul>
          <p>
            Debt.com no tiene obligación de supervisar los Servicios de Comunicación. Sin embargo, Debt.com se reserva
            el derecho de revisar los materiales publicados en los Servicios de Comunicación y eliminarlos a su
            exclusivo criterio. Debt.com se reserva el derecho de cancelar su acceso a cualquiera de los Servicios de
            Comunicación en cualquier momento sin previo aviso y por cualquier motivo. Debt.com se reserva el derecho en
            todo momento de divulgar cualquier información según sea necesario para cumplir con cualquier ley,
            reglamento, proceso legal o solicitud gubernamental aplicable, o negarse a publicar o eliminar cualquier
            información o material, en su totalidad o en parte, a criterio exclusivo de Debt.com.
          </p>
          <p>
            Tenga siempre cuidado al proporcionar información de identificación personal (“PII”) sobre usted, su cónyuge
            o sus hijos en cualquier Servicio de Comunicación. Debt.com no controla ni respalda el contenido, los
            mensajes o la información que se encuentran en ningún Servicio de Comunicación y, por lo tanto, Debt.com
            renuncia específicamente a cualquier responsabilidad con respecto a los Servicios de Comunicación y
            cualquier acción resultante de su participación en cualquier Servicio de Comunicación. Los gerentes y hosts
            no son portavoces autorizados de Debt.com, y sus puntos de vista no reflejan necesariamente los de Debt.com.
            Los materiales cargados en un Servicio de Comunicación pueden estar sujetos a limitaciones publicadas sobre
            su uso, reproducción o difusión. Usted es responsable de cumplir con dichas limitaciones si descarga los
            materiales.
          </p>
          <h3>MATERIALES SUMINISTRADOS A DEBT.COM O PUBLICADOS EN CUALQUIER SITIO WEB DE DEBT.COM</h3>
          <p>
            Debt.com no reclama la propiedad de los materiales que usted proporciona a Debt.com (incluidos los
            comentarios y las sugerencias) o que publica, carga, ingresa o envía a cualquier Sitio de Debt.com o sus
            servicios asociados (colectivamente, “Envíos”). Sin embargo, al publicar, cargar, ingresar, proporcionar o
            enviar su Envío, usted le otorga a Debt.com, sus empresas relacionadas y los sublicenciatarios necesarios
            permiso para usar su Envío en relación con la explotación de sus negocios en Internet, incluidos, entre
            otros, los derechos a: copiar, distribuir, transmitir, exhibir públicamente, representar públicamente,
            reproducir, modificar, traducir y reformatear su Envío; y publicar su nombre en relación con su Envío.
          </p>
          <p>
            No se pagará ninguna compensación con respecto al uso de su Envío, según lo dispuesto en este documento.
            Debt.com no tiene ninguna obligación de publicar o utilizar ningún Envío que usted pueda proporcionar, y
            puede eliminar cualquier Envío en cualquier momento a su exclusivo criterio. <br />
            Al publicar, cargar, ingresar, proporcionar o enviar su Envío, usted garantiza y declara que posee o
            controla de algún otro modo todos los derechos sobre su Envío tal como se describe en esta sección,
            incluidos, entre otros, todos los derechos necesarios para que usted proporcione, publique, cargue, ingrese
            o envíe los Envíos.
          </p>
          <h3>CONSENTIMIENTO PARA EL USO DE COMUNICACIONES ELECTRÓNICAS</h3>
          <p>
            <strong>
              Todas las interacciones con el sitio web y las llamadas telefónicas se registran y graban con fines de
              marketing, cumplimiento normativo y control de la calidad.
            </strong>
          </p>
          <div>
            <ol>
              <li>Dispositivos móviles</li>
            </ol>
            <p>
              Al proporcionarnos su nombre, dirección de correo electrónico o número de teléfono, usted nos autoriza
              expresamente a comunicarnos con usted (incluso mediante el uso de marcadores automáticos, mensajes de
              texto automatizados y mensajes pregrabados) por medio de su teléfono fijo, teléfono celular, dispositivo
              móvil (incluso a través del servicio de mensajes cortos [“SMS”] o internet inalámbrico [“Servicio WAP”]) y
              correo electrónico, aunque su número de teléfono figure actualmente en alguna lista de no llamar estatal,
              federal o de la empresa. Se aplicarán cargos estándar de uso de teléfono y datos. Su aceptación de los
              términos anteriores no es necesaria como condición para comprar o recibir nuestros servicios. También
              acepta la grabación y el seguimiento de todas las llamadas hechas por usted a nosotros y hechas por
              nosotros a usted. Usted declara que es el propietario o usuario autorizado del dispositivo móvil y el
              número de teléfono asociado con ese dispositivo móvil en el que se recibirán mensajes, y que está
              autorizado a aprobar cualquier cargo aplicable. Puede optar por no recibir llamadas o mensajes en su
              teléfono o dispositivo móvil en cualquier momento; para ello, deberá enviarnos una notificación a{" "}
              <a href="mailto:admin@debt.com">
                <strong>admin@Debt.com</strong>
              </a>{" "}
              . Además, puede optar por no recibir correos electrónicos, para lo cual debe hacer clic en el enlace
              “Opt-out” (Exclusión voluntaria) de la parte inferior del mensaje de correo electrónico. <br />
              Solo los residentes de Estados Unidos pueden utilizar el servicio SMS o WAP.
            </p>
            <ol>
              <li>Seguridad de los datos</li>
            </ol>
            <p>
              Los Servicios SMS o WAP se proporcionan mediante sistemas inalámbricos que utilizan frecuencias de radio
              (y otros medios) para transmitir comunicaciones por redes complejas. Debt.com no garantiza que su uso de
              los Servicios SMS o WAP sea privado o seguro, y Debt.com no será responsable ante usted por cualquier
              falta de privacidad o seguridad que pueda experimentar. Usted es plenamente responsable de tomar
              precauciones y proporcionar medidas de seguridad adecuadas a su situación y al uso previsto del Servicio
              SMS o WAP. Usted reconoce y acepta que Debt.com puede acceder al contenido de su cuenta y a la cuenta
              inalámbrica que tiene con su proveedor de servicios de telefonía con el fin de identificar y resolver
              problemas técnicos y quejas relacionadas con el servicio. Lea nuestra Política de Privacidad para obtener
              información adicional sobre la privacidad y nuestros servicios de SMS o WAP.
            </p>
          </div>
          <h3>LIMITACIÓN DE RESPONSABILIDAD Y RENUNCIA DE GARANTÍA</h3>
          <p>
            LA INFORMACIÓN, EL SOFTWARE, LOS PRODUCTOS Y LOS SERVICIOS INCLUIDOS O DISPONIBLES POR MEDIO DEL SITIO
            PUEDEN INCLUIR IMPRECISIONES, INFORMACIÓN ANTIGUA O ERRORES TIPOGRÁFICOS. DEBT.COM REALIZA CAMBIOS
            PERIÓDICOS A LA INFORMACIÓN Y LOS MATERIALES PROPORCIONADOS EN EL SITIO. DEBT.COM O EL ADMINISTRADOR DE SU
            SITIO PUEDEN REALIZAR MEJORAS O CAMBIOS EN EL SITIO EN CUALQUIER MOMENTO.
          </p>
          <p>
            No se debe confiar necesariamente en las opiniones, consejos generales, declaraciones u otros comentarios
            del sitio y no se los debe interpretar como asesoramiento profesional de Debt.com o del Propietario del
            Sitio Web. No debe confiarse en la información y los consejos generales RECIBIDOS POR MEDIO DEL SITIO PARA
            DECISIONES PERSONALES, MÉDICAS, LEGALES O FINANCIERAS, Y USTED DEBE CONSULTAR A UN PROFESIONAL ADECUADO PARA
            OBTENER ASESORAMIENTO ESPECÍFICO ADAPTADO A SU SITUACIÓN.
          </p>
          <p>
            DEBT.COM Y EL ADMINISTRADOR DE SU SITIO NO HACEN DECLARACIONES SOBRE LA IDONEIDAD, CONFIABILIDAD,
            DISPONIBILIDAD, PUNTUALIDAD Y EXACTITUD DE LA INFORMACIÓN, SOFTWARE, PRODUCTOS, SERVICIOS Y GRÁFICOS
            RELACIONADOS CONTENIDOS O DISPONIBLES MEDIANTE EL SITIO PARA CUALQUIER PROPÓSITO. EN LA MEDIDA MÁXIMA
            PERMITIDA POR LA LEY APLICABLE, TODA LA INFORMACIÓN, SOFTWARE, PRODUCTOS, SERVICIOS Y GRÁFICOS RELACIONADOS
            SE PROPORCIONAN “TAL COMO ESTÁN” SIN GARANTÍA NI CONDICIÓN DE NINGÚN TIPO. DEBT.COM Y EL PROPIETARIO DEL
            SITIO WEB RECHAZAN POR EL PRESENTE TODAS LAS GARANTÍAS Y CONDICIONES CON RESPECTO A ESTA INFORMACIÓN,
            SOFTWARE, PRODUCTOS, SERVICIOS Y GRÁFICOS RELACIONADOS, INCLUIDAS TODAS LAS GARANTÍAS O CONDICIONES
            IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO PARTICULAR, TITULARIDAD Y NO INFRACCIÓN.
          </p>
          <p>
            EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY APLICABLE, EN NINGÚN CASO DEBT.COM O EL PROPIETARIO DE SU SITIO WEB
            SERÁN RESPONSABLES DE NINGÚN DAÑO DIRECTO, INDIRECTO, PUNITIVO, INCIDENTAL, ESPECIAL, CONSECUENTE O
            CUALQUIER DAÑO O PERJUICIO, INCLUIDOS, A MODO DE EJEMPLO, DAÑOS POR PÉRDIDA DE USO, DATOS O GANANCIAS, QUE
            SURJAN DE O SE RELACIONEN DE ALGUNA MANERA CON EL USO O RENDIMIENTO DEL SITIO DEBT.COM, CON RETRASOS O
            IMPOSIBILIDAD DE UTILIZAR EL SITIO DEBT.COM O LOS SERVICIOS RELACIONADOS U OFRECIDOS, LA PRESTACIÓN O FALTA
            DE PRESTACIÓN DE SERVICIOS, O DE CUALQUIER INFORMACIÓN, SOFTWARE, PRODUCTOS, SERVICIOS Y GRÁFICOS
            RELACIONADOS OBTENIDOS MEDIANTE EL SITIO DEBT.COM, O QUE SURJAN DE ALGUNA OTRA MANERA DEL USO DEL SITIO
            DEBT.COM, YA SEA POR CONTRATO, RESPONSABILIDAD EXTRACONTRACTUAL, NEGLIGENCIA, RESPONSABILIDAD ESTRICTA O DE
            OTRA MANERA, INCLUSO SI DEBT.COM O CUALQUIERA DE SUS PROVEEDORES HAN SIDO ADVERTIDOS DE LA POSIBILIDAD DE
            DAÑOS. DEBIDO A QUE ALGUNOS ESTADOS O JURISDICCIONES NO PERMITEN LA EXCLUSIÓN O LIMITACIÓN DE
            RESPONSABILIDAD POR DAÑOS CONSECUENCIALES O INCIDENTALES, LA LIMITACIÓN ANTERIOR PODRÍA NO SER PERTINENTE A
            USTED. SI NO ESTÁ SATISFECHO CON ALGUNA PARTE DEL SITIO DEBT.COM, O CON ALGUNO DE ESTOS TÉRMINOS DE USO, SU
            ÚNICO Y EXCLUSIVO RECURSO ES DEJAR DE UTILIZAR EL SITIO DEBT.COM.
          </p>
          <h3>CANCELACIÓN Y RESTRICCIÓN DEL ACCESO</h3>
          <p>
            Debt.com puede establecer áreas en el Sitio que requieran la obtención de credenciales de acceso. Si obtiene
            dicho acceso a áreas restringidas del Sitio, Debt.com se reserva el derecho, a su exclusivo criterio, de
            cancelar su acceso al Sitio de Debt.com y los servicios relacionados o cualquier parte de ellos en cualquier
            momento, sin previo aviso.
          </p>
          <h3>SITIO NO APTO PARA MENORES DE 13 AÑOS</h3>
          <p>
            El Sitio no está dirigido a personas menores de trece (13) años, y solicitamos que estas personas no
            proporcionen Información de identificación personal (PII) por medio del Sitio. No recopilamos
            intencionalmente información de niños menores de 13 años sin el consentimiento de los padres. Para obtener
            más información sobre esta política, visite nuestra Política de Privacidad.
          </p>
          <h3>AVISOS DE DERECHOS DE AUTOR Y MARCAS COMERCIALES:</h3>
          <p>
            Debt.com y el logotipo de Debt.com son marcas comerciales registradas, nombres comerciales o marcas de
            servicio de Debt.com, LLC o sus empresas relacionadas. Todas las demás marcas comerciales y marcas de
            servicio presentadas en el Sitio son propiedad de sus respectivos dueños, y Debt.com ha obtenido permiso
            para usarlas en asociación con la información educativa y los servicios de referencia proporcionados. No se
            le permite a usted utilizar ninguna marca comercial o de servicio mostrada en este Sitio sin el
            consentimiento previo por escrito de Debt.com o el propietario de dicha marca comercial o de servicio.
          </p>
          <p>
            Usted reconoce y comprende que no tiene derechos sobre ninguna marca comercial, marca de servicio o derechos
            de autor. Debt.com se reserva todos los derechos sobre su propiedad intelectual en el Sitio.
          </p>
          <h3>POLÍTICA DE INFRACCIÓN DE DERECHOS DE AUTOR</h3>
          <p>
            De acuerdo con los requisitos establecidos en la Ley de Derechos de Autor del Milenio Digital, título 17 del
            Código de los Estados Unidos, sección 512©(2) (“DMCA”), Debt.com investigará los avisos de infracción de
            derechos de autor y tomará medidas correctivas adecuadas. Si cree que algún Contenido del Sitio se ha
            utilizado o copiado de una manera que infrinja su trabajo, proporcione una notificación por escrito de la
            presunta infracción de derechos de autor al Agente Designado para el Sitio, que contenga los siguientes
            elementos según lo establecido en la DMCA:
          </p>
          <ul>
            <li>
              una firma física o electrónica del propietario del derecho de autor supuestamente infringido o de la
              persona autorizada a actuar en nombre del propietario;
            </li>
            <li>
              identificación de las obras protegidas por derechos de autor que se afirma que se han infringido, incluida
              la fecha de los derechos de autor;
            </li>
            <li>
              identificación del Contenido que usted afirma que se infringe sus derechos y que usted solicita que se
              elimine del Sitio o se deshabilite su acceso, junto con una descripción de dónde se encuentra el Contenido
              infractor;
            </li>
            <li>
              información razonablemente suficiente para permitirnos comunicarnos con usted, como un domicilio físico,
              un número de teléfono y una dirección de correo electrónico;
            </li>
            <li>
              una declaración suya de que cree de buena fe que el uso del Contenido identificado en su notificación
              escrita en la forma reclamada no está autorizado por usted o el propietario de los derechos de autor, su
              agente o la ley; y
            </li>
            <li>
              una declaración suya de que la información contenida en su notificación escrita es exacta y que, bajo pena
              de perjurio, usted es el propietario de los derechos de autor o está autorizado a actuar en nombre del
              propietario de los derechos de autor.
            </li>
          </ul>
          <p>
            Los siguientes son los datos para comunicarse con el agente designado de Debt.com para la notificación
            escrita de reclamos por infracción de derechos de autor:
          </p>
          <p>
            Designated Agent – Copyright Infringement Claims <br />
            Debt.com LLC <br />
            8220 W. State Road 84, <br />
            Fort Lauderdale, FL 33324 <br />
            Correo electrónico:{" "}
            <strong>
              <a href="mailto:admin@debt.com">
                <u>admin@Debt.com</u>
              </a>
            </strong>
          </p>
          <h3>SUS REQUISITOS DE EXENCIÓN DE RESPONSABILIDAD</h3>
          <p>
            Usted acepta defender y eximir de toda responsabilidad a nosotros y a nuestros funcionarios, directores,
            accionistas, empleados, contratistas independientes, agentes, representantes y afiliados ante todo reclamo y
            gasto, incluidos, entre otros, honorarios de abogados, que surjan de, se relacionen con, lo siguiente: (i)
            cualquier incumplimiento o violación de este Acuerdo por su parte; (ii) el que usted no nos haya
            proporcionado información de identificación personal («PII») exacta, completa y actual en respuesta a
            cualquier formulario de solicitud de nuestro Sitio; (iii) su acceso o uso de los Servicios por medio de
            nuestro Sitio; (iv) el acceso a nuestro Sitio o uso de nuestros Servicios con cualquier contraseña que se le
            pueda emitir; (v) sus transmisiones, envíos o publicaciones (es decir, su propio Contenido Generado por el
            Usuario); (vi) los Servicios que reciba de cualquiera de los Proveedores de Servicios a los que lo
            remitimos; o (vii) cualquier lesión personal o daño material causado por usted.
          </p>
          <h3>TÉRMINOS GENERALES / VARIOS</h3>
          <p>
            <strong>Legislación aplicable, jurisdicción, fuero</strong>. En la medida máxima permitida por la ley, este
            Acuerdo se rige por las leyes del estado de Florida (EE. UU.), sin tener en cuenta ninguna disposición sobre
            elección de la legislación aplicable. Si opta de manera apropiada por no participar en arbitraje, por la
            presente acepta la jurisdicción y el fuero exclusivos de los tribunales del condado de Broward (Florida, EE.
            UU.) en todas las disputas que surjan del uso del sitio web Debt.com o estén relacionadas con dicho uso. Si
            no optó por no participar en arbitraje, consulte la disposición sobre arbitraje más abajo para conocer la
            legislación aplicable y la jurisdicción que rige su arbitraje. Usted y nosotros aceptamos someternos a la
            jurisdicción personal de los tribunales situados en el condado de Broward (Florida) con el fin de litigar la
            totalidad de dichos reclamos o disputas.
          </p>
          <p>
            <strong>Relación de las Partes. </strong>Usted acepta que no existe ninguna relación de empresa conjunta,
            sociedad, empleo o agencia entre usted y Debt.com como resultado de este Acuerdo o del uso del Sitio
            Debt.com.
          </p>
          <p>
            <strong>Aplicabilidad de las leyes. </strong>El cumplimiento de este acuerdo por parte de Debt.com está
            sujeto a las leyes y procesos legales existentes, y nada de lo contenido en este acuerdo constituye una
            derogación del derecho de Debt.com a cumplir con las solicitudes o requisitos gubernamentales, judiciales y
            policiales relacionados con su uso del Sitio Debt.com o cualquier información proporcionada o recopilada por
            Debt.com con respecto a dicho uso. El uso del sitio Debt.com no está autorizado en ninguna jurisdicción que
            no dé efecto a todas las disposiciones de los presentes términos y condiciones, incluidos, entre otros, este
            párrafo.
          </p>
          <p>
            <strong>Separabilidad. </strong>Si se determina que alguna parte de este Acuerdo es inválida o inaplicable
            de conformidad con las leyes pertinentes, incluidas, entre otras, las renuncias de garantía y las
            limitaciones de responsabilidad establecidas anteriormente, entonces la disposición inválida o inaplicable
            se considerará reemplazada por una disposición válida y aplicable que coincida en la mayor medida posible
            con la intención de la disposición original, y el resto del acuerdo continuará en vigor.
          </p>
          <p>
            <strong>Acuerdo completo. </strong>A menos que se especifique lo contrario en este documento, el presente
            Acuerdo, junto con nuestra Política de Privacidad y todo Aviso de nuestro Sitio, que se incorporan aquí como
            referencia, juntos constituyen el acuerdo completo entre Usted y Debt.com con respecto al Sitio y reemplaza
            a todas las comunicaciones y propuestas anteriores o contemporáneas, ya sean electrónicas, orales o
            escritas, entre el usuario y Debt.com con respecto al Sitio Web Debt.com. Una versión impresa de este
            Acuerdo y de cualquier aviso dado en formato electrónico será admisible en procedimientos judiciales o
            administrativos basados en este Acuerdo o relacionados con él, en la misma medida y con sujeción a las
            mismas condiciones que otros documentos y registros comerciales generados originalmente y mantenidos en
            forma impresa. Es el deseo expreso de las partes que este Acuerdo y todos los documentos relacionados se
            redacten en inglés, a pesar de tener estos términos traducidos a otros idiomas.
          </p>
          <h3>MODIFICACIONES A ESTE ACUERDO</h3>
          <p>
            Nos reservamos el derecho de actualizar, modificar o cambiar este Acuerdo en cualquier momento a nuestro
            exclusivo criterio y sin previo aviso. Las actualizaciones de este Acuerdo se publicarán aquí. Las
            modificaciones entrarán en vigor inmediatamente después de que publiquemos el Acuerdo actualizado en
            nuestros Servicios. Le recomendamos que revise este Acuerdo cada cierto tiempo para revisar los cambios que
            se haya realizado. La fecha en la que este Acuerdo se actualizó por última vez se indicará antes del
            comienzo de este Acuerdo. Si usted continúa usando nuestros Servicios y accediendo a ellos después de la
            publicación de dichos cambios, se considerará automáticamente que usted acepta todos los cambios.
          </p>
          <p>
            <strong>
              Resolución de disputas mediante arbitraje vinculante: AVISO IMPORTANTE DE EXENCIÓN DE SU DERECHO A
              PRESENTAR UNA RECLAMACIÓN ANTE UN JUEZ O JURADO EN TRIBUNAL.
            </strong>
          </p>
          <p>
            En caso de cualquier controversia entre usted y Debt.com, incluidas, entre otras, cualquier demanda,
            reclamo, demanda contra coparte, contrademanda o queja o disputa de terceros, ya sea contractual,
            reglamentaria, extracontractual o de otro tipo (incluidos, a modo de ejemplo, nuestros prestadores de
            servicios designados y todo proveedor de distribución que trabaje en su nombre) que surja de nuestra
            relación o se relacione de alguna manera con ella, lo cual incluye, a modo de ejemplo, este Acuerdo, nuestra
            Política de Privacidad, los avisos de renuncia del Sitio, el uso del Sitio Debt.com, la información
            contenida en el Sitio Debt.com, cualquier servicio de referencias proporcionado por Debt.com, o el
            incumplimiento, resolución, aplicación, interpretación o validez del Acuerdo o su ejecución, incluida toda
            determinación del alcance, aplicación o aplicabilidad de esta disposición de arbitrar, se resolverá mediante
            arbitraje vinculante en el condado de Broward (Florida) o la zona metropolitana más cercana al condado en el
            que usted reside, o en cualquier otro lugar que las partes acuerden. Esta disposición de arbitraje se rige
            por la Ley Federal de Arbitraje (“FAA”), 9 U.S.C. §1 y sig., que regirán la interpretación y aplicación de
            nuestro acuerdo mutuo para arbitrar, y no por ningún reglamento o normativa estatal que rija el arbitraje y
            sin respeto a ninguna ley de arbitraje estatal. Este acuerdo de arbitraje seguirá teniendo vigencia tras la
            extinción del Acuerdo. Las Partes acuerdan los siguientes Términos de Arbitraje:
          </p>
          <ol>
            <li>
              El arbitraje será administrado por la Asociación Estadounidense de Arbitraje (“AAA”) (
              <a href="http://www.adr.org/" target="_blank" rel="noreferrer noopener">
                www.adr.org
              </a>
              ), los Servicios de Mediación de Arbitraje Judicial (“JAMS”) u otro servicio de arbitraje para
              consumidores conocido a nivel nacional acordado por la partes en caso de que AAA y JAMS ya no realicen
              arbitrajes para consumidores. La Parte que pretende solicitar el arbitraje debe primero enviar a la otra
              Parte un Aviso de Disputa por escrito antes de presentarla ante el proveedor de servicios de arbitraje. El
              Aviso debe describir tanto la naturaleza como el fundamento de la disputa; y la reparación específica
              buscada. Todo aviso se considerará entregado y recibido a todos los efectos cuando se entregue en mano, un
              día después de su recepción mediante un servicio de mensajería nocturno reconocido a nivel nacional, o un
              día después de haber sido recibido por correo certificado o registrado, con franqueo y cargos pagados por
              adelantado y con acuse de recibo, a nosotros en la siguiente dirección: Debt.com, LLC, Attn: Legal
              Department, 8220 W. State Road 84, Fort Lauderdale, FL 33324.
            </li>
            <li>
              <strong>Inicio y reglas de arbitraje. </strong>Si no resolvemos un reclamo dentro de los 30 días
              posteriores a la recepción del Aviso, la parte que envía el Aviso puede iniciar un procedimiento de
              arbitraje ante la AAA, JAMS u otra disposición de arbitraje nacional si la AAA o JAMS no están
              disponibles, para lo cual debe presentar una Demanda de arbitraje que cobrará vigor al momento de
              iniciarse la acción. Si elige presentar la demanda ante la AAA, puede descargar o copiar el formulario de
              inicio de arbitraje desde el siguiente sitio web: http://www.adr.org/consumer. La AAA proporcionará a las
              partes una lista de 5 árbitros y las partes podrán ponerse de acuerdo en uno de ellos. Si las partes no
              pueden llegar a un acuerdo mutuo sobre un árbitro, las partes clasificarán a los árbitros en orden de
              preferencia y la AAA designará un único árbitro que administrará el procedimiento de arbitraje de
              conformidad con sus Procedimientos de Resolución de Disputas Comerciales y los Procedimientos
              Suplementarios para Disputas Relacionadas con el Consumidor (“Reglas de la AAA”), con excepción de las
              reglas de arbitraje masivo o colectivo. Puede consultar las reglas de la AAA en www.adr.org o pedirlas por
              teléfono a la AAA al 1-800-778-7879. Si elige presentar una solicitud ante JAMS, puede descargar o copiar
              el formulario para iniciar el arbitraje desde el siguiente sitio web:{" "}
              <a href="https://www.jamsadr.com/adr-forms" target="_blank" rel="noreferrer noopener">
                https://www.jamsadr.com/adr-forms
              </a>
              . JAMS proporcionará a las partes una lista de 5 árbitros y las partes podrán ponerse de acuerdo en uno de
              ellos. Si las partes no pueden llegar a un acuerdo mutuo sobre un árbitro, las partes clasificarán a los
              árbitros en orden de preferencia y JAMS designará un único árbitro que administrará el procedimiento de
              arbitraje de conformidad con sus Procedimientos de Resolución de Disputas Comerciales y las Normas Mínimas
              de Arbitraje del Consumidor (“Normas de JAMS”), con excepción de las reglas de arbitraje masivo o
              colectivo. En caso de que se proporcione otro servicio de arbitraje, las partes acuerdan seguir las reglas
              y principios de ese servicio de arbitraje, excepto en lo que respecta a cualquier derecho a las reglas de
              arbitraje masivo o colectivo, que las Partes acuerdan expresamente que no se aplicarán.
            </li>
            <li>
              <strong>El árbitro. </strong>El árbitro seleccionado deberá ser un juez jubilado o un abogado
              experimentado, será neutral e independiente, y deberá cumplir con el código de ética del servicio de
              arbitraje seleccionado. El árbitro conducirá el arbitraje de buen modo y garantizará el buen
              comportamiento de las partes al presentar sus argumentos. Asimismo, al llevar a cabo la audiencia de
              arbitraje, además de las reglas del servicio de arbitraje seleccionado, el árbitro se guiará por las
              Reglas Federales de Procedimiento Civil, las Reglas Federales de Pruebas y la ley sustantiva de Florida.
            </li>
            <li>
              <strong>El laudo arbitral. </strong>El árbitro emitirá un laudo razonado por escrito en el que abordará
              las reclamaciones, defensas, pruebas presentadas y razonamiento legal utilizado al tomar su decisión. Si
              el árbitro determina que deben pagarse honorarios de abogados y costos razonables según la ley aplicable,
              las partes acuerdan que el árbitro también determinará el monto de los honorarios de abogados razonables
              que deben pagarse. Todo laudo dictado por el árbitro será definitivo y vinculante, y no se lo podrá anular
              o modificar, excepto según lo permita expresamente la Ley Federal de Arbitraje. La sentencia sobre el
              laudo arbitral podrá dictarse en la jurisdicción en la que se inició el arbitraje o en cualquier tribunal
              que tenga competencia sobre la Parte contra la cual se dictará sentencia.
            </li>
            <li>
              <strong>
                Renuncia al derecho de presentar demandas masivas o colectivas. Las partes acuerdan que cualquiera de
                las partes puede presentar reclamaciones contra la otra solo a título individual y no como demandante o
                miembro de un grupo en cualquier supuesto procedimiento masivo, colectivo u otro procedimiento
                representativo. Además, las partes acuerdan que el árbitro no podrá consolidar los procedimientos de más
                de una persona y no podrá presidir ninguna forma de procedimiento representativo o colectivo.
              </strong>
            </li>
            <li>
              <strong>Limitación de medidas cautelares: </strong>El árbitro puede dictar medidas cautelares solo a favor
              de una parte individual que busque reparación y solo en la medida necesaria para proporcionar la
              reparación que amerite el reclamo individual de esa parte.
            </li>
            <li>
              <strong>Costos del arbitraje: </strong>El pago de todos los gastos de presentación y administración, y los
              honorarios del árbitro se regirá por las reglas del servicio de arbitraje seleccionado. Cada parte pagará
              sus propios honorarios de abogados, si los hubiere, a menos que dichos honorarios estén expresamente
              previstos por la ley aplicable. En el caso de que una parte no proceda con el arbitraje, objete sin éxito
              el laudo del árbitro o no cumpla con el laudo del árbitro, la otra parte tendrá derecho a que le pague los
              costos de la demanda, incluidos los honorarios razonables de los abogados por tener que obligar al
              arbitraje o defenderse o hacer cumplir el laudo.
            </li>
            <li>
              <strong>Exclusión voluntaria del arbitraje. </strong>USTED PUEDE EXCLUIRSE DE LOS TÉRMINOS DE ESTA
              DISPOSICIÓN DE ARBITRAJE, PARA LO CUAL DEBE PROPORCIONAR A DEBT.COM UN AVISO POR ESCRITO EN UN PLAZO DE
              TREINTA (30) DÍAS DESPUÉS DE UTILIZAR NUESTRO SITIO POR PRIMERA VEZ. Si lo hace, ni usted ni nosotros
              podemos exigir al otro que participe en un procedimiento de arbitraje. Para optar por excluirse, debe
              notificarnos por escrito, ya sea por correo postal de EE.UU. a la dirección Attn: Legal Department,
              Debt.com, LLC, 8220 W. State Road 84, Fort Lauderdale, FL 33324, o por correo electrónico a{" "}
              <a href="mailto:admin@debt.com">admin@debt.com</a>. Debe incluir: (1) su nombre y domicilio particular;
              (2) la dirección de correo electrónico o número de teléfono celular asociado con su cuenta; y (3) una
              declaración clara de que desea excluirse de este acuerdo de arbitraje.
            </li>
            <li>
              <strong>
                ¿Qué significa arbitraje? Al aceptar el arbitraje, usted y nosotros reconocemos y aceptamos que cada uno
                de nosotros: (i) renunciamos al derecho de seguir cualquier otro proceso de resolución de disputas
                disponible, incluidos, entre otros, las demandas judiciales y los procedimientos administrativos, (ii)
                renunciamos al derecho de participar en cualquier demanda colectiva o masiva, (iii) renunciamos al
                derecho a juicio ante juez o jurado, y (iv) renunciamos a todo derecho a apelar la decisión del árbitro,
                excepto por determinadas cuestiones apelables expresamente previstas en la FAA. Las partes consideran
                que esta sección es un requisito esencial e irrevocable, y forma parte de este Acuerdo, y las partes
                acuerdan que antes de firmar este Acuerdo, incluida la aceptación de esta sección: (1) han tenido la
                oportunidad de revisarlos cuidadosamente, incluso con su propio asesor legal si es necesario, (2) las
                partes comprenden las limitaciones de los derechos legales contenidos en esta sección, entre ellos, la
                renuncia al juicio con jurado, la renuncia a procedimientos judiciales y demandas colectivas, y (3) las
                partes acuerdan y aceptan que esta sección es justa, razonable, representa las intenciones de cada parte
                al momento de celebrar este Acuerdo y ningún tribunal debe dejarla de lado más adelante por ningún
                motivo. Esta sección y el requisito de arbitraje seguirán teniendo vigencia tras la extinción del
                Acuerdo.
              </strong>
            </li>
          </ol>
          <p>
            Sin perjuicio de cualquier disposición en contrario en el Acuerdo, usted y nosotros aceptamos que si
            realizamos un cambio en este acuerdo para someternos a arbitraje en el futuro, ese cambio no se aplicará a
            reclamos presentados en un procedimiento judicial entre usted y nosotros antes de la fecha efectiva del
            cambio.
          </p>

          <h2 ref={privatePolicyTargetRef}>POLÍTICA DE PRIVACIDAD</h2>
          <p>
            <strong>Fecha de la última actualización: 19 de Abril de 2024.</strong>
          </p>
          <p>
            Debt.com, LLC (“Debt.com”) es el propietario de este sitio web. Esta Política de Privacidad en Línea (en
            adelante, a veces denominada “Aviso”) se aplica a esta interfaz en línea de <strong>Debt.com</strong> (es
            decir, sitio web o aplicación móvil) (el “Sitio”). El término <strong>Debt.com</strong> o “nosotros” o “nos”
            o “nuestro” en este Aviso se refiere a Debt.com y a cualquiera de sus empresas relacionadas o filiales que
            puedan adoptar este Aviso. Este Aviso describe cómo este Sitio, o cualquier página de inicio de Debt.com
            vinculada a este Sitio (las páginas de inicio de Debt.com y este Sitio se denominarán colectivamente
            “Sitios”), pueden recopilar, usar y compartir información de usted o sobre usted, y explica cómo se puede
            recopilar y utilizar la información con fines publicitarios. Debt.com puede proporcionar otras interfaces en
            línea no abarcadas en este Aviso. Si visita sus servicios o accede a estos desde uno de dichos sitios,
            revise las prácticas de privacidad en línea de ese sitio para comprender cómo se puede recopilar, utilizar y
            compartir su información en línea.
          </p>
          <p>
            <strong>Aceptación de esta Política:</strong> Al usar, interactuar, ingresar información o ver información
            en este Sitio, usted reconoce y acepta expresamente esta Política de Privacidad en Línea, lo cual incluye
            dar su consentimiento a nuestro uso y divulgación de información recopilada de usted o sobre usted de la
            manera descrita en este documento. Usted acepta que Debt.com puede considerar que su uso e interacción con
            este Sitio son su consentimiento expreso a los términos de este Aviso de Privacidad en Línea.
          </p>
          <h3>Recopilación y uso de información</h3>
          <p>
            <em>Registro y otra información que se nos proporciona</em>
          </p>
          <p>
            Cuando visita o se suscribe a <strong>Debt.com</strong>, es posible que se le solicite que revele
            información de identificación personal (también conocida como “PII”, por sus siglas en inglés). También
            puede optar por enviarnos PII cuando nos envíe un mensaje de correo electrónico. Usted reconoce y acepta que
            podemos usar y almacenar dicha PII sobre usted de conformidad con nuestra Política de Privacidad. También
            podemos procesar y almacenar su PII en un servidor ubicado fuera de su jurisdicción. Al proporcionarnos su
            PII, usted acepta la transferencia de su información a través de fronteras nacionales. Si no da su
            consentimiento para dicha transferencia, así como para el procesamiento y almacenamiento de su información
            de identificación personal fuera de su jurisdicción, no debe proporcionarnos dicha PII y debe finalizar su
            interacción con nuestro Sitio. Debt.com no procesará ni transferirá PII u otra información a través de
            fronteras internacionales, y no realizará ningún procesamiento o transferencia de datos con entidades
            europeas.
          </p>
          <p>
            <em>Información Personal que recopilamos en línea</em> <br />
            Información Personal significa información que usted proporciona por medio de formularios, encuestas,
            solicitudes u otros campos por Internet, entre otros, los siguientes: cualquier información que constituya
            PII, su nombre, direcciones postales o de correo electrónico, números de teléfono fijo, celular o fax, o
            números de cuenta.
          </p>
          <h3>Cómo utilizamos la Información Personal</h3>
          <p>Podemos utilizar Información Personal, incluida la PII, de la siguiente manera:</p>
          <ul>
            <li>Para responder a sus consultas y cumplir con sus solicitudes.</li>
            <li>
              Para comunicarle información importante sobre el Sitio, productos o servicios que puede solicitar o en los
              que esté inscrito, cambios en los términos, condiciones y políticas, u otra información administrativa.
            </li>
            <li>
              Para enviar comunicaciones de marketing que creemos que pueden ser de su interés, tales como anuncios u
              ofertas adaptados a usted, incluidos anuncios en otros sitios web o de terceros relacionados o no
              relacionados.
            </li>
            <li>Para personalizar su experiencia en el Sitio.</li>
            <li>
              Para permitirle solicitar diversos productos o servicios, y evaluar si reúne los requisitos para dichos
              productos o servicios.
            </li>
            <li>
              Para verificar su identidad o ubicación (o la identidad o ubicación de su representante o agente) con el
              fin de permitir el acceso a los servicios en los que pueda inscribirse, realizar transacciones en línea y
              mantener medidas destinadas a prevenir el fraude y proteger la seguridad de su Información Personal.
            </li>
            <li>
              Para permitirle participar en encuestas y otras formas de investigación de mercado, sorteos, concursos y
              promociones similares, y para administrar estas actividades. Estas actividades pueden tener reglas de
              participación adicionales, que también pueden contener políticas adicionales sobre cómo se usa o comparte
              la Información personal proporcionada.
            </li>
            <li>
              Para permitirnos verificar su identidad u otra información recopilada por medio de nuestras páginas o
              campañas de redes sociales y otras interacciones con formularios en línea generados en nuestro nombre.
              Podemos combinar y utilizar la PII que usted proporciona en este Sitio con la información recopilada de
              nuestras páginas de redes sociales o de su interacción con formularios en línea generados en nuestro
              nombre.
            </li>
            <li>
              Para permitirle ingresar y participar en nuestro chat en vivo, foros u otros tablones de anuncios que
              podamos tener en el sitio cada cierto tiempo.
            </li>
            <li>
              Para fines comerciales, incluidos análisis de datos y marketing, auditorías, desarrollo y mejora de
              productos y servicios, mejora del Sitio, identificación de tendencias de uso y determinación de la
              efectividad de las campañas promocionales.
            </li>
            <li>
              Para control de riesgos, detección y prevención de fraude, para cumplir con leyes y reglamentos, y para
              cumplir con otros procesos legales y requisitos de aplicación de la ley.
            </li>
            <li>
              Para permitirle utilizar funciones dentro de nuestro Sitio, al otorgarnos acceso a información de su
              dispositivo, como listas de contactos o geolocalización, cuando solicita ciertos servicios.
            </li>
          </ul>
          <p>
            <strong>Cómo se comparte la Información Personal</strong>
          </p>
          <p>
            Cuando nos envía Información Personal o PII (que puede incluir, entre otra información, su nombre, apellido,
            dirección de correo electrónico, código postal, número de teléfono de contacto y otra información
            proporcionada por usted, como su fecha de nacimiento o ingresos familiares), usted reconoce y acepta que
            podemos compartir esa información con proveedores de servicios externos que ofrezcan diversos productos o
            servicios en los que estaba interesado o sobre los que ha solicitado información adicional. También reconoce
            y acepta que la Información Personal proporcionada también puede usarse con fines de marketing cruzado con
            terceros relacionados y para brindarle ofertas que puedan ser de su interés o beneficio sobre la base de su
            solicitud inicial. Nuestras empresas relacionados o de terceros pueden utilizar su dirección de correo
            electrónico para su propio uso de marketing.
          </p>
          <p>
            También podemos compartir información que no se considera PII recopilada de usted, que consiste en
            información general sobre nuestra base de usuarios con nuestras empresas publicitarias relacionadas que
            dirigen tráfico a nuestro sitio web, para ayudarlos a identificar a nuestro cliente ideal y dirigir la
            publicidad a él. También podemos compartir su información de contacto con terceros que podrían comunicarse
            con usted para una consulta gratuita y sin compromiso sobre servicios que pueden ser de su interés en
            función de su interacción con nuestro Sitio. Como referencia, encontrará una lista no excluyente de empresas
            de terceros con las que podemos compartir su información{" "}
            <a target="_blank" href="https://www.debt.com/privacy-policy/partners/" rel="noreferrer">
              aquí
            </a>
            .
          </p>
          <p>
            <strong>Derecho a optar por no compartir</strong>
          </p>
          <p>
            Si prefiere que no compartamos su PII o información recopilada adicionalmente con terceros con fines de
            marketing directo, puede optar por no compartirla, para lo cual debe enviarnos su solicitud por correo
            electrónico a <a href="mailto:admin@Debt.com">admin@Debt.com</a>.
          </p>
          <p>
            <strong>Otra Información que recopilamos en línea</strong> <br />
            Otra Información es cualquier información, que no sea Información Personal o PII, que no revela su identidad
            específica o no se relaciona directamente con un individuo, como la información del navegador, la
            información recopilada mediante cookies, etiquetas de píxeles y otras tecnologías, información demográfica y
            datos agregados y anonimizados. En el caso de que se determine que la información recopilada mediante
            cookies, etiquetas de píxeles y otras tecnologías, o la información demográfica contiene PII, usted
            comprende y acepta que Debt.com puede usar y transferir dicha PII de conformidad con los términos que rigen
            la PII en este Aviso.
          </p>
          <p>¿Cómo recopilamos y utilizamos Otra Información?</p>
          <p>
            Nosotros y cualquiera de nuestros proveedores de servicios externos afiliados podemos recopilar y utilizar
            Otra Información de diversas maneras, entre ellas las siguientes:
          </p>
          <ul>
            <li>
              <strong>Por medio de su navegador o dispositivo:</strong> Hay determinada información que recopilan la
              mayoría de los navegadores o que se recopila por medio de su dispositivo, como su dirección de control de
              acceso a medios (MAC), el tipo de dispositivo, la resolución de la pantalla, la versión del sistema
              operativo y el tipo y versión del navegador de Internet. Si bien dicha información a menudo se considera
              Otra Información, usted comprende que se la puede tratar como PII y, por lo tanto, independientemente de
              su naturaleza, puede usarse según las disposiciones de PII de este Aviso. Utilizamos esta información para
              garantizar que los Sitios funcionen correctamente, para la detección y prevención de fraudes y con fines
              de seguridad.
            </li>
            <li>
              <strong>Mediante cookies:</strong> Las cookies son pequeños archivos de información que se almacenan
              directamente en el dispositivo que usted utiliza para acceder a nuestro Sitio. Las cookies que utilizamos
              no contienen ni capturan Información Personal no cifrada. Las cookies nos permiten recopilar información
              tal como el tipo de navegador, el tiempo que pasan los usuarios en el Sitio, las páginas visitadas, las
              preferencias de idioma y la interacción general con nuestro Sitio. Podemos utilizar la información
              recopilada de las cookies para diversos fines, incluidos, entre otros, los siguientes: por motivos de
              seguridad, para facilitar la navegación, para mostrar información de manera más eficaz, para personalizar
              o adaptar su experiencia mientras interactúa con nuestro Sitio o interactúa con nosotros, y para reconocer
              su dispositivo en el futuro con el fin de facilitar el uso de nuestros productos y servicios en línea.
              Además, recopilamos información estadística sobre el uso del Sitio para mejorar continuamente el diseño y
              la funcionalidad, monitorear las respuestas a nuestros anuncios y contenido, comprender cómo usan el Sitio
              los visitantes y ayudarnos a resolver problemas con el Sitio. También utilizamos cookies con fines
              publicitarios. Consulte la sección Publicidad más abajo para obtener más información. Puede negarse a
              aceptar estas cookies, y la mayoría de los dispositivos y navegadores ofrecen sus propias configuraciones
              de privacidad para cookies. Deberá administrar la configuración de cookies para cada dispositivo y
              navegador que utilice. Sin embargo, tenga en cuenta que si decide no aceptar estas cookies, es posible que
              el Sitio y algunos productos y servicios en línea no funcionen correctamente cuando interactúe con nuestro
              Sitio.
            </li>
            <li>
              <strong>Otras tecnologías, incluidas etiquetas de píxeles, balizas web y GIF transparentes:</strong> Estas
              pueden usarse en conexión con algunas páginas del Sitio, aplicaciones móviles descargables y mensajes de
              correo electrónico con formato HTML para medir la eficacia de nuestras comunicaciones, el éxito de
              nuestras campañas de marketing, para compilar estadísticas sobre el uso y las tasas de respuesta, para la
              detección y prevención de fraudes, con fines de seguridad y con fines publicitarios. Usted comprende y
              acepta que podemos utilizar etiquetas de píxeles, balizas web o GIF transparentes de terceros que pueden
              dar acceso a terceros a su información, incluida su dirección IP y actividad de navegación en las páginas
              de nuestros Sitios donde se encuentran estos dispositivos. Usted comprende que no controlamos ninguna
              información recopilada por dichos dispositivos ni su uso por parte de dichos terceros. Consulte nuestra
              sección Publicidad más abajo para obtener más información sobre nuestro uso de estas y otras tecnologías.
            </li>
            <li>
              <strong>Google Analytics:</strong> Utilizamos una herramienta llamada “Google Analytics” para recopilar
              información sobre el uso de este Sitio. Google Analytics recopila información tal como la frecuencia con
              la que los usuarios visitan este Sitio, qué páginas visitan cuando lo hacen y qué otros Sitios utilizaron
              antes de acceder a este Sitio. Utilizamos la información que obtenemos de Google Analytics solo para
              mejorar este Sitio. Google Analytics recopila únicamente la dirección IP que se le asignó en la fecha en
              la que visita este sitio, en lugar de su nombre u otra información que lo identifique. Si se determina que
              Google Analytics es PII, mantendremos y utilizaremos la información recopilada de Google Analytics de
              conformidad con los términos que rigen la PII en este Aviso. No combinamos la información recopilada
              mediante el uso de Google Analytics con otra PII que usted pueda proporcionar en nuestro Sitio. Aunque
              Google Analytics coloca una cookie permanente en su navegador web para identificarlo como usuario único la
              próxima vez que visite este Sitio, nadie más que Google puede utilizar la cookie. La capacidad de Google
              de utilizar y compartir información recopilada por Google Analytics sobre sus visitas a este Sitio está
              restringida por los{" "}
              <a href="https://www.google.com/analytics/terms/" target="_blank" rel="noreferrer noopener">
                Términos de Uso de Google Analytics
              </a>{" "}
              y la{" "}
              <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">
                Política de Privacidad de Google
              </a>
              . Puede evitar que Google Analytics lo reconozca cuando vuelva a visitar este sitio. Para ello, debe{" "}
              <a href="https://www.usa.gov/optout_instructions.shtml" target="_blank" rel="noreferrer noopener">
                deshabilitar las cookies
              </a>{" "}
              en su navegador.
            </li>
            <li>
              <strong>Dirección IP:</strong> Su dirección IP es un número que su proveedor de servicios de Internet
              (ISP) le asigna automáticamente al dispositivo que usted está utilizando. Su dirección IP puede ser
              dinámica o estática. La dirección IP se identifica y registra automáticamente en los archivos de registro
              de nuestro servidor cada vez que un usuario visita el Sitio, junto con la hora de la visita y las páginas
              que fueron visitadas. La recopilación de direcciones IP es un procedimiento estándar en Internet, y muchos
              sitios web la realizan automáticamente. Trataremos su dirección IP como PII si se utiliza para
              identificarlo para cualquier propósito de conformidad con este Aviso. Además, podemos utilizar direcciones
              IP para fines tales como calcular los niveles de uso del Sitio, ayudar a diagnosticar problemas del
              servidor, publicidad, con fines de cumplimiento normativo y de seguridad, y administrar el Sitio. Consulte
              la sección Publicidad más abajo para obtener más información.
            </li>
            <li>
              <strong>Datos agregados y anonimizados:</strong> Los datos agregados y anonimizados son datos que podemos
              crear o compilar a partir de diversas fuentes, incluidas, entre otras, cuentas y transacciones. Esta
              información, que no identifica la PII individual, puede usarse para nuestros fines comerciales, que pueden
              incluir ofrecer productos o servicios, investigar, comercializar o analizar tendencias del mercado, y
              otros fines consecuentes con las leyes aplicables.
            </li>
          </ul>
          <p>
            <strong>Información adicional</strong> <br />
            <em>Widgets de terceros</em>: podemos permitir el uso de ciertos widgets (por ejemplo, botones para
            compartir en redes sociales) en nuestros Sitios, que permitan a los usuarios compartir información
            fácilmente en otra plataforma, tal como la plataforma de redes sociales del usuario. Usted comprende y
            acepta que los widgets de terceros pueden dar acceso, y a los terceros se les puede otorgar acceso, a cierta
            información sobre su navegación en las páginas de nuestros Sitios donde se encuentran dichos widgets. Usted
            comprende que no tenemos control sobre la información a la que obtienen acceso los terceros, y es
            recomendable que revise la información de privacidad del sitio del tercero para determinar cómo esos
            terceros recopilan y tratan dicha información.
          </p>
          <p>
            <em>Retargeting</em>: mientras navega en{" "}
            <a target="_blank" href="https://www.debt.com/" rel="noreferrer">
              https://www.debt.com
            </a>
            , se colocarán cookies de publicidad en su computadora para que podamos conocer lo que le interesa. Nuestros
            socios de publicidad gráfica nos permiten presentarle publicidad de retargeting en otros sitios en función
            de su interacción previa con https://www.debt.com y páginas y sitios relacionados.
          </p>
          <p>
            <em>Programa de Publicidad Basada en Intereses de Google:</em> para mejorar su experiencia cuando visita
            nuestro Sitio, participamos en el Programa de Publicidad Basada en Intereses de Google (“Programa”) para
            garantizar que los anuncios de este Sitio se correspondan con los intereses de usted. Para dicho fin,
            trabajando con Google y otros participantes del Programa que, como nosotros, colocan anuncios de publicidad
            en nuestro Sitio y en los de otros Participantes del Programa. Luego compartimos la información que
            recopilamos mediante cookies web colocadas en su navegador para brindarle publicidad dirigida cuando visita
            el Sitio. Puede optar por no participar en el uso de cookies por parte de Google, para lo cual debe visitar
            la{" "}
            <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noreferrer noopener">
              https://policies.google.com/technologies/ads
            </a>{" "}
            página de exclusión voluntaria de publicidad de Google.
          </p>
          <p>
            <em>Balizas web de terceros:</em> utilizamos balizas web externas de Yahoo! como ayuda para analizar adónde
            van los visitantes y qué hacen mientras visitan nuestro sitio web. Yahoo! también puede utilizar información
            anónima sobre sus visitas a este y otros sitios web para mejorar sus productos y servicios, y proporcionar
            anuncios sobre bienes y servicios de su interés. Si desea obtener más información sobre esta práctica y
            conocer sus opciones para que Yahoo! no utilice esta información, haga clic aquí o visite{" "}
            <a href="https://yahoo.mydashboard.oath.com/device" target="_blank" rel="noreferrer noopener">
              https://yahoo.mydashboard.oath.com/device
            </a>
            .
          </p>
          <p>
            <em>Las Empresas de Facebook:</em> Facebook posee y dirige cada una de las empresas que se enumeran a
            continuación, de conformidad con sus respectivos términos de servicio y políticas de privacidad. Podemos
            compartir información sobre usted dentro de la familia de empresas para ayudar a publicitar nuestros
            servicios. Para obtener más información sobre las prácticas de privacidad de las Empresas de Facebook y cómo
            tratan la información de las personas, la PII o la información recopilada adicionalmente, visite los
            siguientes enlaces:
          </p>
          <ul>
            <li>
              Facebook Payments Inc. (
              <a target="_blank" href="https://www.facebook.com/payments_terms/privacy" rel="noreferrer">
                https://www.facebook.com/payments_terms/privacy
              </a>
              )
            </li>
            <li>
              Facebook{" "}
              <a href="https://developers.facebook.com/policy" target="_blank" rel="noreferrer noopener">
                (https://developers.facebook.com/policy)
              </a>
            </li>
            <li>
              Facebook{" "}
              <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noreferrer noopener">
                (https://www.facebook.com/about/privacy/)
              </a>
            </li>
            <li>
              Facebook{" "}
              <a href="https://www.facebook.com/legal/terms" target="_blank" rel="noreferrer noopener">
                (https://www.facebook.com/legal/terms)
              </a>
            </li>
            <li>
              Términos de servicio de Instagram LLC{" "}
              <a href="https://www.instagram.com/about/legal/terms/" target="_blank" rel="noreferrer noopener">
                (https://www.instagram.com/about/legal/terms/)
              </a>
            </li>
            <li>
              Atlas (
              <a target="_blank" href="https://www.atlashxm.com/privacy-notice" rel="noreferrer">
                <u>https://www.atlashxm.com/privacy-notice</u>
              </a>
              )
            </li>
            <li>
              Instagram LLC{" "}
              <a href="https://instagram.com/about/legal/privacy/" target="_blank" rel="noreferrer noopener">
                (http://instagram.com/about/legal/privacy/)
              </a>
            </li>
            <li>
              Parse{" "}
              <a href="https://opencollective.com/privacypolicy" target="_blank" rel="noreferrer noopener">
                (https://opencollective.com/privacypolicy)
              </a>
            </li>
            <li>
              Oculus{" "}
              <a href="https://www.oculus.com/legal/privacy-policy" target="_blank" rel="noreferrer noopener">
                (https://www.oculus.com/legal/privacy-policy/)
              </a>
            </li>
            <li>
              WhatsApp Inc.{" "}
              <a href="https://www.whatsapp.com/legal/" target="_blank" rel="noreferrer noopener">
                (https://www.whatsapp.com/legal/)
              </a>
            </li>
          </ul>
          <p>Política de exclusión voluntaria para suscripciones a boletines informativos y correo electrónico</p>
          <p>
            Puede optar por no recibir o darse de baja de un boletín informativo u otra lista de correo electrónico en
            cualquier momento, para lo cual debe seguir las instrucciones que se encuentran al final de los boletines o
            mensajes de correo electrónico que reciba. Espere de cinco a diez (5 a 10) días hábiles para que los cambios
            entren en vigencia. Usted comprende y acepta que durante ese período y mientras su solicitud esté pendiente,
            es posible que continúe recibiendo boletines informativos y mensajes de correo electrónico. Las
            comunicaciones relacionadas con el servicio al cliente son una parte esencial de los servicios que recibe de
            nosotros, y es posible que continúe recibiendo dichos mensajes de correo electrónico relacionados con el
            servicio a menos que cancele su cuenta, incluso si opta por no recibir los boletines informativos o la lista
            de correo electrónico. Si nos ha proporcionado más de una dirección de correo electrónico, es posible que
            sigamos poniéndonos en contacto con usted a menos que solicite cancelar la suscripción de cada dirección de
            correo electrónico que haya proporcionado. Otra opción para dejar de recibir nuestro boletín informativo o
            mensajes de correo electrónico de marketing es comunicarse con nosotros en{" "}
            <a href="mailto:unsubscribe@Debt.com">
              <strong>unsubscribe@Debt.com</strong>
            </a>{" "}
            o responder un mensaje de correo electrónico existente e incluir en él su solicitud de que lo eliminen de la
            lista de correo.
          </p>
          <p>Servicio de mensajes cortos</p>
          <p>
            Podemos poner a su disposición un servicio a través del cual puede recibir comunicaciones en su dispositivo
            inalámbrico mediante un servicio de mensajes cortos (“Servicio de SMS”). Los datos obtenidos de usted en
            relación con este Servicio de SMS pueden ser su nombre, dirección, número de teléfono celular, nombre de su
            proveedor y la fecha, hora y contenido de sus mensajes. Al proporcionar su información de contacto, usted
            declara que tiene 18 años de edad y es el propietario o usuario autorizado del dispositivo inalámbrico en el
            que se recibirán los mensajes, y que comprende que dicha comunicación puede estar sujeta a ciertos cargos
            aplicables, y que usted aprueba todos los cargos aplicables. Además de toda tarifa que se le notifique,
            pueden aplicarse las tarifas estándares de mensajes y datos de su proveedor a nuestra confirmación por SMS y
            a toda la correspondencia por SMS posterior. Todos los cargos por tarifas de datos son facturados por su
            proveedor de servicios móviles y pagaderos a dicho proveedor. No seremos responsables de ningún retraso en
            la recepción de ningún mensaje SMS, ya que la entrega está sujeta a la eficacia de la transmisión por parte
            de su operador de red. No seremos responsables si los SMS se reciben fuera del horario de atención debido a
            dichos retrasos en la transmisión. Los servicios de mensajes SMS se proporcionan TAL COMO ESTÁN. También
            podemos obtener la fecha, hora y contenido de sus mensajes durante el uso del Servicio de SMS. Utilizaremos
            la información que obtengamos en relación con nuestro Servicio de SMS de conformidad con esta Política de
            Privacidad. Podemos proporcionarle a su proveedor de servicios de telefonía su información correspondiente
            en relación con su consentimiento para recibir SMS. Su proveedor de servicios de telefonía inalámbrica y
            otros proveedores de servicios también pueden recopilar datos sobre el uso de su dispositivo inalámbrico, y
            sus prácticas se rigen por sus propias políticas. No seremos responsables de ningún uso o uso indebido de
            datos por parte de su proveedor de servicios de telefonía inalámbrica.
          </p>
          <p>
            Usted reconoce y acepta que el Servicio de SMS se proporciona mediante sistemas inalámbricos que utilizan
            radios (y otros medios) para transmitir comunicaciones a través de redes complejas. Debe tener un teléfono
            con capacidad para enviar mensajes de texto bidireccionales con un proveedor de servicios de telefonía y un
            plan compatibles. Algunos grandes proveedores de servicios de telefonía compatibles son Alltel Wireless,
            AT&amp;T, Boost Mobile, Nextel, Sprint, T-Mobile, Verizon Wireless y Virgin Mobile USA. No garantizamos que
            su uso del Servicio de SMS sea privado o seguro, y no somos responsables ante usted por cualquier falta de
            privacidad o seguridad que pueda sufrir. Usted es plenamente responsable de tomar precauciones y
            proporcionar las medidas de seguridad que mejor se adapten a su situación y al uso previsto del Servicio de
            SMS.
          </p>
          <p>Suscripción a SMS y llamadas telefónicas</p>
          <p>
            Al proporcionar su número de teléfono o unirse a nuestro Servicio de SMS, usted nos autoriza expresamente a
            comunicarnos con usted (incluso mediante el uso de marcadores automáticos, mensajes de texto automatizados y
            mensajes pregrabados) por medio de su teléfono fijo, teléfono celular, dispositivo móvil (incluso por SMS y
            MMS) y correo electrónico, aunque su número de teléfono figure actualmente en alguna lista de no llamar
            estatal, federal o de la empresa. Se aplicarán cargos estándar de uso de teléfono y datos. Su aceptación de
            los términos anteriores no es necesaria como condición para comprar o recibir nuestros servicios. También
            acepta la grabación y el seguimiento de todas las llamadas hechas por usted a nosotros y hechas por nosotros
            a usted. Puede optar por no participar y eliminar su autorización para recibir dichas llamadas o mensajes de
            texto, para lo cual debe enviarnos sus instrucciones por correo electrónico a{" "}
            <a href="mailto:admin@Debt.com">admin@Debt.com</a>. También puede optar por no participar y eliminar su
            autorización para recibir mensajes de texto SMS, para lo cual debe responder o enviar “STOP”, “END”, “QUIT”,
            “UNSUBSCRIBE” o “CANCEL” a la Empresa.
          </p>
          <p>
            <strong>Seguridad</strong> <br />
            En Debt.com, la seguridad de su información personal es sumamente importante. Tomamos precauciones
            razonables desde el punto de vista comercial para proteger su información personal y solo brindamos acceso a
            ella a los empleados cuando es necesario. En un esfuerzo por evitar el acceso no autorizado, la divulgación,
            la difusión, el uso indebido, la pérdida, la alteración o la destrucción de su información personal, hemos
            empleado medidas físicas, electrónicas y administrativas razonables desde el punto de vista comercial para
            proteger sus datos, y revisamos periódicamente estas políticas y procedimientos para prevenir este tipo de
            actos. Sin embargo, a pesar de nuestros mejores esfuerzos, ninguna política, procedimiento o práctica de
            seguridad es adecuada para proteger contra todo tipo de amenazas o pérdidas de datos, y ningún tipo de
            método de transmisión de datos es impenetrable a la interceptación.
          </p>
          <p>
            <strong>Publicidad</strong> <br />
            <strong>Debt.com</strong> se anuncia en línea por diversos métodos (por ejemplo, páginas dentro de nuestros
            Sitios y aplicaciones móviles, mediante presencias en redes sociales administradas por la empresa o mediante
            otros sitios y aplicaciones móviles no relacionados con Debt.com ) y fuera de línea por diversos métodos
            (por ejemplo, centros de llamadas y marketing directo). Para conocer la eficacia de la publicidad, podemos
            recopilar cierta PII e información recopilada adicionalmente en nuestro Sitio o por medio de otros sitios y
            aplicaciones móviles a través de nuestros proveedores de servicios de publicidad o socios que utilizan
            cookies, direcciones IP y otras tecnologías. La información recopilada puede ser, por ejemplo, la cantidad
            de visitas a páginas, páginas vistas en nuestro Sitio, referencias de motores de búsqueda, actividades de
            navegación a lo largo del tiempo y en otros sitios después de su visita a uno de nuestros Sitios o
            aplicaciones, y respuestas a anuncios y promociones en el Sitio y en sitios y aplicaciones donde ponemos
            publicidad.
          </p>
          <p>
            <strong>Debt.com</strong> utiliza la PII y la información recopilada adicionalmente (según corresponda)
            descrita en este Aviso para ayudar a publicitar nuestros productos y servicios de diversas maneras. Podemos
            utilizar dicha información para lo siguiente:
          </p>
          <ul>
            <li>
              presentarle anuncios personalizados en forma de:
              <ul>
                <li>
                  anuncios tipo banner y anuncios que aparecen al abrir aplicaciones, y otras aplicaciones de movilidad;
                  o
                </li>
                <li>
                  boletines y comunicaciones por correo electrónico, correo postal y otras comunicaciones de
                  telemercadeo;
                </li>
              </ul>
            </li>
            <li>analizar la eficacia de nuestros anuncios; o</li>
            <li>
              determinar si podría estar interesado en nuevos productos o servicios que puedan ofrecer nuestros socios
              proveedores de servicios externos.
            </li>
          </ul>
          <p>
            Nuestros servicios también se le pueden ofrecer en anuncios presentados por otros proveedores con
            información obtenida durante sus visitas a nuestro Sitio.
          </p>
          <p>Cómo adaptamos los anuncios:</p>
          <p>
            <em>Publicidad basada en relaciones</em> <br />
            Para ayudar a que nuestra publicidad sea informativa y útil, podemos usar PII e información recopilada
            adicionalmente sobre su interacción con nosotros (como el tipo de servicios solicitados o consultados,
            información transaccional identificable y no identificable, o información geográfica no identificable).
            Dicha PII y la información recopilada adicionalmente se utilizarán para ayudarnos a determinar qué anuncios
            u ofertas presentarle, o sobre qué tipos de ofertas podría interesarle obtener más información.
          </p>
          <p>
            <em>Publicidad conductual en línea</em> <br />
            Nosotros o nuestros proveedores de servicios de publicidad podemos utilizar cierta información (tanto PII
            como información recopilada adicionalmente) obtenida sobre sus actividades en nuestro Sitio, como las
            páginas visitadas y las palabras clave de búsqueda ingresadas, para ayudar a determinar cuáles de nuestros
            anuncios u ofertas pueden ser de su interés. Esta PII y la información recopilada adicionalmente pueden
            usarse para publicidad en línea y fuera de línea.
          </p>
          <p>Opciones de publicidad</p>
          <p>Puede cambiar sus opciones de publicidad de las siguientes maneras:</p>
          <p>
            <strong>Publicidad conductual en línea presentada por Sitios de Debt.com:</strong> Si prefiere que no
            utilicemos información basada en el comportamiento en el Sitio en línea para proporcionar contenido y
            publicidad personalizados en línea y fuera de línea, puede optar por no recibir publicidad conductual en
            línea; para ello, deberá enviarnos su solicitud por correo electrónico a{" "}
            <a href="mailto:admin@Debt.com">
              <strong>admin@Debt.com</strong>
            </a>
            .
          </p>
          <p>
            <strong>Publicidad conductual en línea de terceros:</strong> Además de configurar su navegador para que no
            permita cookies de nuestro Sitio o cambiar su configuración de Privacidad en otros sitios, como los
            administrados por las Empresas de Facebook, no puede optar por no recibir publicidad conductual en línea de
            terceros que utilicen etiquetas de píxeles, balizas web o GIF transparentes en nuestro sitio.
          </p>
          <p>
            <strong>Marketing directo gestionado por Debt.com:</strong> Si prefiere que no le enviemos ofertas de
            marketing por correo electrónico, correo postal o telemercadeo, puede administrar sus opciones de marketing
            directo enviándonos sus solicitudes por correo electrónico a{" "}
            <a href="mailto:admin@Debt.com">
              <strong>admin@Debt.com</strong>
            </a>
            .
          </p>
          <p>
            Recordatorio importante <br />
            Para que la exclusión voluntaria de la publicidad conductual en línea en nuestros Sitios y en otros sitios
            funcione en su dispositivo, su navegador debe estar configurado para aceptar cookies. Si elimina cookies,
            compra un nuevo dispositivo, accede a nuestro Sitio u otros sitios desde un dispositivo diferente, inicia
            sesión con un nombre de pantalla diferente (si corresponde) o cambia de navegador web, deberá hacer la
            exclusión voluntaria nuevamente. Si su navegador tiene las secuencias de comandos deshabilitadas, no es
            necesario que haga la exclusión voluntaria, ya que la tecnología de publicidad conductual en línea no
            funciona cuando las secuencias de comandos están deshabilitadas. Verifique la configuración de seguridad de
            su navegador para validar si las secuencias de comandos están activas o deshabilitadas.
          </p>
          <p>Enlace a otros sitios</p>
          <p>
            Podemos proporcionar enlaces a sitios de terceros. Si sigue enlaces a sitios no relacionados ni controlados
            por Debt.com, debe revisar sus políticas de privacidad y seguridad y otros términos y condiciones, ya que
            pueden ser diferentes de los de nuestro Sitio. Debt.com no garantiza ni es responsable de ninguna
            interacción entre usted y los sitios de terceros, tal como la privacidad o seguridad de estos sitios,
            incluida la exactitud, integridad o confiabilidad de su información. Usted reconoce que la disponibilidad de
            enlaces a sitios de terceros es simplemente para fines de comodidad, y que Debt.com no respalda, apoya,
            reconoce, acepta ni promueve de ninguna otra manera los contenidos de los sitios de terceros con enlaces en
            nuestro Sitio.
          </p>
          <p>Sitios de redes sociales</p>
          <p>
            Debt.com ofrece experiencias en plataformas de redes sociales que permiten compartir y colaborar en línea
            entre los usuarios que se han registrado para usarlas. Todo contenido que publique en las páginas de redes
            sociales oficiales administradas por Debt.com, como imágenes, información, opiniones o cualquier Información
            Personal que ponga a disposición de otros participantes en estas plataformas sociales, está sujeto a los
            Términos de Uso y las Políticas de Privacidad de esas respectivas plataformas. Al permitirle a usted
            publicar o compartir información en las páginas de redes sociales de Debt.com, usted reconoce que Debt.com
            no respalda, apoya, reconoce ni está de acuerdo de algún otro modo con la información publicada y que dichas
            publicaciones serán de su exclusiva responsabilidad. También acepta que Debt.com puede utilizar cualquier
            información publicada por usted en las redes sociales de Debt.com para cualquier propósito previsto en este
            Aviso. Consúltelos para comprender mejor sus derechos y obligaciones con respecto a dicho contenido. Además,
            tenga en cuenta que, al visitar cualquier página oficial de redes sociales de Debt.com, usted también está
            sujeto a los Avisos de Privacidad de Debt.com.
          </p>
          <p>Seguridad</p>
          <p>
            Para proteger la información personal del acceso y uso no autorizados, utilizamos medidas de seguridad que
            cumplen con las leyes federales y estatales aplicables. Estas medidas pueden incluir protecciones de
            dispositivos y archivos y edificios seguros, así como la supervisión de nuestros proveedores de servicios
            externos para garantizar que la información permanezca confidencial y segura. Si bien seguimos estándares
            del sector generalmente aceptados para proteger la Información Personal y la PII que se nos envía (tanto
            durante su envío como después de recibirla), tenga en cuenta que ningún método de transmisión por Internet,
            o método de almacenamiento electrónico, es 100% seguro. Por lo tanto, si bien nos esforzamos por utilizar
            medios comercialmente aceptables de proteger su Información Personal y su PII, no podemos garantizar su
            seguridad absoluta. Si tiene alguna pregunta sobre la seguridad en nuestro sitio web, puede escribirnos por
            correo electrónico a{" "}
            <a href="mailto:admin@Debt.com">
              <strong>admin@Debt.com</strong>
            </a>
            .
          </p>
          <p>Asegurarse de que la información sea exacta</p>
          <p>
            Usted comprende y acepta que Debt.com supone que la Información Personal que usted proporcionó es, en
            efecto, exacta. Debt.com no será responsable de verificar esa Información Personal. Es responsabilidad de
            usted asegurarse de que la información proporcionada sea exacta y esté al día. Si anteriormente envió
            información incompleta, inexacta o desactualizada, envíenos un mensaje de correo electrónico o escríbanos a
            la dirección correspondiente con la información actualizada que le gustaría que utilicemos en nuestra
            comunicación con usted.
          </p>
          <p>
            Transiciones comerciales <br />
            Usted comprende y acepta que, si Debt.com atraviesa una transición empresarial, como una fusión, adquisición
            por parte de otra empresa o venta de la totalidad o parte de sus activos, es probable que su información
            personal, PII e información recopilada adicionalmente esté entre los activos transferidos.
          </p>
          <p>
            <strong>Aviso para los residentes de California</strong>
          </p>
          <p>
            Los residentes del Estado de California pueden solicitar una lista de todos los terceros a los que hemos
            divulgado información personal durante el año anterior para fines de marketing directo de dichos terceros.
            Si es residente de California y desea conocer dicha lista, escríbanos a{" "}
            <a href="mailto:admin@Debt.com">
              <strong>admin@Debt.com</strong>
            </a>
            .
          </p>
          <p>
            Con ciertas excepciones, los residentes del estado de California pueden solicitar (a) que les revelen qué
            información personal fue recopilada, (b) que les revelen qué información personal fue vendida o divulgada
            con fines comerciales, (c) que se elimine información personal, (d) que se los excluya de la venta de
            información personal, y (e) acceso y portabilidad de los datos. Además, Debt.com no discriminará sobre la
            base del ejercicio de dichos derechos. Si es residente de California y desea hacer alguna de dichas
            solicitudes, comuníquese con nosotros por nuestro sitio web (www.Debt.com) o por correo electrónico a{" "}
            <a href="mailto:admin@Debt.com">admin@Debt.com</a> o por correo postal a Debt.com LLC, 8220 W. State Road
            84, Davie, FL 33324.
          </p>
          <p>
            Para todas estas solicitudes, debe incluir la declaración “Solicitud de privacidad de California” en el
            cuerpo de su solicitud, así como su nombre, dirección, ciudad, estado y código postal. Tenga en cuenta que
            no somos responsables de las solicitudes que no se hayan rotulado o enviado correctamente, o que no tengan
            información completa.
          </p>
          <p>
            <strong>
              Divulgaciones de no rastrear de California <br />
              ¿Cómo respondemos a las señales de “no rastrear” (también conocidas como “DNT”) del navegador web u otros
              mecanismos que brindan a los consumidores la capacidad de elegir con respecto a la recopilación de
              información de identificación personal sobre las actividades en línea de consumidores individuales a lo
              largo del tiempo y en sitios web o servicios en línea de terceros?
            </strong>{" "}
            <br />
            Actualmente, no respondemos a las señales DNT de los navegadores porque no rastreamos a usuarios
            individuales en la Web.
          </p>
          <p>
            <strong>
              ¿Pueden otras partes recopilar información de identificación personal sobre las actividades en línea de
              consumidores individuales a lo largo del tiempo y en diferentes sitios web cuando visitan www.Debt.com?
            </strong>{" "}
            <br />
            Sí, tal como se indica en esta Política de Privacidad.
          </p>
          <p>
            <strong>Aviso para los residentes de Vermont</strong> <br />
            Sobre la base de su consentimiento, Debt.com puede compartir su información con terceros no relacionados con
            Debt.com, en la medida autorizada por esta Política de Privacidad y los reglamentos de Vermont. Como se
            indicó anteriormente, puede retirar su consentimiento para compartir dicho contenido en cualquier momento,
            para lo cual debe enviarnos sus solicitudes por correo electrónico a{" "}
            <a href="mailto:admin@Debt.com">admin@Debt.com,</a> configurar su navegador de modo que no admita cookies de
            nuestro Sitio o cambiar sus ajustes de privacidad en otros sitios, como los administrados por las Empresas
            de Facebook, según corresponda.
          </p>
          <p>
            <strong>Aviso para los residentes de Nevada</strong> <br />
            Le proporcionamos este Aviso de conformidad con la ley estatal. Es posible que lo incluyan en nuestra lista
            interna de no llamar si sigue las instrucciones establecidas anteriormente. La ley de Nevada exige que
            también le proporcionemos la siguiente información de contacto: Bureau of Consumer Protection, Office of the
            Nevada Attorney General, 555 E. Washington St., Suite 3900, Las Vegas, NV 89101; número de teléfono:
            702-486-3132; correo electrónico:{" "}
            <a href="mailto:BCPINFO@ag.state.nv.us">
              <strong>BCPINFO@ag.state.nv.us</strong>
            </a>
            .
          </p>
          <p>
            <strong>Aviso para usuarios de fuera de los Estados Unidos</strong> <br />
            Esta Política de Privacidad en Línea tiene como finalidad cubrir la recopilación de información en nuestros
            sitios web por parte de residentes de los Estados Unidos. Si visita nuestros sitios web desde fuera de los
            Estados Unidos, tenga en cuenta que su información podría transferirse a los Estados Unidos y almacenarse y
            procesarse en los Estados Unidos, donde se encuentran nuestros servidores y funciona nuestra base de datos
            central. Es posible que las leyes y la protección de datos de los Estados Unidos y otros países no sean tan
            completas como las de su país. Al utilizar nuestros servicios, usted comprende que su información puede ser
            transferida a nuestras instalaciones y a aquellos terceros con quienes la compartimos tal como se describe
            en este Aviso. No vendemos, ofrecemos ni brindamos servicios a personas de fuera de los Estados Unidos.
          </p>
          <p>
            <strong>Protección de la privacidad de los niños en línea</strong> <br />
            El Sitio no está dirigido a personas menores de trece (13) años, y solicitamos que estas personas no
            proporcionen información de identificación personal (PII) por medio del Sitio. No recopilamos
            intencionalmente información de niños menores de 13 años sin el consentimiento de los padres. Si usted es un
            Usuario menor de 13 años, no nos envíe ninguna PII si no hemos obtenido el consentimiento previo de su
            padre, madre o tutor. Si descubrimos que hemos recopilado PII de un Usuario menor de 13 años sin el
            consentimiento de sus padres, o si descubrimos que un Usuario menor de 13 años nos ha proporcionado más PII
            de la que le solicitamos, eliminaremos esa información lo mas rápido posible. Si cree que un Usuario menor
            de 13 años podría haber proporcionado PII a Debt.com de una manera que infrinja esta Política de Privacidad,
            escríbanos a la dirección de correo electrónico o a la dirección postal física que figura en la sección
            “Información de contacto” más abajo y haremos todos los esfuerzos razonables para eliminar la información.
            Para obtener más información sobre la Ley de Protección de la Privacidad Infantil en Línea (COPPA, por sus
            siglas en inglés), visite el sitio web de la Comisión Federal de Comercio en{" "}
            <a
              href="https://www.ftc.gov/business-guidance/privacy-security/childrens-privacy"
              target="_blank"
              rel="noreferrer noopener"
            >
              <strong>https://www.ftc.gov/business-guidance/privacy-security/childrens-privacy</strong>
            </a>
            .
          </p>
          <p>
            <strong>Actualizaciones de este Aviso de Privacidad</strong> <br />
            Este Aviso de Privacidad en Línea está sujeto a cambios a exclusivo criterio de Debt.com. Revise la Política
            de Privacidad periódicamente para ver si hay algún cambio. Si realizamos cambios en el Aviso de Privacidad,
            modificaremos la “Fecha de la última actualización” que aparece al comienzo de este Aviso. Todo cambio que
            se le haga a este Aviso entrará en vigencia cuando publiquemos el Aviso corregido en el Sitio. Usted acepta
            que su uso del Sitio después de cualquier cambio en este Aviso significa que acepta el Aviso corregido y las
            Políticas de Privacidad que contiene.
          </p>
          <h4>Información de contacto</h4>
          <p>
            Si tiene alguna pregunta o sugerencia sobre nuestra política de privacidad, comuníquese con nosotros a{" "}
            <a href="mailto:admin@debt.com">
              <strong>admin@Debt.com</strong>
            </a>{" "}
            o al <strong>1.800.810.0989</strong>, o escríbanos a:
          </p>
          <p>
            <strong>Debt.com LLC</strong> <br />
            8220 W. State Road 84, Fort Lauderdale, FL 33324
          </p>
          <p>
            <strong>IMPRIMA Y CONSERVE UNA COPIA DE ESTA POLÍTICA DE PRIVACIDAD PARA SUS ARCHIVOS.</strong>
          </p>
        </div>
      </div>
    );
  },
);
