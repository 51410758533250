import { makeAutoObservable } from "mobx";

import { AxiosAgent } from "@services";
import { DebtManagementPlanDetails, JourneyId, PlanSelectionForm } from "@types";
import { DebtSettlementPlanDetails, CreditResponse } from "@types";
import { GTMCreditPullSuccess, GTMCreditReportError, JourneyCTAEnum } from "@utils";

import LoadingStore from "./LoadingStore.ts";
import { store } from "./store.ts";
import { rollbar } from "../../rollbarConfig.ts";

export default class ProfileCallToActions {
  loader: LoadingStore;
  creditResponse: CreditResponse | undefined = undefined;
  selectedPlan: DebtSettlementPlanDetails | undefined = undefined;
  ssnError: boolean = false;
  hasRetried: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.loader = new LoadingStore();
  }

  authorizeCreditPull = async (profileId: string) => {
    try {
      this.loader.updateState("LOADING");
      this.setSsnError(false);
      const data = await AxiosAgent.CallToActions.authorizeCreditPull(profileId);
      this.loader.updateState("SUCCESS");
      GTMCreditPullSuccess();
      this.setCreditResponse(data);
    } catch (error) {
      GTMCreditReportError();
      rollbar.error("Error occurred while authorizing credit pull", error as Error);
      this.loader.updateState("ERROR");
    }
  };

  getTotalDebt = () => {
    const currency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    if (this.creditResponse) {
      const totalEligibleDebt = this.creditResponse?.analysis["debt-settlement"].totalEligibleDebt;
      if (totalEligibleDebt) {
        return currency.format(totalEligibleDebt);
      }
    }
    return "";
  };

  setCreditResponse = (data: CreditResponse | undefined) => {
    this.creditResponse = data;
    const planInfo = this.creditResponse?.plans["debt-settlement"].planDetails.find(
      (plan) => plan.primaryDefaultPlan === true,
    );
    if (planInfo) {
      this.setSelectedPlan(planInfo);
    }
  };

  setSelectedPlan = (plan: DebtSettlementPlanDetails) => {
    this.selectedPlan = plan;
  };

  setSsnError = (ssnError: boolean) => {
    this.ssnError = ssnError;
  };

  setHasRetried = (retried: boolean) => {
    this.hasRetried = retried;
  };

  saveSelectedPlan = async (profileId: string, data: PlanSelectionForm) => {
    try {
      this.loader.updateState("LOADING");
      const planInfo = this.creditResponse?.plans["debt-settlement"].planDetails.find(
        (plan) => plan.id === data.plan.id,
      );

      if (planInfo) {
        if (data.plan.description) {
          planInfo.description = data.plan.description;
        }

        this.setSelectedPlan(planInfo);
        await AxiosAgent.CallToActions.saveSelectedPlan(profileId, JourneyCTAEnum["debt-settlement-id"], {
          selectedPlan: planInfo,
          submittedBy: "user",
        });
        this.loader.updateState("SUCCESS");
      }
    } catch (error) {
      rollbar.debug("Error saving the selected plan", error as Error);
      this.loader.updateState("ERROR");
    }
  };

  get allDebtSettlementPlans() {
    const plan = this.creditResponse?.plans["debt-settlement"];

    if (plan && Object.keys(plan).length) return plan;

    return null;
  }

  get debtSettlementPlan(): DebtSettlementPlanDetails | null {
    const selectedPlan = store.profileStore?.journey?.selectedPlan;

    if (
      store.profileStore.journeyId === JourneyId.DEBT_SETTLEMENT &&
      selectedPlan &&
      Object.keys(selectedPlan).length
    ) {
      return selectedPlan as DebtSettlementPlanDetails;
    }

    return null;
  }

  get debtManagementPlan(): DebtManagementPlanDetails | null {
    const selectedPlan = store.profileStore?.journey?.selectedPlan;

    if (
      store.profileStore.journeyId === JourneyId.DEBT_MANAGEMENT &&
      selectedPlan &&
      Object.keys(selectedPlan).length
    ) {
      return selectedPlan as DebtManagementPlanDetails;
    }

    return null;
  }

  calculateDebtManagementPlan = async (profileId: string) => {
    this.loader.updateState("LOADING");
    try {
      await AxiosAgent.CallToActions.getDebtManagementPlan(profileId);
      await store.profileStore.updateProfile();
      this.loader.updateState("SUCCESS");
    } catch (error) {
      this.loader.updateState("ERROR");
      rollbar.error("Error retrieving debt management plan", error as Error);
      throw error;
    }
  };

  getCreditReport = async (profileId: string) => {
    this.loader.updateState("LOADING");
    try {
      const response = await AxiosAgent.CallToActions.getCreditReport(profileId);
      this.loader.updateState("SUCCESS");
      if (Array.isArray(response)) {
        const filteredResponse = response.filter((item) => {
          return item.plans && item;
        });
        this.setCreditResponse(filteredResponse[0]);
      } else {
        this.setCreditResponse(response);
      }
    } catch (error) {
      this.loader.updateState("ERROR");
      rollbar.error("Error retrieving credit report", error as Error);
      throw error;
    }
  };

  updateJourneyCta = async (profileId: string, journeyId: JourneyId, ctaId: JourneyCTAEnum) => {
    return AxiosAgent.CallToActions.updateJourneyCta(profileId, journeyId, ctaId);
  };
}
