import { useRollbar } from "@rollbar/react";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ButtonBranded, ScrollIndicator, FooterLegalInfo } from "@components/common";
import { ConfirmationModalView } from "@components/layout";
import { useStyles } from "@components/layout/Form/form.styles";
import { useStore } from "@stores";
import { theme } from "@styles";
import { CreditAuthForm, VTPageTitlesEnum } from "@types";
import {
  CTAModulesEnum,
  GTMDynamicEvent,
  GTMOffRampClicked,
  GTMOffRampConfirmed,
  handlePercentageAndPageCompleted,
  ProfileSubStatusEnum,
} from "@utils";

interface IFooterProps {
  name: string;
}

export const CreditAuthorizationFooter = observer(({ name }: IFooterProps) => {
  const { t } = useTranslation("credit_authorization");
  const [loading, setLoading] = useState(false);
  const classes = useStyles({ name });
  const methods = useFormContext<CreditAuthForm>();
  const rollbar = useRollbar();

  const {
    modalStore: { openModal, showOffRampModal, setShowOffRampModal, setClickSource },
    profileStore,
    remoteConfigStore: { featureFlags },
    callToActionsStore,
  } = useStore();

  const handleOffRamp = () => {
    GTMOffRampClicked("Credit-Auth");
    setClickSource("footer");
    setShowOffRampModal(!showOffRampModal);
  };

  const handleSubmission = useCallback(
    async (data: CreditAuthForm) => {
      const { address } = data;
      const dob = new Date(data.dob).toISOString().slice(0, 10).split("-");

      if (profileStore.profile?.id) {
        const body = {
          address: {
            street: address.street_line.trim(),
            city: address.city.trim(),
            state: address.state.trim(),
            zipCode: address.zipcode,
            country: "USA",
          },
          dob: {
            year: dob[0],
            month: dob[1],
            day: dob[2],
          },
          ssn: data.ssn,
        };
        await profileStore.updateUserProfileAPI(profileStore.profile.id, body);
        await callToActionsStore.authorizeCreditPull(profileStore.profile.id);
        await profileStore.updateProfile();
        methods?.control.unregister("address");
        methods?.control.unregister("dob");
        methods?.control.unregister("ssn");
      }
    },
    [profileStore, callToActionsStore, methods?.control],
  );

  const handleSubmit = async () => {
    try {
      setLoading(true);
      GTMDynamicEvent("credit_authorization_click");

      await handlePercentageAndPageCompleted(
        featureFlags.enableBudgetTool,
        VTPageTitlesEnum.CREDIT_AUTHORIZATION,
        profileStore,
      );

      await methods.handleSubmit(handleSubmission)();
    } catch (error) {
      rollbar.error("Error when submitting credit auth", error as Error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ConfirmationModalView
        isOpen={showOffRampModal}
        handlePrimary={() => {
          GTMOffRampConfirmed("Credit-Auth");
          setShowOffRampModal(false);
          profileStore.updateEnrollmentStatus(ProfileSubStatusEnum.IDA_DECLINED_CREDIT);
          openModal(CTAModulesEnum["call-for-help"]);
        }}
        handleSecondary={() => {
          setShowOffRampModal(false);
        }}
        handleCancel={() => {
          setShowOffRampModal(false);
        }}
        name={"credit-authorization"}
      />
      <div className={classes.footer}>
        <div className={classes.footerButtonContainer}>
          {featureFlags.enableCreditAuthOffRamp && (
            <div className={classes.callMeButton}>
              <ButtonBranded
                data-cy="callMeButton"
                variant="textonly"
                fontSize={theme.fontSizes.medium}
                fontWeight={theme.fontWeights.medium}
                lineHeight={theme.fontLineHeights.xxxsmall}
                className={classes.debtReviewCallButton}
                onClick={handleOffRamp}
              >
                {t("callMe")}
              </ButtonBranded>
            </div>
          )}
          <div className={classes.nextActionButton}>
            <ButtonBranded
              data-cy="credit-authorization-button-agree"
              variant={"primary"}
              disabled={!!Object.keys(methods.formState.errors).length || !methods.formState.isValid}
              loading={loading}
              onClick={handleSubmit}
              type={"submit"}
              name={"CreditAuthSubmit"}
            >
              {t("agreeAuth")}
            </ButtonBranded>
          </div>
        </div>
        <FooterLegalInfo />
        <ScrollIndicator scrollDivId={"form-container"} />
      </div>
    </>
  );
});
