import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { CallToActionCard, MiniDashboard, HelpView, HelpViewB } from "@components/layout";
import { HelpViewC } from "@components/layout/HelpViewC";
import { useStore } from "@stores";
import { CallToActionIdEnum, JourneyId } from "@types";
import { MODULE_AUTH_CREDIT, MODULE_BUDGET_TOOL, shouldOpenCreditAuthModule, shouldOpenBudgetModule } from "@utils";

import { useStyles } from "./Home.styles";

export const Home = observer(() => {
  const { profileStore, budgetStore, commonStore, modalStore } = useStore();
  const { profile, hasSelectedPlan, journeyId } = profileStore;
  const { userClosedBudgetModal, userClosedCredAuthModal } = commonStore;
  const helpView = commonStore.getHelpPageView();
  const userHasCompletedCreditAuth = profileStore.profile?.completedCallToActionIds?.includes(
    CallToActionIdEnum.AUTHORIZE_CREDIT_PULL,
  );
  useEffect(() => {
    if (
      !helpView &&
      journeyId === JourneyId.DEBT_MANAGEMENT &&
      shouldOpenBudgetModule(profileStore, budgetStore, userClosedBudgetModal)
    ) {
      modalStore.openModal(MODULE_BUDGET_TOOL);
    }
  }, [journeyId, budgetStore, profileStore, userClosedBudgetModal, modalStore, helpView]);

  useEffect(() => {
    if (
      journeyId === JourneyId.DEBT_SETTLEMENT &&
      shouldOpenCreditAuthModule(userClosedCredAuthModal, userHasCompletedCreditAuth!, profile!)
    ) {
      modalStore.openModal(MODULE_AUTH_CREDIT);
    }
  }, [journeyId, userClosedCredAuthModal, modalStore, profile, userHasCompletedCreditAuth]);

  const helpPageCSS = () => {
    const helpPageView = commonStore.getHelpPageView();

    if (helpPageView === "helpA" || (hasSelectedPlan && (helpPageView === "helpB" || helpPageView === "helpC"))) {
      return classes.contenthelpA;
    }
    return "";
  };

  const calculateMarginTop = () => {
    switch (helpView) {
      case "helpA":
        return profile?.profilePercentageComplete !== 100 ? 210 : 250;
      case "helpB":
        return 0;
      case "helpC":
        return profile?.profilePercentageComplete !== 100 ? 315 : 205;
      default:
        return 400;
    }
  };

  const classes = useStyles({
    marginTop: calculateMarginTop(),
  });

  return (
    <div className={classes.centerContainer}>
      {helpView !== "helpA" && helpView !== "helpB" && helpView !== "helpC" && profile?.nextCallToAction ? (
        <div className={classes.callToActionsContainer}>
          <CallToActionCard data={profile.nextCallToAction} />
          <MiniDashboard />
        </div>
      ) : (
        <>
          <div className={helpPageCSS()}>
            {helpView == "helpA" && <HelpView />}
            {helpView == "helpB" && hasSelectedPlan && <HelpViewB />}
            {helpView == "helpC" && hasSelectedPlan && <HelpViewC />}
          </div>
          <div className={classes.miniDashboardWrapper}>
            <MiniDashboard endState={helpView} />
          </div>
        </>
      )}
    </div>
  );
});
