import { Divider, Grid } from "@mui/material";

import { theme } from "@styles";
import { formatNumberToCurrencyString } from "@utils";

import { Text } from "../Text";

export interface DebtInfoPropsBase {
  name: string;
  type: string;
  amount: number;
  ineligible: boolean;
  accountIdentifier?: string;
}

export const DebtInfo: React.FC<{ data: DebtInfoPropsBase[] }> = ({ data }) => {
  return (
    <>
      {data.map((tradeLine) => (
        <div key={`${tradeLine.name}-${tradeLine.amount}`}>
          <Grid container display="flex" justifyContent="space-between" paddingY={2}>
            <Grid item>
              <Text
                fontSize="medium"
                fontWeight="semiBold"
                lineHeight="medium"
                sx={{
                  color: tradeLine.ineligible
                    ? `${theme.palette.brandGray.main} !important`
                    : `${theme.palette.brandGray.darker} !important`,
                }}
              >
                {tradeLine.name}
              </Text>
              <Text
                fontSize="medium"
                fontWeight="regular"
                lineHeight="medium"
                sx={{
                  color: theme.palette.brandGray.main,
                }}
              >
                {tradeLine.type}
              </Text>
            </Grid>
            <Grid item>
              <Text
                fontSize="medium"
                fontWeight="semiBold"
                lineHeight="medium"
                sx={{
                  color: tradeLine.ineligible
                    ? `${theme.palette.brandGray.main} !important`
                    : `${theme.palette.brandGray.darker} !important`,
                }}
              >
                ${formatNumberToCurrencyString(tradeLine.amount)}
              </Text>
            </Grid>
          </Grid>
          <Divider sx={{ color: "black", width: "100%" }} />
        </div>
      ))}
    </>
  );
};
