import AddIcon from "@mui/icons-material/Add";
import { Box, BoxProps } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Text } from "@components/common";
import { theme } from "@styles";

interface AddBudgetCategoryProps extends BoxProps {
  category: string;
}

export const AddBudgetCategory = ({ category, ...props }: AddBudgetCategoryProps) => {
  const { t } = useTranslation("budget_tool");

  return (
    <Box
      py={1}
      px={2}
      borderRadius={2}
      border={`1px dashed ${theme.palette.brandGray.light}`}
      display="flex"
      alignItems="center"
      gap={2}
      sx={{
        background: theme.palette.common.white,
        ":hover": {
          cursor: "pointer",
        },
      }}
      data-cy={`add-budget-item-${category}`}
      {...props}
    >
      <AddIcon sx={{ color: theme.palette.brandGreen.main }} />
      <Text fontWeight="semiBold">{t(category)}</Text>
    </Box>
  );
};
