import { BrowserRouter } from "react-router-dom";

import { ApplicationRoutes } from "@routes";

import { appVersion } from "./version";

function App() {
  // eslint-disable-next-line no-console
  console.log(`App version: ${appVersion}`);
  return (
    <BrowserRouter>
      <ApplicationRoutes />
    </BrowserRouter>
  );
}

export default App;
