export const DMP_SUB_CATEGORIES = {
  PRIMARY_INCOME: "Applicant Primary Income",
  DISABILITY: "Applicant Disability",
  OTHER_INCOME: "Applicant Other Income",
  SECONDARY_INCOME: "Applicant Secondary Income",
  ALIMONY: "Applicant Alimony/Child Support",
  SOCIAL_SECURITY: "Applicant Social Security",
  PENSION: "Applicant Pension",
  UNEMPLOYMENT: "Applicant Unemployment",
  RENTAL_INCOME: "Applicant Rental Income",
  RENT: "Rent",
  ASSOCIATION_FEES: "Association Fees",
  HOME_OWNERS_INSURANCE: "Home Owners Insurance",
  REAL_ESTATE: "Real Estate Taxes",
  SECOND_MORTGAGE: "Second Mortgage",
  HOME_EQUITY: "Home Equity Loan",
  MORTGAGE: "First Mortgage",
  RENTER_INSURANCE: "Renter's Insurance",
  INTERNET: "Internet",
  PHONE: "Phone",
  BUNDLE_TV: "Bundle TV/Cable/Internet",
  CABLE: "Cable",
  CELLULAR: "Cellular",
  WATER: "Water",
  ELECTRICITY: "Electricity",
  GAS_OIL: "Gas/Oil/Heat",
  GROCEIRY: "Grocery",
  LUNCHES: "Lunches/Eating Out",
  AUTO_BOAT_1: "Auto/Boat/Bike Payment 1",
  AUTO_BOAT_2: "Auto/Boat/Bike Payment 2",
  AUTO_BOAT_3: "Auto/Boat/Bike Payment 3",
  PARKING: "Parking/Tolls",
  CAR_INSURANCE: "Car Insurance",
  RAPAIRS: "Repairs/Maintenance",
  GAS: "Gas",
  OTHER_TRANSPORT: "Other Transportation Fees",
  BUS_FARE: "Bus Fare",
  HEALTH_INSURANCE: "Health Insurance",
  LIFE_INSURANCE: "Life Insurance",
  MEDICAL: "Doctor Bills",
  PRESCRIPTION: "Prescription/Medication",
  CHILD_CARE: "Child Care",
  CHILD_SUPPORT: "Child Support/Alimony",
  CLEANING: "Cleaning/Laundry",
  CLOTHING: "Clothing",
  SAVINGS: "Savings",
  EDUCATION: "Educational/Professional",
  PETS: "Pets",
  HEALTH_MEMBERSHIP: "Health Club Membership",
  CREDIT_CARD: "Total Recurring Credit Card Payments",
  MISC: "Miscellaneous",
  STUDENT_LOAN: "Student Loan",
  CHURCH: "Church/Tithe/Charity",
  HAIR_CARE: "Hair Care/Nails/Barber",
  CIGARETTES: "Cigarettes",
  HOME_REPAIR: "Home Repairs/Maintenance",
  PERSONAL_LOAN: "Total Recurring Personal Loan Payments",
  OTHER_LOAN: "Other Loan",
} as const;
export type TDMPSubCategories = (typeof DMP_SUB_CATEGORIES)[keyof typeof DMP_SUB_CATEGORIES];

export const DSP_SUB_CATEGORIES = {
  PRIMARY_INCOME: "primary",
  SECONDARY_INCOME: "secondary",
  CHILD_SUPPORT_ALIMONY: "childSupportAlimony",
  SOCIAL_SECURITY: "socialSecurity",
  PENSION: "pension",
  DISABILITY: "disability",
  UNEMPLOYMENT: "unemployment",
  RENTAL_INCOME: "rental",

  MORTGAGE: "mortgageRent",
  HOME_OWNERS_INSURANCE: "homeOwnersInsurance",
  ELECTRICITY: "electricity",
  GROCERIES: "groceries",
  RESTAURANTS: "restaurants",
  CAR_PAYMENT: "carPayment",
  CAR_INSURANCE: "carInsurance",
  INTERNET_CABLE: "internetCable",
  TELEPHONE: "telephone",
  PERSONAL_CARE: "personalCare",

  CREDIT_CARD: "creditCard",
  PAYDAY_LOANS: "Payday/Personal Loans Debt",
  STUDENT_LOAN: "Student Loan Debt",
  MEDICAL_DEBT: "Medical Debt",
  LINES_OF_CREDIT: "Lines of Credit",
  OTHER_LOANS: "Other Loans",
} as const;
export type TDSPSubCategories = (typeof DSP_SUB_CATEGORIES)[keyof typeof DSP_SUB_CATEGORIES];

export type TSubCategories = TDMPSubCategories | TDSPSubCategories;

export enum BudgetStatusEnum {
  PENDING = "pending",
  STARTED = "started",
  COMPLETED = "completed",
}

export enum BudgetItemTypeEnum {
  REQUIRED = "required",
  OPTIONAL = "optional",
  CUSTOM = "custom",
}

export const BUDGET_FREQUENCY = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
} as const;

export type TFrequency = (typeof BUDGET_FREQUENCY)[keyof typeof BUDGET_FREQUENCY];

export const DEFAULT_FREQUENCY = BUDGET_FREQUENCY.MONTHLY;
export const DEFAULT_ALLOWED_FREQUENCY = [BUDGET_FREQUENCY.MONTHLY, BUDGET_FREQUENCY.YEARLY];

export interface ValidationFunc {
  (val: string): string;
}

export interface OnChangeFunc {
  (val: string): void;
}

export enum BudgetTypeEnum {
  DEBT = "debt",
  INCOME = "income",
  EXPENSE = "expense",
}

export const BudgetCategoryDSP = {
  PrimaryIncome: "Primary Income",
  Auto: "Auto",
  Clothing: "Clothing",
  Education: "Education",
  Entertainment: "Entertainment",
  Food: "Food",
  Housing: "Housing",
  Medical: "Medical / Health",
  Taxes: "Taxes",
  Utilities: "Utilities",
  Other: "Other",
} as const;

export const BudgetSubCategoryDMP = {
  Transportation: "Transportation",
  Health: "Health",
  ChildCare: "Child Care",
  Income: "Income",
  Insurance: "Insurance",
  Children: "Children",
  Personal: "Personal",
  MEDICAL: "Medical",
} as const;

export const BudgetSubCategoryEnum = {
  ...BudgetCategoryDSP,
  ...BudgetSubCategoryDMP,
} as const;

export type TBudgetSubCategory = (typeof BudgetSubCategoryEnum)[keyof typeof BudgetSubCategoryEnum];

type TBudgetSubCategoryMap = {
  [key in TSubCategories]?: TBudgetSubCategory;
};

export const BudgetSubCategoryMap: TBudgetSubCategoryMap = {
  [DSP_SUB_CATEGORIES.MORTGAGE]: BudgetSubCategoryEnum.Housing,
  [DSP_SUB_CATEGORIES.HOME_OWNERS_INSURANCE]: BudgetSubCategoryEnum.Housing,
  [DSP_SUB_CATEGORIES.ELECTRICITY]: BudgetSubCategoryEnum.Utilities,
  [DSP_SUB_CATEGORIES.GROCERIES]: BudgetSubCategoryEnum.Food,
  [DSP_SUB_CATEGORIES.RESTAURANTS]: BudgetSubCategoryEnum.Entertainment,
  [DSP_SUB_CATEGORIES.CAR_PAYMENT]: BudgetSubCategoryEnum.Auto,
  [DSP_SUB_CATEGORIES.CAR_INSURANCE]: BudgetSubCategoryEnum.Auto,
  [DSP_SUB_CATEGORIES.INTERNET_CABLE]: BudgetSubCategoryEnum.Utilities,
  [DSP_SUB_CATEGORIES.TELEPHONE]: BudgetSubCategoryEnum.Utilities,
  [DSP_SUB_CATEGORIES.PERSONAL_CARE]: BudgetSubCategoryEnum.Other,

  [DSP_SUB_CATEGORIES.PRIMARY_INCOME]: BudgetSubCategoryEnum.PrimaryIncome,
  [DSP_SUB_CATEGORIES.SECONDARY_INCOME]: BudgetSubCategoryEnum.Other,
  [DSP_SUB_CATEGORIES.CHILD_SUPPORT_ALIMONY]: BudgetSubCategoryEnum.Other,
  [DSP_SUB_CATEGORIES.SOCIAL_SECURITY]: BudgetSubCategoryEnum.Other,
  [DSP_SUB_CATEGORIES.PENSION]: BudgetSubCategoryEnum.Other,
  [DSP_SUB_CATEGORIES.DISABILITY]: BudgetSubCategoryEnum.Other,
  [DSP_SUB_CATEGORIES.UNEMPLOYMENT]: BudgetSubCategoryEnum.Other,
  [DSP_SUB_CATEGORIES.RENTAL_INCOME]: BudgetSubCategoryEnum.Other,

  [DSP_SUB_CATEGORIES.CREDIT_CARD]: BudgetSubCategoryEnum.Health,
  [DSP_SUB_CATEGORIES.PAYDAY_LOANS]: BudgetSubCategoryEnum.Health,
  [DSP_SUB_CATEGORIES.STUDENT_LOAN]: BudgetSubCategoryEnum.Health,
  [DSP_SUB_CATEGORIES.MEDICAL_DEBT]: BudgetSubCategoryEnum.Health,
  [DSP_SUB_CATEGORIES.LINES_OF_CREDIT]: BudgetSubCategoryEnum.Health,
  [DSP_SUB_CATEGORIES.OTHER_LOANS]: BudgetSubCategoryEnum.Health,

  // DSP_SUB_CATEGORIES
  [DMP_SUB_CATEGORIES.CREDIT_CARD]: BudgetSubCategoryEnum.Personal,
  [DMP_SUB_CATEGORIES.PERSONAL_LOAN]: BudgetSubCategoryEnum.Personal,
  [DMP_SUB_CATEGORIES.STUDENT_LOAN]: BudgetSubCategoryEnum.Personal,
  [DMP_SUB_CATEGORIES.MEDICAL]: BudgetSubCategoryEnum.MEDICAL,
  [DMP_SUB_CATEGORIES.HOME_EQUITY]: BudgetSubCategoryEnum.Housing,
  [DMP_SUB_CATEGORIES.OTHER_LOAN]: BudgetSubCategoryEnum.Personal,
  // INCOME
  [DMP_SUB_CATEGORIES.PRIMARY_INCOME]: BudgetSubCategoryEnum.Income,
  [DMP_SUB_CATEGORIES.DISABILITY]: BudgetSubCategoryEnum.Income,
  [DMP_SUB_CATEGORIES.OTHER_INCOME]: BudgetSubCategoryEnum.Income,
  [DMP_SUB_CATEGORIES.SECONDARY_INCOME]: BudgetSubCategoryEnum.Income,
  [DMP_SUB_CATEGORIES.SOCIAL_SECURITY]: BudgetSubCategoryEnum.Income,
  [DMP_SUB_CATEGORIES.ALIMONY]: BudgetSubCategoryEnum.Income,
  [DMP_SUB_CATEGORIES.PENSION]: BudgetSubCategoryEnum.Income,
  [DMP_SUB_CATEGORIES.UNEMPLOYMENT]: BudgetSubCategoryEnum.Income,
  [DMP_SUB_CATEGORIES.RENTAL_INCOME]: BudgetSubCategoryEnum.Income,
  // EXPENSE
  [DMP_SUB_CATEGORIES.MORTGAGE]: BudgetSubCategoryEnum.Housing,
  [DMP_SUB_CATEGORIES.RENT]: BudgetSubCategoryEnum.Housing,
  [DMP_SUB_CATEGORIES.GROCEIRY]: BudgetSubCategoryEnum.Food,
  [DMP_SUB_CATEGORIES.ELECTRICITY]: BudgetSubCategoryEnum.Utilities,
  [DMP_SUB_CATEGORIES.SECOND_MORTGAGE]: BudgetSubCategoryEnum.Housing,
  [DMP_SUB_CATEGORIES.ASSOCIATION_FEES]: BudgetSubCategoryEnum.Housing,
  [DMP_SUB_CATEGORIES.HOME_OWNERS_INSURANCE]: BudgetSubCategoryEnum.Housing,
  [DMP_SUB_CATEGORIES.REAL_ESTATE]: BudgetSubCategoryEnum.Housing,
  [DMP_SUB_CATEGORIES.RENTER_INSURANCE]: BudgetSubCategoryEnum.Housing,
  [DMP_SUB_CATEGORIES.INTERNET]: BudgetSubCategoryEnum.Utilities,
  [DMP_SUB_CATEGORIES.PHONE]: BudgetSubCategoryEnum.Utilities,
  [DMP_SUB_CATEGORIES.BUNDLE_TV]: BudgetSubCategoryEnum.Utilities,
  [DMP_SUB_CATEGORIES.WATER]: BudgetSubCategoryEnum.Utilities,
  [DMP_SUB_CATEGORIES.GAS_OIL]: BudgetSubCategoryEnum.Utilities,
  [DMP_SUB_CATEGORIES.LUNCHES]: BudgetSubCategoryEnum.Food,
  [DMP_SUB_CATEGORIES.AUTO_BOAT_1]: BudgetSubCategoryEnum.Transportation,
  [DMP_SUB_CATEGORIES.PARKING]: BudgetSubCategoryEnum.Transportation,
  [DMP_SUB_CATEGORIES.CAR_INSURANCE]: BudgetSubCategoryEnum.Transportation,
  [DMP_SUB_CATEGORIES.RAPAIRS]: BudgetSubCategoryEnum.Transportation,
  [DMP_SUB_CATEGORIES.GAS]: BudgetSubCategoryEnum.Transportation,
  [DMP_SUB_CATEGORIES.BUS_FARE]: BudgetSubCategoryEnum.Transportation,
  [DMP_SUB_CATEGORIES.HEALTH_INSURANCE]: BudgetSubCategoryEnum.Insurance,
  [DMP_SUB_CATEGORIES.LIFE_INSURANCE]: BudgetSubCategoryEnum.Insurance,
  [DMP_SUB_CATEGORIES.PRESCRIPTION]: BudgetSubCategoryEnum.MEDICAL,
  [DMP_SUB_CATEGORIES.CHILD_CARE]: BudgetSubCategoryEnum.Children,
  [DMP_SUB_CATEGORIES.CHILD_SUPPORT]: BudgetSubCategoryEnum.Children,
  [DMP_SUB_CATEGORIES.CLEANING]: BudgetSubCategoryEnum.Personal,
  [DMP_SUB_CATEGORIES.CLOTHING]: BudgetSubCategoryEnum.Personal,
  [DMP_SUB_CATEGORIES.SAVINGS]: BudgetSubCategoryEnum.Personal,
  [DMP_SUB_CATEGORIES.EDUCATION]: BudgetSubCategoryEnum.Personal,
  [DMP_SUB_CATEGORIES.PETS]: BudgetSubCategoryEnum.Personal,
  [DMP_SUB_CATEGORIES.HEALTH_MEMBERSHIP]: BudgetSubCategoryEnum.Personal,
  [DMP_SUB_CATEGORIES.MISC]: BudgetSubCategoryEnum.Personal,
  [DMP_SUB_CATEGORIES.CHURCH]: BudgetSubCategoryEnum.Personal,
  [DMP_SUB_CATEGORIES.HAIR_CARE]: BudgetSubCategoryEnum.Personal,
  [DMP_SUB_CATEGORIES.CIGARETTES]: BudgetSubCategoryEnum.Personal,
  [DMP_SUB_CATEGORIES.HOME_REPAIR]: BudgetSubCategoryEnum.Personal,
} as const;

export interface IBudgetItemData {
  id?: string;
  subCategory?: TBudgetSubCategory;
  category?: string;
  name?: string;
  frequency?: TFrequency;
  amount?: number | string;
  type?: BudgetItemTypeEnum;
}

export interface IBudgetDMPItemData {
  subCategory: TBudgetSubCategory;
  category: string;
  tags: BudgetTypeEnum[];
  name: string;
  frequency: number;
  amount: number;
  type: string;
}

export interface GaugeDataValue {
  id: number;
  value: number;
  color: string;
  budgetType: BudgetTypeEnum;
}

export interface IBudgetItemDataDto {
  id?: string;
  subCategory?: string;
  category?: string;
  name?: string;
  frequency?: string;
  amount?: number | string;
  type?: string;
}

export interface IBudgetDMPItemDataDto {
  name?: string;
  amount?: number;
  tags?: string[];
  attributes?: string[];
  category?: string;
  frequency?: number;
  subCategory?: string;
  type?: string;
}
export class BudgetItemData implements IBudgetItemData {
  id?: string;
  subCategory?: TBudgetSubCategory;
  category?: string;
  name: string;
  frequency: TFrequency;
  amount?: number | string;
  type: BudgetItemTypeEnum;

  constructor({ id, name, subCategory, category, frequency, amount, type }: IBudgetItemData) {
    this.id = id;
    this.frequency = frequency || DEFAULT_FREQUENCY;
    this.subCategory = subCategory;
    this.category = category;
    this.name = name || "";
    this.amount = amount;
    this.type = type || BudgetItemTypeEnum.CUSTOM;
  }
}

export type BudgetType<T> = { [key in BudgetTypeEnum]: T };
export type BudgetDMPType = { elements: IBudgetDMPItemData[] };
