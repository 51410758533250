import { Box, BoxProps, Grid, Paper, SxProps } from "@mui/material";
import React from "react";

import { Text, TextProps } from "@components/common";
import { theme } from "@styles";

interface ModuleProps extends BoxProps {
  children: React.ReactNode;
  formatted?: boolean;
}

interface ModuleTextProps extends TextProps {
  children: React.ReactNode;
}

/** Series of helper components to make it easier to create the module layout */
interface ModuleComponent extends React.FC<ModuleProps> {
  Main: React.FC<ModuleProps>;
  Header: React.FC<Partial<ModuleProps>>;
  Sidebar: React.FC<ModuleProps>;
  Content: React.FC<ModuleProps>;
  Title: React.FC<ModuleTextProps>;
  SubTitle: React.FC<ModuleTextProps>;
  Description: React.FC<ModuleTextProps>;
  Footer: React.FC<ModuleProps>;
}

export const Module: ModuleComponent = ({ children, formatted, ...props }) => {
  const modalStyles: SxProps = {
    overflow: {
      xs: "auto",
      md: "hidden",
    },
    display: "flex",
    flexDirection: "column",
    boxShadow: `${theme.customShadows.clickable} !important`,
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    borderRadius: "0px !important",

    [theme.breakpoints.up("md")]: {
      position: "relative",
      height: "calc(100vh - 128px)",
      borderRadius: "16px !important",
    },
  };

  const formattedStyles = {
    width: "100%",
    position: "relative",
    zIndex: 99,
    [theme.breakpoints.up("md")]: {
      margin: "0 auto",
      width: "80%",
    },
  };

  if (formatted) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", height: 1, ...formattedStyles }} {...props}>
        <Paper id="module" sx={modalStyles}>
          {children}
        </Paper>
      </Box>
    );
  }

  return (
    <Box id="module" sx={{ display: "flex", flexDirection: "column", height: 1 }} {...props}>
      {children}
    </Box>
  );
};

const Main: React.FC<ModuleProps> = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={[
        { flex: 1, padding: "32px 24px", overflowY: { xs: "visible", md: "auto" } },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        flex="1 0 0"
        justifyContent="space-between"
        sx={{
          gap: 3,
          overflowY: "visible",
          height: 1,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            width: 1,
          },
        }}
        {...props}
      >
        {children}
      </Box>
    </Box>
  );
};

const Sidebar: React.FC<ModuleProps> = ({ children, sx, ...props }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexBasis={"35%"}
      gap={2}
      sx={[
        {
          height: 1,
          [theme.breakpoints.down("md")]: {
            width: 1,
            gap: 1,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      {children}
    </Box>
  );
};

const Content: React.FC<ModuleProps> = ({ children, sx, ...props }) => {
  return (
    <Box
      display="flex"
      flex={1}
      gap={3}
      flexDirection="column"
      sx={[
        {
          height: 1,
          [theme.breakpoints.down("md")]: {
            width: "100%",
            gap: 2,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      {children}
    </Box>
  );
};

const Title: React.FC<ModuleTextProps> = ({ children, ...props }) => {
  return (
    <Text fontSize={"xxxxlarge"} fontWeight={"bold"} lineHeight={"xxxxxlarge"} {...props}>
      {children}
    </Text>
  );
};

const SubTitle: React.FC<ModuleTextProps> = ({ children, ...props }) => {
  return (
    <Text fontSize={"medium"} fontWeight={"semiBold"} lineHeight={"medium"} color={"accent"} {...props}>
      {children}
    </Text>
  );
};

const Description: React.FC<ModuleTextProps> = ({ children, ...props }) => {
  return (
    <Text fontSize={"small"} fontWeight={"light"} lineHeight={"xxsmall"} {...props}>
      {children}
    </Text>
  );
};

const Header: React.FC<Partial<ModuleProps>> = ({ children }) => {
  return (
    <Grid
      container
      sx={{
        padding: (theme) => `${theme.spacing(0.5)} ${theme.spacing(2)}`,
        boxShadow: (theme) => `${theme.customShadows.clickable} !important`,
        alignItems: "center",
        position: "sticky",
        top: 0,
        left: 0,
        background: "white",
        [theme.breakpoints.up("md")]: {
          boxShadow: "none !important",
          borderWidth: 0,
          borderStyle: "solid",
          borderColor: "rgba(0, 0, 0, 0.12)",
          borderBottomWidth: "thin",
          position: "static",
          minHeight: "49px",
        },
      }}
    >
      {children}
    </Grid>
  );
};

const Footer: React.FC<ModuleProps> = ({ children }) => {
  return (
    <Box
      sx={{
        border: "none",
        [theme.breakpoints.up("md")]: {
          borderWidth: "0",
          borderStyle: "solid",
          borderColor: "rgba(0, 0, 0, 0.12)",
          borderTopWidth: "thin !important",
          borderRadius: "unset",
        },
      }}
    >
      <Box sx={{ margin: 2 }}>{children}</Box>
    </Box>
  );
};

Module.Main = Main;
Module.Header = Header;
Module.Sidebar = Sidebar;
Module.Content = Content;
Module.Title = Title;
Module.SubTitle = SubTitle;
Module.Description = Description;
Module.Footer = Footer;
