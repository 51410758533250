import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useStore } from "@stores";
import {
  BudgetType,
  CreditAuthForm,
  FORM_TYPES,
  IBudgetItemData,
  initializeForm,
  PartialCreditAuthFormType,
  PlanSelectionForm,
} from "@types";
import { CTAModuleID, CTAModulesEnum, JourneyCTAEnum, ProfileSubStatusEnum } from "@utils";

export const useFormActions = (name: string) => {
  const { t } = useTranslation("credit_authorization");
  const { profileStore, modalStore, callToActionsStore, remoteConfigStore } = useStore();

  const [enableSsnValidation, setEnableSsnValidation] = useState(
    remoteConfigStore.featureFlags.enableSsnValidation ?? true,
  );

  useEffect(() => {
    setEnableSsnValidation(remoteConfigStore.featureFlags.enableSsnValidation ?? true);
  }, [remoteConfigStore.featureFlags.enableSsnValidation]);

  const form = initializeForm(name, t, enableSsnValidation);

  const methods = useForm<
    CreditAuthForm | PlanSelectionForm | PartialCreditAuthFormType | BudgetType<IBudgetItemData[]>
  >({
    mode: "onChange",
    defaultValues: form?.defaultValues,
    resolver: form?.resolver,
  });

  const selectPlanSubmitHandler = useCallback(
    async (data: PlanSelectionForm) => {
      if (profileStore.profile?.id && profileStore.journeyId) {
        await callToActionsStore.saveSelectedPlan(profileStore.profile.id, data);
        await callToActionsStore.updateJourneyCta(
          profileStore.profile.id,
          profileStore.journeyId,
          JourneyCTAEnum["select-plan"],
        );
        profileStore.updateEnrollmentStatus(ProfileSubStatusEnum.IDA_ENROLLMENT_COMPLETED);
        profileStore.setIsEnrollmentComplete(true);
      }
    },
    [callToActionsStore, profileStore],
  );

  const submitHandler: SubmitHandler<FORM_TYPES> = async (data) => {
    await selectPlanSubmitHandler(<PlanSelectionForm>data);
  };

  const nextUserCtaAfterCreditPull = () => {
    if (callToActionsStore.loader.isSuccess.get()) {
      modalStore.openModal(CTAModulesEnum["review-debt-eligibility" as CTAModuleID]);
    } else if (callToActionsStore.loader.hasError.get()) {
      modalStore.openModal(CTAModulesEnum["call-for-help" as CTAModuleID]);
    }
  };

  return {
    submitHandler,
    methods: methods,
    nextUserCtaAfterCreditPull,
  };
};
