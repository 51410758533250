import "./BouncingDots.css";

export const BouncingDots = () => {
  return (
    <div className="bouncing-dots">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};
